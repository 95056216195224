import _ from "lodash";
import Helper from "@/views/modules/hotel/Helper";
import IBEHotel from "@/api/ibe/hotel";
import {i18n} from "@/i18n";

export default {
    namespaced: true,
    state: {
        hotelSearchRequest: '',
        hotelSearchResults: '',
        selectedHotelId: '',
        hotelRoomTypeCode: '',
        hotelSearchId: '',
        hotelBookingResponse: '',
        hotelRepriceResponse: '',
        hotelBookingRequest: '',
        setAllRooms: ''
    },
    getters: {
        selectedHotel: (state: any) => {
            const allHotels = state.hotelSearchResults;
            const sid = state.selectedHotelId
            if (allHotels != '') {
                const result = allHotels.find((hotel: any) => hotel.hotelid === sid)
                if (!result) return ''
                else return result
                }
            else return ''
        },
        allRooms: (state: any) => state.allRooms,
        hotelSearchId: (state: any) => state.hotelSearchId,
        selectedHotelId: (state: any) => state.selectedHotelId,
        hotelRoomTypeCode: (state: any) => state.hotelRoomTypeCode,
        hotelBookingResponse: (state: any) => state.hotelBookingResponse,
        hotelRepriceResponse: (state: any) => state.hotelRepriceResponse,
        hotelBookingRequest: (state: any) => state.hotelBookingRequest,
        hotelSearchRequest: (state: { hotelSearchRequest: any; }) => state.hotelSearchRequest,
        hotelSearchResults: (state: any) => Helper.CleanPayload(state.hotelSearchResults) || [],
        filteredPayload: (state: any, getters: { hotelSearchResults: any; }) => (data: any) => Helper.filterPayload(getters.hotelSearchResults, data) || [],
    },
    mutations: {
        setHotelBookingResponse: (state: any, data: any) => state.hotelBookingResponse = data,
        setHotelSearchId: (state: any, data: any) => state.hotelSearchId = data,
        setHotelRoomTypeCode: (state: any, data: any) => state.hotelRoomTypeCode = data,
        setSelectedHotelId: (state: any, id: any) => state.selectedHotelId = id,
        setAllRooms: (state: any, data: any) => state.allRooms = data,
        setHotelSearchResults: (state: { hotelSearchResults: any; }, cr: any) => state.hotelSearchResults = cr,
        setHotelSearchRequest: function (state: { hotelSearchRequest: any; }, cs: any) { state.hotelSearchRequest = cs },
        setHotelRepriceResponse: (state: { hotelRepriceResponse: any; }, cr: any) => state.hotelRepriceResponse = cr,
        setHotelBookingRequest: (state: { hotelBookingRequest: any; }, cr: any) => state.hotelBookingRequest = cr,
    },
    actions: {
        /* not here, doesn't return a promise.
        callHotelSearchRequest( {commit}: any, payload: any) {
            let err = '' // do nothing with err for now
            IBEHotel.GetHotelSearchResponse(payload)
                .then (response => {
    console.log ("Thening the hotel search request")

                    if (response.Errors) {
                        const e: any[] = [];
                        response.Errors.filter((d: { ErrorMessage: any; }) => e.push(d.ErrorMessage));
                        err = 'Error'
                        }
                    else {
                        if (!response.SearchResponse || response.SearchResponse.hotels.hotel === null || 
                            response.SearchResponse.hotels.hotel.length === 0)
                            err = '' + i18n.t('addcomm.No Result Found')
                        else {
                            commit('setHotelSearchId', response.SearchId);
                            commit('setHotelSearchResults', response.SearchResponse.hotels.hotel || [])
                            }
                        }   
                    })
            }*/
        }
}