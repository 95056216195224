<template>
  <div class="container mt-2">
      <div>
        <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #a7abb0;">
          <div class="container-fluid">
            <div >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <span @click="$store.dispatch('navigate', { c2: 'userprofile' })"><a class="nav-link">Profile</a></span>
                </li>
                <li class="nav-item">
                  <span @click="$store.dispatch('navigate', { c2: 'changePassword' })"><a class="nav-link">Change Password</a></span>
                </li>
                <li class="nav-item">
                  <span @click="$store.dispatch('navigate', { c2: 'accruals' })"><a class="nav-link">Accruals</a></span>
                </li>
                <li class="nav-item">
                  <span @click="$store.dispatch('navigate', { c2: 'redemptions' })"><a class="nav-link">Redemptions</a></span>
                </li>
                <li class="nav-item">
                  <span @click="$store.dispatch('navigate', { c2: 'transactions' })"><a
                      class="nav-link">Transactions</a></span>
                </li>
                <li class="nav-item">
                  <span @click="$store.dispatch('navigate', { c2: 'expiry' })"><a class="nav-link">Expiries</a></span>
                </li>
                <li class="nav-item">
                  <span @click="$store.dispatch('navigate', { c2: 'bookings' })"><a class="nav-link">Bookings</a></span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <a-spin v-if="$store.getters.isUserProcessing"
          size=large
          style="position: absolute; left: 0; right: 0; top: 50%"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        />
        <div v-else class="row onlythis">
          <Component :is="secondComponent" />
        </div>  
      </div>
    </div>
</template>

<script>

import { secondaryComponents } from "@/navContextToComponentMap";

export default {
  computed: {
    secondComponent: function () {
      //this.$store.commit ('mutisUserProcessing', true)
      const result = secondaryComponents.get (this.$store.getters.underNavContext)
      //console.log ("Found " + (result?result.name:" nothing") + " for " + this.$store.getters.underNavContext)
      return result
      }
    },
  methods: {
    transitionComplete: function (el) {
      console.log ("YO " + JSON.stringify (el))
      }
    }
  }

</script>

<style scoped>
.title {
  text-align: center;
  font-size: 18px;
  color: #595959;
  margin-bottom: 40px;
}

.onlythis {
  width: 100%;
  background-color: #ffffff;
  margin: 20px auto;
  padding-top: 40px;
}

.onlythis .col-md-6 {
  /*border: 1px solid red;*/
  margin: auto;
}

.onlythis /deep/ .form-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.onlythis /deep/ label {
  width: 110px;
  margin-right: 50px;
  font-size: 14px;
  color: #595959;
  text-align: right;
}

/deep/ .field-wrap {
  width: 350px;
}

.btmbtn {
  display: flex;
  margin: 20px auto;
  width: 200px;
  justify-content: space-between;
}

.btmbtn button {
  width: 80px;
  height: 30px;
  background-color: #e5e5e5;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  color: #ffffff;
}

.btmbtn button:last-child {
  background-color: #0095eb;
}

/deep/ .required label {
  position: relative;
  left: 11px;
}

/deep/ .errors {
  position: relative;
  left: 160px;
}

/deep/ .form-group.error input:not([type=checkbox]),
.form-group.error select,
.form-group.error textarea {
  background: none;
}

</style>
