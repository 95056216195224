<template>
  <div style="width: 100%">
    <div class="row login">
      <div class="col-md-4"></div>
      <div class="col-md-4" v-if="showUserNameForm">
        <form class="login-form">
          <h6>Forgot User Name ?</h6>
          <br />
          <div class="mb-3">
            <label for="email" class="form-label">Email address</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="email"
            />
          </div>
          <div class="mb-3" v-if="getErrors">
            <a-alert
              v-for="(err, index) in errorAlert"
              :key="err"
              :message="err"
              type="error"
              closable
              class="mt-3"
              @close="handleErrorClose(index)"
              show-icon
            />
          </div>
          <div class="mb-3">
            <a>
              <span @click="$store.dispatch('navigate', {layer: 'user',  c2: 'login' })"
                ><a-icon type="arrow-left" /> Back to Login</span
              >
            </a>
          </div>
          <a-button
            type="primary"
            block
            @click.prevent="submitBid"
            v-if="!apiProcessing1"
          >
            Submit
          </a-button>
          <a-button type="primary" block v-if="apiProcessing1">
            Please Wait ...
          </a-button>
        </form>
      </div>
      <div class="col-md-4" v-if="showVerifyOtpForm">
        <form class="login-form">
          <h6>Verify OTP</h6>
          <br />
          <div class="mb-3">
            <label for="email" class="form-label">Enter OTP</label>
            <input type="text" class="form-control" id="otp" v-model="otp" />
          </div>
          <div>
            <vue-countdown
              :time="600000"
              @end="onCountdownEnd"
              v-slot="{ minutes, seconds }"
            >
              OTP Expires in：{{ minutes }} minutes {{ seconds }} seconds.
            </vue-countdown>
          </div>
          <a-button
            type="primary"
            block
            @click.prevent="callVerifyOtp"
            :disabled="disableVerifyOtp"
            v-if="!apiProcessing2"
          >
            Submit
          </a-button>
          <a-button type="primary" block v-if="apiProcessing2">
            Please Wait ...
          </a-button>
        </form>
      </div>
      <div class="col-md-4" v-if="showUserName">
        <form class="login-form">
          <h6>Forgot User Name ?</h6>
          <br />
          <h6>Your User Name is: {{ userName }}</h6>
          <br />
          <div class="mb-3">
            <a-button
              type="primary"
              block
              @click="$store.dispatch('navigate', {layer: 'user',  c2: 'login' })"
            >
              Login Page
            </a-button>
          </div>
        </form>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import { i18n } from "@/i18n";
import LBMSService from "@/api/lbmsService";

export default {
  components: { VueCountdown },
  data() {
    return {
      email: "",
      otp: "",
      showUserNameForm: true,
      showVerifyOtpForm: false,
      showUserName: false,
      isProcessing: true,
      disableVerifyOtp: false,
      userName: "",
      apiProcessing1: false,
      apiProcessing2: false,
      errorAlert: [],
    };
  },
  computed: {
    programDefinition: function () {
      return this.$store.getters.programDefinition;
    },
    systemParameter: function () {
      return this.$store.getters.systemParameter;
    },
    getErrors() {
      return this.errorAlert.length >= 1 ? true : false;
    },
  },
  activated() {
    this.showUserNameForm = true;
    this.showVerifyOtpForm = false;
    this.showUserName = false;
    this.email = "";
    this.errorAlert = [];
  },
  methods: {
    submitBid() {
      this.errorAlert = [];
      const regexEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email === "") {
        this.errorAlert.push(i18n.t("addcomm.Please Enter Email"));
        return;
      }
      if (!regexEmail.test(this.email.toLowerCase())) {
        this.errorAlert.push(i18n.t("addcomm.Please Enter Valid Email"));
        return;
      }
      
      this.apiProcessing1 = true;
      // Generate OTP
      LBMSService.GenerateOTP({
        UniquerefID: this.email,
        SourceCode: 1,
        SourceAddress: "192.168.46.121",
        OtpType: "FORGOTUSERNAME",
        ProgramId: this.programDefinition.program_id,
        RelationType: this.$store.getters.relationType,
        AddExpirationTimeInMinutes: this.systemParameter.OTPExpirationTime,
      }).then(
        (response) => {
          // console.log(response);
          if (response.data.results.IsSuccessful) {
            this.apiProcessing1 = false;
            this.showUserNameForm = false;
            this.showVerifyOtpForm = true;
          } else {
            this.apiProcessing1 = false;
            this.$notification["error"]({
              message: i18n.t("addcomm.Error"),
              description: response.data.results.ErrorDescription,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    },
    callVerifyOtp() {
      this.errorAlert = [];
      if (this.otp === "") {
        this.errorAlert.push(i18n.t("addcomm.Please Enter OTP"));
        return;
      }
      this.apiProcessing2 = true;
      LBMSService.ForgotUserName({
        UniquerefID: this.email,
        OTP: this.otp,
        DestinationAddress: "192.168.46.111",
        Destination: "Web",
        OtpType: "FORGOTUSERNAME",
        ProgramId: this.programDefinition.program_id,
        RelationType: this.$store.getters.relationType,
      }).then(
        (response) => {
          // console.log(response);
          if (response.data.results.IsSuccessful) {
            this.apiProcessing2 = false;
            this.showVerifyOtpForm = false;
            this.showUserNameForm = false;
            this.userName = response.data.results.ReturnObject;
            this.showUserName = true;
          } else {
            this.apiProcessing2 = false;
            this.$notification["error"]({
              message: i18n.t("addcomm.Error"),
              description: response.data.results.ErrorDescription,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    onCountdownEnd() {
      this.disableVerifyOtp = true;
    },
    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
}

.login-form {
  margin-top: 15%;
  /* text-align: left; */
}

.btn {
  width: 100%;
}
</style>
