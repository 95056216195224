<template>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 main-center">
          <div>
            <br />
            <br />
            Select Year:
            <a-select
              :default-value="selectedYear"
              style="width: 120px"
              @change="handleChange"
            >
              <a-select-option
                :value="year"
                v-for="year in dropdownYearArray"
                :key="year"
              >
                {{ year }}
              </a-select-option>
            </a-select>
          </div>
          <div v-if="showNoTransactions" style="margin-top: 5%">
            <h4>No Record Found</h4>
          </div>
          <div v-if="!showNoTransactions && processing" style="margin-top: 5%">
            <h4>Processing.... Please Wait..</h4>
          </div>
          <div v-if="!processing">
            <table
              class="table table-bordered"
              v-if="!showNoTransactions && expiryPeriod.length > 0"
            >
              <thead>
                <tr>
                  <th scope="col">Schedule Date</th>
                  <th scope="col">Points</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in expiryPeriod" :key="data.TotalExpiredPoints">
                  <td>{{ formatDate(data.ScheduleDate) }}</td>
                  <td>{{ data.TotalExpiredPoints }}</td>
                </tr>
              </tbody>
            </table>
            <h5 v-if="expiryData !== ''">
              Your {{ expiryData.TotalExpiredPoints }} are going to expire on:
              {{ formatDate(expiryData.ScheduleDate) }}
            </h5>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
</template>
<script>
import moment from "moment";
import LBMSService from "@/api/lbmsService";
import { i18n } from "@/i18n";
export default {
  data() {
    return {
      programCurrency: "",
      isProcessing: false,
      dropdownYearArray: [],
      selectedYear: new Date().getFullYear(),
      period: "",
      expiryPeriod: [],
      expiryData: "",
      showNoTransactions: false,
      processing: true,
    };
  },
  computed: {
    programDefinition: function () {
      return this.$store.getters.programDefinition;
    },
  },
  created: function () {
    LBMSService.GetProgramCurrencyDefinitionList(
      this.programDefinition.program_id
    ).then(
      (response) => {
        // console.log(response);
        if (response.data.results.IsSuccessful) {
          const defaultData = response.data.results.ReturnObject.filter(
            (d) => d.IsDefault === true
          )[0];
          this.programCurrency = defaultData.Currency;
          if (this.programCurrency) {
            this.isProcessing = true;
            this.selectedYear = new Date().getFullYear() + 1;
            for (let i = 0; i < 4; i++) {
              this.dropdownYearArray.push(new Date().getFullYear() + i);
            }
            this.getExpirySchedule(this.dropdownYearArray[0]);
          }
        } else {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      },
      (error) => console.log(error)
    );
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    getExpirySchedule(selectedYear) {
      LBMSService.GetExpirySchedule({
        RelationReference: this.$store.getters.relationReference,
        RelationType: this.$store.getters.relationType,
        ProgramId: this.programDefinition.program_id,
        TransactionCurrency: this.programCurrency,
        Year: selectedYear,
      }).then((response) => {
        if (response.data.results.IsSuccessful) {
          this.expiryPeriod = response.data.results.ReturnObject.ExpiryPeriod;
          this.period = response.data.results.ReturnObject.Period;
          this.showNoTransactions = true;
          this.getNextExpiredPointsOnDate(
            this.expiryPeriod,
            this.period,
            this.dropdownYearArray[0]
          );
        } else {
          this.showNoTransactions = true;
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
    getNextExpiredPointsOnDate(expiryPeriod, period, selectedYear) {
      LBMSService.GetNextExpiredPointsOnDate({
        ExpiryPeriod: expiryPeriod,
        Period: period,
        RelationReference: this.$store.getters.relationReference,
        RelationType: this.$store.getters.relationType,
        ProgramId: this.programDefinition.program_id,
        TransactionCurrency: this.programCurrency,
        Year: selectedYear + 3,
      }).then((response) => {
        if (response.data.results.IsSuccessful) {
          this.expiryData = response.results.ReturnObject.ExpiryPeriod[0] || "";
        } else {
          this.expiryData = "";
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
    handleChange(year) {
      this.showNoTransactions = false;
      this.processing = true;
      if (year === this.dropdownYearArray[0]) {
        this.getExpirySchedule(year);
      } else {
        this.getNextYearExpirySchedule(year);
      }
    },
    getNextYearExpirySchedule(year) {
      LBMSService.GetNextYearExpirySchedule({
        ExpiryPeriod: this.expiryPeriod,
        Period: this.period,
        RelationReference: this.$store.getters.relationReference,
        RelationType: this.$store.getters.relationType,
        ProgramId: this.programDefinition.program_id,
        TransactionCurrency: this.programCurrency,
        Year: year,
      }).then((response) => {
        if (response.data.results.IsSuccessful) {
          this.expiryPeriod = response.results.ReturnObject.ExpiryPeriod;
          this.showNoTransactions = true;
        } else {
          this.expiryPeriod = [];
          this.showNoTransactions = true;
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
</style>