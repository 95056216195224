<template>
  <div class="container">
    <div class="row">
      <div class="col-md-9">
        <div v-if="buyable" class="row main" style="margin: 30px 0 0">
          <!-- <div class="col-md-1">
        <div v-for="img in buyable.imgs" :key="img">
          <img
            :src="img"
            v-bind:class="{ images: buyable.currentImg || (buyable.imgs?buyable.imgs[0]:'') === img }"
            class="card-img-top"
            @mouseover="mouseOverImage(img)"
          />
          <br /><br />
        </div> -->
          <!-- </div> -->
          <div class="col-md-3 onlywidth">
            <img :src="buyable.currentImg || buyable.imgs[0]" class="card-img-top" />
          </div>
          <div class="col-md-7">
            <div class="title">{{ buyable.name }}</div>
            <div>
              <div class="vhtml" v-html="buyable.description"></div>
              <div class="tips">
                {{ $t('addcomm.ExchangeGiiftPoints') }}
              </div>
              <br />
              <!-- <div style="color: #0095eb; font-size: 14px; margin-bottom: 10px">
                <u>{{ $t('details.Report Abuse') }}</u>
              </div> -->
              <div class="itembox">
                <span style="font-size: 14px; color: #686868">{{ $t('details.Categories') }}:</span
                >{{ buyable.categories ? buyable.categories[0] : "" }}
              </div>
              <!-- <div class="itembox">
                <span style="font-size: 14px; color: #686868">{{ $t('details.Country') }}: </span
                >{{ buyable.country }}
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="tc">
          <br /><br />
          <Related-Items :relatedBuyables="relatedBuyables"></Related-Items>
          <br /><br />
          <Recently-Viewed></Recently-Viewed>
        </div> -->
        <div>
          <vue-slider
            v-if="isShowSlider"
            class="vueslider"
            v-model="sliderData.currentValue"
            :data="sliderData.values"
            :marks="sliderData.marks"
            :max="maxSlider"
            :min="minSlider"
            :tooltip="'always'"
            :adsorb="true"
            :included="true"
            :drag-on-click="true"
            @change="getSliderValue"
            :disabled="isShowForm"
          >
          </vue-slider>
        </div>

        <div class="tc" id="terms" v-if="buyable ? buyable.tc : false">
            <div v-html="buyable.tc"></div>
        </div>
      </div>
      <div class="col-md-3" style="padding-top:30px">
        <div style="background-color: #eeeeee; padding: 34px 28px 24px">
          <div class="units">
            <div>
              {{ currentPointValue }}
              {{ $t('common.Points') }}
            </div>
            <div>({{ currentPointValue }} {{ $t('mine.miles') }})</div>
          </div>
          <div class="points">
            <div>{{ $t('details.Total Points') }}</div>
            <div>
              {{ currentPointValue }}
              x {{ Qty }} =
              {{ currentPointValue * Qty }}
              {{ $t('addcomm.smallPoints') }}
            </div>
          </div>
          <div class="quantity" v-if="!isShowForm">
            <div class="input-group mb-3 addlose">
              <div class="lose cursor" @click="decreaseQty">
                <img src="@/assets/img/paymentmethod/lose.png" alt="">
              </div>
              <input
                type="text"
                class="form-control"
                v-model="Qty"
                style="text-align: center; background:#ffffff; height: 38px"
                @keyup="getQtyInputChange"
                :disabled="isShowForm"
                readonly
              />
              <div class="lose cursor"  @click="increaseQty">
                <img src="@/assets/img/paymentmethod/add.png" alt="">
              </div>
            </div>
          </div>

          <div class="row buttons" v-if="$store.getters.IsUserLoggedIn">
            <div v-if="isShowForm">
              <vue-form-generator
              class="emailname"
                @validated="onValidated"
                :schema="schema"
                :model="model"
                :options="formOptions"
              ></vue-form-generator>
              <a-button
                type="primary"
                size="large"
                style="width: 100%"
                @click="redeemNow"
              >
                {{ $t('common.Redeem Now') }}
              </a-button>
              <a-button
                type="default"
                size="large"
                style="width: 100%;margin-top:10px"
                @click="isShowForm = !isShowForm"
              >
                {{ $t('common.Cancel') }}
              </a-button>
            </div>
            <a-button
              type="primary"
              size="large"
              @click="showForm"
              style="width:90%;margin:auto"
              v-if="!isShowForm"
              :disabled="isButtonDisabled"
            >
              {{ $t('details.Buy Now') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueFormService from "@/helpers/vueFormService";
import UserService from "@/api/user";
import {i18n} from "@/i18n";
// import RecentlyViewed from "@/components/recentlyViewed";
// import RelatedItems from "@/components/relatedItems";
export default {
  // components: { RecentlyViewed, RelatedItems },
  data() {
    return {
      buyableId: String,
      Qty: 1,
      isProcessing: true,
      isShowForm: false,
      isFormValid: false,
      relatedItems: [],
      relatedBuyables: {},
      isButtonDisabled: false,

      isShowSlider: false,
      maxSlider: 0,
      minSlider: 0,
      currentPointValue: "",
      sliderData: {
        values: [],
        currentValue: "",
        marks: true,
      },

      model: {},
      schema: {
        fields: [],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  activated() {
    this.configureFor(this.buyable);
  },
  computed: {
    buyable: function () {
      return this.$store.getters.activeBuyable;
    },
  },
  methods: {
    configureFor: function (buyable) {
      if (buyable) {
        this.schema.fields = [];
        this.sliderData.values = [];
        this.sliderData.currentValue = "";
        this.isShowForm = false;
        
        UserService.addRecentlyViewed(buyable);
        this.isProcessing = false;

        const units = this.buyable.units;
        if (units) {
          const unit = units[0];
          this.currentPointValue = unit.price.toFixed(2) || unit.min.toFixed(2);
          if (unit.type === "fixed") {
            this.isShowSlider = false;
            if (buyable.units.length > 1) {
              this.isShowSlider = true;
              for (let i = 0; i < buyable.units.length; i++) {
                if (buyable.units[i].type === "fixed") {
                  this.sliderData.values.push(
                    buyable.units[i].price.toFixed(2)
                  );
                }
              }
              this.sliderData.currentValue = this.sliderData.values[0];
              this.maxSlider = Number(this.sliderData.values[0]);
              this.minSlider = Number(
                this.sliderData.values[this.sliderData.values.length - 1]
              );
              if (buyable.units.length > 5) {
                this.sliderData.marks = false;
              }
            }
          } else if (unit.type === "variable") this.isShowSlider = false;

          if (buyable.metas && buyable.metas.form) {
            const objectArray = Object.entries(
              buyable.metas.form.schema[
                Object.keys(buyable.metas.form.schema)[0]
              ].properties
            );
            // console.log(objectArray);
            for (let i = 0; i < objectArray.length; i++) {
              this.model[objectArray[i][0]] = "";
              this.schema.fields.push({
                type: "input",
                inputType: objectArray[i][1].type,
                label: objectArray[i][1].title,
                model: objectArray[i][0],
                placeholder: "Enter " + objectArray[i][1].title,
                required: true,
                validator: () => {
                  if (objectArray[i][1].regex) {
                    if (
                      !objectArray[i][1].regex.match(
                        this.model[objectArray[i][0]]
                      )
                    ) {
                      return ["Regex Mismatch: " + objectArray[i][1].regex];
                    } else {
                      return [];
                    }
                  } else {
                    return [];
                  }
                },
              });
              if (i === objectArray.length - 1) {
                this.isProcessing = false;
                // console.log(this.fields);
              }
            }
          } else {
            const formData = VueFormService.getDefaultForm();
            this.schema.fields = formData.fields;
            this.model = formData.model;
          }
        }
        // related Items
        // this.relatedItems = UserService.relatedItems(
        //   this.$store.getters.filteredBuyables,
        //   buyable
        // );
        // console.log(this.relatedItems);
        this.relatedBuyables = {
          relatedItems: this.relatedItems,
          navContext: "topup/miles",
        };
        // console.log(this.relatedBuyables);

        this.getButtonDisabled();
      }
    },
    mouseOverImage(img) {
      this.buyable.currentImg = img;
    },
    decreaseQty() {
      if (this.Qty !== 1) {
        this.Qty = this.Qty - 1;
        this.getButtonDisabled();
      }
    },
    increaseQty() {
      this.Qty = this.Qty + 1;
      this.getButtonDisabled();
    },
    getSliderValue() {
      const currentSlider = this.sliderData.currentValue;
      this.currentPointValue = Number(currentSlider);
    },
    showForm() {
      this.isShowForm = true;
    },
    redeemNow() {
      const objectArray = Object.values(this.model);
      let allvaluePresent = false;
      objectArray.filter((item) => {
        if (item !== "") {
          allvaluePresent = true;
        } else {
          allvaluePresent = false;
        }
      });
      if (allvaluePresent && this.isFormValid) {
        this.$store.commit("mutBuyableRedeemSuccess", {
          buyable:  this.buyable, 
          points: this.currentPointValue * this.Qty,
          form: this.model
        });
        this.$store.dispatch("navigate", { c2: "success" });
      } else {
        this.$notification["error"]({
          message: i18n.t('addcomm.Error'),
          description: i18n.t('addcomm.Please enter all required fields'),
        });
      }
    },
    onValidated(isValid) {
      if (isValid) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    },
    getButtonDisabled() {
      if (
        Number(this.currentPointValue) * Number(this.Qty) <
        Number(this.$store.getters.points)
      ) {
        this.isButtonDisabled = false;
      } else {
        this.isButtonDisabled = true;
      }
    },
    getQtyInputChange() {
      if (this.Qty > 0) {
        this.getButtonDisabled();
      }
    },
  },
};
</script>

<style scoped>
.main {
  /* text-align: left; */
  /* background-color: #f9f9f9; */
  margin-top: 30px;
  padding: 30px 12px;
  background-color: #ffffff;
}

.points {
  /* text-align: left; */
  font-size: 14px;
  color: #1f1f1f;
}
.points div:last-child {
  font-size: 12px;
  color: #999999;
  margin-top: 5px;
  margin-bottom: 10px;
}
.quantity .addlose .lose img {
  height: 38px;
}
.units {
  text-align: center;
  font-size: 12px;
  color: #545454;
  margin-bottom: 15px;
}
.units div:first-child {
  font-size: 16px;
  color: #1f1f1f;
  margin-bottom: 5px;
}

.quantity-input {
  width: 50%;
  margin-top: -1%;
  text-align: center !important;
}


.images {
  border: 2px solid;
}

.cursor {
  cursor: pointer;
}

.tc {
  margin-top: 10px;
  background-color: #fff;
  /* text-align: left; */
  padding: 20px 15px;
  box-sizing: border-box;
}
.tc .title{
  font-size: 18px;
  color: #333;
  /* text-align: left; */
  position: relative;
  text-align: center;
  top: 5px;
}

div >>> .tc h2 {
  font-size: 18px;
}

.onlywidth {
  width: 285px;
}
.card-img-top {
  width: 260px;
  border: solid 1px #eeeeee;
}
.title {
  font-size: 20px;
  color: #1f1f1f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vhtml {
  font-size: 14px;
  color: #8e8d8d;
  margin: 2px 0 10px;
}
.tips {
  font-size: 14px;
  color: #1f1f1f;
}
.itembox span {
  display: inline-block;
  width: 90px;
  margin-bottom: 5px;
}
.units {
  text-align: center;
  font-size: 12px;
  color: #545454;
  margin-bottom: 15px;
}
.units div:first-child {
  font-size: 16px;
  color: #1f1f1f;
  margin-bottom: 5px;
}
.emailname {
  font-size: 12px;
  color: #1f1f1f;
  /* text-align: left; */
}
/deep/ .form-group {
  font-size: 14px;
  color: #1f1f1f;
}
/deep/ .form-group input {
  margin-top: 6px;
}
.vueslider {
  background-color: #fff;
  margin-top: 10px;
  height: 10px !important;
  padding: 80px 80px 60px !important;
  color: #999;
}
/deep/ .vue-slider-dot-tooltip-inner {
  padding: 7px 7px;
  color: #2c2b2b;
  font-size: 14px;
  background-color: #a9e0ff;
  border-color: #a9e0ff;
}
/deep/ .vue-slider-process {
  background: #0095eb;
}
</style>
