<template>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 main-center">
          <div class="row">
            <div>Redemption Transactions</div>
            <div :style="{ marginTop: '16px' }">
              <a-radio-group
                :default-value="radioValue"
                @change="getradioValues($event.target.value)"
              >
                <a-radio-button value="a"> All Transactions </a-radio-button>
                <a-radio-button value="b"> Custom Search </a-radio-button>
              </a-radio-group>
            </div>
            <div v-if="showCustomSearchDiv">
              <br /><br />
              <a-range-picker
                @change="onChangeDatepicker($event)"
                format="LL"
              />
            </div>
            <div>
              <br /><br />
              <table class="table table-bordered" v-if="tableData.length !== 0">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Points</th>
                    <th scope="col">Transaction Date</th>
                    <th scope="col">Processing Date</th>
                    <th scope="col">Transaction Type</th>
                    <th scope="col">Narration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tableData" :key="item.Id">
                    <td>{{ item.Id }}</td>
                    <td>{{ item.Amounts }}</td>
                    <td>{{ item.Points }}</td>
                    <td>{{ formatDate(item.TransactionDate) }}</td>
                    <td>{{ formatDate(item.ProcessingDate) }}</td>
                    <td>{{ formatTransactionType(item.TransactionType) }}</td>
                    <td>{{ item.Narration }}</td>
                  </tr>
                </tbody>
              </table>
              <div
                class="text-center"
                v-if="tableData.length === 0 && isProcessing === false"
              >
                <h4>No Transactions Available</h4>
              </div>
              <div
                class="text-center"
                v-if="tableData.length === 0 && isProcessing === true"
              >
                <h4>Processing... Please Wait...</h4>
              </div>
              <br /><br />
              <a-pagination
                v-if="tableData.length !== 0"
                :default-current="currentPage"
                :total="totalTableData"
                :pageSize="pageSize"
                @change="pagination"
              />
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
</template>

<script>
import moment from "moment";
import LBMSService from "@/api/lbmsService";
import { i18n } from "@/i18n";
export default {
  data() {
    return {
      programCurrency: "",
      radioValue: "a",
      totalTableData: 0,
      tableData: [],
      isProcessing: false,
      showCustomSearchDiv: false,

      currentPage: 1,
      startIndex: 0,
      endIndex: 10,
      pageSize: 10,
    };
  },
  beforeMount() {
    this.isProcessing = true;
  },
  computed: {
    programDefinition: function () {
      return this.$store.getters.programDefinition;
    },
  },
  created() {
    LBMSService.GetProgramCurrencyDefinitionList(
      this.programDefinition.program_id
    ).then(
      (response) => {
        // console.log(response);
        if (response.data.results.IsSuccessful) {
          const defaultData = response.data.results.ReturnObject.filter(
            (d) => d.IsDefault === true
          )[0];
          this.programCurrency = defaultData.Currency;
          if (this.programCurrency) {
            this.isProcessing = true;
            this.getAllTransactions();
          }
        } else {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    formatTransactionType(value) {
      const types = ["Credit", "Debit", "Blocked", "None", "Void"];
      return types[value - 1];
    },
    getAllTransactions() {
      LBMSService.GetTotalMemberRedemptionTransaction({
        ProgramId: this.programDefinition.program_id,
        RelationReference: this.$store.getters.relationReference,
        RelationType: this.$store.getters.relationType,
        TransactionCurrency: this.programCurrency,
      }).then((response) => {
        // console.log(response);
        if (response.data.results.IsSuccessful) {
          this.totalTableData = response.data.results.ReturnObject;
          this.getAllTransactionByRange("0", "10");
        } else {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
    getAllTransactionByRange(minRange, maxRange) {
      this.isProcessing = true;
      LBMSService.GetMemberRedemptionTransaction({
        MaximumRange: maxRange,
        MinimumRange: minRange,
        ProgramId: this.programDefinition.program_id,
        RelationReference: this.$store.getters.relationReference,
        RelationType: this.$store.getters.relationType,
        TransactionCurrency: this.programCurrency,
      }).then((response) => {
        if (response.data.results.IsSuccessful) {
          // console.log(response);
          this.tableData = [];
          this.tableData = response.data.results.ReturnObject;
          this.isProcessing = false;
        } else {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
    getradioValues(value) {
      this.radioValue = value;
      this.tableData = [];
      if (this.radioValue === "b") {
        this.tableData = [];
        this.showNoTransactions = true;
        this.fromDate = "";
        this.toDate = "";
        this.startIndex = 0;
        this.endIndex = 10;
        this.currentpage = 1;
        // this.getAllCustomTransaction(this.fromDate, this.toDate);
        this.showCustomSearchDiv = true;
        this.isProcessing = true;
      } else {
        this.tableData = [];
        this.dateRange = [];
        this.fromDate = "";
        this.toDate = "";
        this.startIndex = 0;
        this.endIndex = 10;
        this.currentpage = 1;
        this.getAllTransactions();
        this.showCustomSearchDiv = false;
        this.isProcessing = true;
      }
    },
    pagination(page) {
      this.currentPage = page;
      this.startIndex = (this.currentPage - 1) * this.pageSize;
      this.endIndex = this.startIndex + this.pageSize;

      this.tableData = [];
      if (this.radioValue === "b") {
        this.getAllCustomTransactionByDateAndRange(
          (this.startIndex + 1).toString(),
          this.endIndex.toString(),
          this.fromDate,
          this.toDate
        );
      } else {
        this.getAllTransactionByRange(
          (this.startIndex + 1).toString(),
          this.endIndex.toString()
        );
      }
    },
    onChangeDatepicker(dates) {
      this.dateRange = dates;
      this.fromDate = this.convertDate(0);
      this.toDate = this.convertDate(1);
      if (this.fromDate !== "" && this.toDate !== "") {
        this.tableData = [];
        // this.showNoTransactions = false;
        this.getAllCustomTransaction(this.fromDate, this.toDate);
      } else {
        this.$notification["error"]({
          message: i18n.t("addcomm.Error"),
          description: i18n.t("addcomm.Please select Date"),
        });
      }
    },
    getAllCustomTransaction(fromDate, toDate) {
      LBMSService.GetTotalMemberRedemptionTransactionByDate({
        DateFrom: fromDate,
        DateTo: toDate,
        ProgramId: this.programDefinition.program_id,
        RelationReference: this.$store.getters.relationReference,
        RelationType: this.$store.getters.relationType,
        TransactionCurrency: this.programCurrency,
      }).then((response) => {
        if (response.data.results.IsSuccessful) {
          // console.log(response);
          this.totalTableData = response.data.results.ReturnObject;
          this.getAllCustomTransactionByDateAndRange(
            "0",
            "10",
            fromDate,
            toDate
          );
        } else {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
    getAllCustomTransactionByDateAndRange(
      minRange,
      maxRange,
      fromDate,
      toDate
    ) {
      LBMSService.GetMemberRedemptionTransactionByDate({
        DateFrom: fromDate,
        DateTo: toDate,
        MaximumRange: maxRange,
        MinimumRange: minRange,
        ProgramId: this.programDefinition.program_id,
        RelationReference: this.$store.getters.relationReference,
        RelationType: this.$store.getters.relationType,
        TransactionCurrency: this.programCurrency,
      }).then((response) => {
        if (response.data.results.IsSuccessful) {
          this.tableData = [];
          this.tableData = response.data.results.ReturnObject;
          this.isProcessing = false;
        } else {
          this.isProcessing = false;
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
    convertDate(i) {
      const date = new Date(this.dateRange[i]);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "T00:00:00"
      );
    },
  },
};
</script>

<style scoped>
.main-center {
  padding: 10px;
  margin-left: 20px;
  /* text-align: left; */
}
</style>