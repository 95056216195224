<template>
        <div class="col-md-7">
          <div class="title">{{ $t('mine.Change Password') }}</div>
          <form class="login-form">
            <div class="panel-body">
              <vue-form-generator
                  :schema="schema"
                  :model="model"
                  :options="formOptions"
              ></vue-form-generator>
              <div class="btmbtn">
                <a-button type="primary" block style="margin-bottom: 30px">
                  {{ $t('common.Cancel') }}
                </a-button>
                <a-button type="primary" block @click.prevent="submit">
                  {{ $t('common.Submit') }}
                </a-button>
              </div>
            </div>
          </form>
        </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import md5 from "js-md5";
import LBMSService from "@/api/lbmsService";
import {i18n} from '@/i18n';

export default {
  data() {
    return {
      isFormValid: false,
      model: {
        Password: "",
        NewPassword: "",
        confirmpassword: "",
      },
      schema: {
        fields: [
          {
            type: "input",
            inputType: "password",
            label: i18n.t("mine.Your Current Password"),
            model: "Password",
            placeholder: i18n.t("addcomm.Please enter Your Current Password"),
            required: true,
            validator: VueFormGenerator.validators.string,
          },
          {
            type: "input",
            inputType: "password",
            label: i18n.t("mine.New Password"),
            model: "NewPassword",
            placeholder: i18n.t("addcomm.Please enter New Password"),
            required: true,
          },
          {
            type: "input",
            inputType: "password",
            label: i18n.t("common.Confirm Password"),
            model: "confirmpassword",
            placeholder: i18n.t("addcomm.Please Enter Confirm Password"),
            required: true,
            validator: this.npasswordValidator,
          },
        ],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  beforeMount() {
    this.isProcessing = true;
  },
  computed: {
    programDefinition: function () {
      return this.$store.getters.programDefinition;
    },
  },
  methods: {
    submit() {
      if (this.Password === "") {
        this.$notification["error"]({
          message: i18n.t('addcomm.Login Error'),
          description: i18n.t('addcomm.Please enter Your Current Password'),
        });
        return;
      }
      if (this.NewPassword === "") {
        this.$notification["error"]({
          message: i18n.t('addcomm.Login Error'),
          description: i18n.t('addcomm.Please enter New Password'),
        });
        return;
      }
      if (this.confirmpassword === "") {
        this.$notification["error"]({
          message: i18n.t('addcomm.Login Error'),
          description: i18n.t('addcomm.Please Enter Confirm Password'),
        });
        return;
      }
      if (this.NewPassword !== this.confirmpassword) {
        this.$notification["error"]({
          message: i18n.t('addcomm.Login Error'),
          description: i18n.t('addcomm.New Confirm Password must match')
        });
        return;
      }
      const encryptedPass = md5(
          this.$store.getters.userName + this.model["Password"]
      );
      const encryptedPass1 = md5(
          this.$store.getters.userName + this.model["NewPassword"]
      );
      LBMSService.ChangePassword({
        RelationReference: this.$store.getters.relationReference,
        RelationType: 4,
        ProgramId: this.programDefinition.program_id,
        Password: encryptedPass.replace("\n", "").replace("\r", ""),
        NewPassword: encryptedPass1.replace("\n", "").replace("\r", ""),
      }).then((response) => {
        console.log(response);
        if (response.data.results.IsSuccessful) {
          this.$notification["success"]({
            message: i18n.t('addcomm.Success'),
            description: i18n.t('addcomm.Password changed successfully')
          });
        } else {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
    npasswordValidator() {
      if (this.model.NewPassword !== this.model.confirmpassword) {
        return ["Password Mismatch"];
      }
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  font-size: 18px;
  color: #595959;
  margin-bottom: 40px;
}

.login {
  width: 100%;
  height: 100%;
}

.login-form {
  margin-top: 5%;
  /* text-align: left; */
}

.btn {
  width: 100%;
}

.onlythis {
  width: 100%;
  background-color: #ffffff;
  margin: auto;
  padding-top: 40px;
  box-sizing: border-box;

}

.onlythis .col-md-7 {
  /*border: 1px solid red;*/
  margin: auto;
}

.onlythis /deep/ .form-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.onlythis /deep/ label {
  width: 200px;
  margin-right: 50px;
  font-size: 14px;
  color: #595959;
  text-align: right;
}

/deep/ .field-wrap {
  width: 350px;
}

.btmbtn {
  display: flex;
  margin: 20px auto;
  width: 200px;
  justify-content: space-between;
}

.btmbtn button {
  width: 80px;
  height: 30px;
  background-color: #e5e5e5;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  color: #ffffff;
}

.btmbtn button:last-child {
  background-color: #0095eb;
}
</style>
