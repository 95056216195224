import { render, staticRenderFns } from "./HotelReview.vue?vue&type=template&id=3fbdd707&scoped=true&"
import script from "./HotelReview.vue?vue&type=script&lang=js&"
export * from "./HotelReview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fbdd707",
  null
  
)

export default component.exports