






















export default {
  data() {
    return {
      searchtext: "",
    };
  },
};
