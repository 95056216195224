<template>
  <div>
    <div style="width: 100%; height: 100%" v-if="maxPointFilter.length !== 0">
      <!-- Total Points -->
      <a-card
        :title="
          $t('addcomm.By Price') +
          '  (' +
          this.minPoint +
          '-' +
          this.maxPoint +
          ')'
        "
        :bordered="false"
        style="width: 100%"
      >
        <a-slider
          range
          :max="this.maxPoint"
          :min="this.minPoint"
          :default-value="[this.minPointFilter, this.maxPointFilter]"
          @afterChange="getSliderValue($event)"
        />
        <!-- :defaultValue="[this.minPoint, this.maxPoint]" -->
        <!-- :v-model="this.hotelPointsFilters" -->
        <p style="text-align: center">
          {{ this.minPointFilter + "-" + this.maxPointFilter }}
        </p>
      </a-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      minPointFilter: 0,
      maxPointFilter: 0,
      maxPoint: 0,
      minPoint: 0,
    };
  },
  watch: {
    filterPoints: function () {
      this.$emit("shopPoints", this.filterPoints);
      this.minPoint = this.filterPoints[0];
      this.maxPoint = this.filterPoints[1];
      this.minPointFilter = this.filterPoints[0];
      this.maxPointFilter = this.filterPoints[1];
    },
  },
  updated() {
    // console.log(
    //   "updated called",
    //   this.maxPoint + " maxPoint ",
    //   this.minPoint + "minPoint",
    //   this.maxPointFilter + " maxPointFilter",
    //   this.filterPoints + " filterPoints"
    // );
  },
  created() {
    this.minPointFilter = this.$store.getters.shopFilters.minPoint || 0;
    this.maxPointFilter = this.$store.getters.shopFilters.maxPoint || 0;
    this.maxPoint = this.$store.getters.shopFilters.maxPoint;
    this.minPoint = this.$store.getters.shopFilters.minPoint;
  },
  methods: {
    getSliderValue(value) {
      this.maxPointFilter = value[1];
      this.minPointFilter = value[0];
      this.$emit("shopPoints", [value[0], value[1]]);
    },
  },
  computed: {
    filterPoints() {
      return [
        this.$store.getters.shopFilters.minPoint,
        this.$store.getters.shopFilters.maxPoint,
      ];
    },
  },
};
</script>
<style scoped>
</style>