<template>
  <div style="width: 100%">
    <div class="row login">
      <div class="col-md-4"></div>
      <div class="col-md-4" v-if="forgotPasswordForm">
        <form class="login-form">
          <h6>Set Password</h6>
          <br />
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="password"
            />
          </div>
          <div class="mb-3">
            <label for="cpassword" class="form-label">Confirm Password</label>
            <input
              type="password"
              class="form-control"
              id="cpassword"
              v-model="cpassword"
            />
          </div>
          <div class="mb-3" v-if="getErrors">
            <a-alert
              v-for="(err, index) in errorAlert"
              :key="err"
              :message="err"
              type="error"
              closable
              class="mt-3"
              @close="handleErrorClose(index)"
              show-icon
            />
          </div>
          <a-button type="primary" block @click.prevent="forgotPasswordSubmit">
            Submit
          </a-button>
        </form>
      </div>
      <div class="col-md-4" v-if="activateForm">
        <form class="login-form">
          <h6>Set UserName and Password</h6>
          <br />
          <div class="mb-3">
            <label for="password" class="form-label">User Name</label>
            <input
              type="text"
              class="form-control"
              id="username"
              v-model="username"
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input
              type="text"
              class="form-control"
              id="apassword"
              v-model="apassword"
            />
          </div>
          <div class="mb-3">
            <label for="cpassword" class="form-label">Confirm Password</label>
            <input
              type="text"
              class="form-control"
              id="acpassword"
              v-model="acpassword"
            />
          </div>
          <div class="mb-3" v-if="getErrors">
            <a-alert
              v-for="(err, index) in errorAlert"
              :key="err"
              :message="err"
              type="error"
              closable
              class="mt-3"
              @close="handleErrorClose(index)"
              show-icon
            />
          </div>
          <a-button type="primary" block @click.prevent="activateFormSubmit">
            Submit
          </a-button>
        </form>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";
import { i18n } from "@/i18n";
import LBMSService from "@/api/lbmsService";

export default {
  components: {},
  data() {
    return {
      isProcessing: true,
      // forgot password form
      password: "",
      cpassword: "",
      forgotPasswordForm: false,

      // active account form
      username: "",
      apassword: "",
      acpassword: "",
      activateForm: false,
      errorAlert: []
    };
  },
  computed: {
    programDefinition: function () {
      return this.$store.getters.programDefinition;
    },
    systemParameter: function () {
      return this.$store.getters.systemParameter;
    },
    getErrors() {
      return this.errorAlert.length >= 1 ? true : false;
    },
  },
  activated() {
    if (this.$store.getters.passwordPageType) {
      const data = this.$store.getters.passwordPageType;
      if (data.type === "ForgotPassword") {
        this.forgotPasswordForm = true;
        this.activateForm = false;
      }
      if (data.type === "Activate") {
        this.activateForm = true;
        this.forgotPasswordForm = false;
      }
    }
    //   if (this.$route.params.type === "Activate") {
    //     this.activateForm = true;
    //     this.forgotPasswordForm = false;
    //   }
    // }
  },
  // created() {},
  methods: {
    forgotPasswordSubmit() {
      this.errorAlert = [];
      if (this.password === "") {
        this.errorAlert.push(i18n.t("addcomm.Please Enter Password"));
        return;
      }
      if (this.cpassword === "") {
        this.errorAlert.push(i18n.t("addcomm.Please Enter Confirm Password"));
        return;
      }
      if (this.password !== this.cpassword) {
        this.errorAlert.push(i18n.t("addcomm.Password Mismatch"));
        return;
      }
      const encryptedPass = md5(this.$store.getters.passwordPageType.username + this.password);
      LBMSService.ForgotPassword({
        EmailID: this.$store.getters.passwordPageType.email,
        RelationType: Number(this.$store.getters.relationType),
        ProgramId: Number(this.programDefinition.program_id),
        NewPassword: encryptedPass.replace("\n", "").replace("\r", ""),
      }).then(
        (response) => {
          // console.log(response);
          if (response.data.results.IsSuccessful) {
            this.$notification["success"]({
              message: i18n.t("addcomm.Success"),
              description: i18n.t("addcomm.Your Password Changed Successfully"),
            });
            this.$store.dispatch('navigate', { layer: 'user', c2: 'login' });
          } else {
            this.$notification["error"]({
              message: i18n.t("addcomm.Error"),
              description: response.data.results.ErrorDescription,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    activateFormSubmit() {
      this.errorAlert = [];
      if (this.username === "") {
        this.errorAlert.push(i18n.t("addcomm.Please Enter Username"));
        return;
      }
      if (this.apassword === "") {
        this.errorAlert.push(i18n.t("addcomm.Please Enter Password"));
        return;
      }
      if (this.a_cpassword === "") {
        this.errorAlert.push(i18n.t("addcomm.Please Enter Confirm Password"));
        return;
      }
      if (this.apassword !== this.a_cpassword) {
        this.errorAlert.push(i18n.t("addcomm.Password Mismatch"));
        return;
      }
      LBMSService.CheckUserNameAvailable({
        UserName: this.username,
        ProgramId: this.programDefinition.program_id,
        RelationType: this.$store.getters.relationType,
      }).then((response) => {
        // console.log(response);
        if (response.data.results.IsSuccessful) {
          this.callMemberActivation();
        } else {
          this.$notification["error"]({
            message: "Error",
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
    callMemberActivation() {
      const encryptedPass = md5(this.username + this.apassword);
      const postdata = {
        EmailID: this.$store.getters.passwordPageType.email,
        UserName: this.username,
        Password: encryptedPass.replace("\n", "").replace("\r", ""),
        RelationType: this.$store.getters.relationType,
        ProgramId: this.programDefinition.program_id,
      };
      this.$axios.post("/api/callback/v1/lbms/user/MemberActivation", postdata).then((response) => {
        // console.log(response);
        if (response.data.results.IsSuccessful) {
          this.$notification["success"]({
            message: i18n.t("addcomm.Success"),
            description: i18n.t("addcomm.Account Activated Successfully"),
          });
        } else {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: response.data.results.ErrorDescription,
          });
        }
      });
    },
    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
    },
  },
};
</script>
<style scoped>
.login {
  width: 100%;
  height: 100%;
}

.login-form {
  margin-top: 15%;
  /* text-align: left; */
}
.btn {
  width: 100%;
}
</style>