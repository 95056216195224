import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/i18n/locale/en.json'
import axios from 'axios'
import store from '@/store'

Vue.use(VueI18n)

const vi18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    messages: {
        en
    } // set locale messages
})

export const li18n = {
    loadLanguageAsync: (s: string): Promise<VueI18n> => LAP(vi18n, s).then(() => vi18n),
    ...vi18n
}
export const i18n = vi18n

const loadedLanguages = ['en'] // our default language that is preloaded

function setI18nLanguage (lang: string) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html')?.setAttribute('lang', lang)
    if (lang === 'ar') {
        document.documentElement.style.direction = 'rtl'
        document.documentElement.style.textAlign = 'right'
    } else {
        document.documentElement.style.direction = 'ltr'
        document.documentElement.style.textAlign = 'left'
    }
    store.commit('mutLang',lang)
    return lang
}

function LAP(i18n: VueI18n, lang: string = 'en'): Promise<string> {
    // If the same language
    if (i18n.locale === lang)
        return Promise.resolve(setI18nLanguage(lang))

    // If the language was already loaded
    if (loadedLanguages.includes(lang))
        return Promise.resolve(setI18nLanguage(lang))

    // If the language hasn't been loaded yet
    return import (/* webpackChunkName: "lang-[request]" */ `./locale/${lang}.json`)
        .then(messages => {
            i18n.setLocaleMessage(lang, messages.default)
            loadedLanguages.push(lang)
            setI18nLanguage(lang)
            return lang
        })
}
