<template>
  <!-- <div style="marging-top: 5%; margin-left: 5%; margin-right: 5%">
    <br />
    <h5>Flight Booking Successful</h5>
    <br />
    <img src="./icons/success.gif" height="200px" width="200px" />
    <div class="row" v-if="flightBookingResponse !== ''">
      <h5>
        BookingReference:
        {{ flightBookingResponse.PNRDetails.BookingReference }}
      </h5>
      <h5>Booking Id: {{ flightBookingResponse.BookingId }}</h5>
    </div>
  </div> -->
  <div>
    <div class="row">
      <div class="col-md-2"></div>
      <div
        class="col-md-8"
        v-if="!isProcessing"
        style="margin-top: 3%; margin-bottom: 5%"
      >
        <div style="padding: 15px; border: 1px solid; border-radius: 4px"><br/>
          <div class="row" style="text-align: left" v-if="flightBookingResponse !== ''">
            <h6>Thank you. Flight booking is now confirmed</h6>
            <h6 v-if="flightBookingResponse.PNRDetails">
              Booking Reference:
              {{ flightBookingResponse.PNRDetails.BookingReference || "" }}
            </h6>
            <h6>Booking Id: {{ flightBookingResponse.BookingId || "" }}</h6>
            <h6>
              Total Points: {{ itineraryResponse.FareDetails.ActualPoints || "" }}
            </h6>
          </div><br/>
          <div class="row" style="text-align: left">
            <div class="col-md-12" style="text-align: left">
              <a-card size="small" title="Booking Details" style="width: 100%">
                <div class="row">
                  <h6><u> Passenger(s) </u></h6>
                  <div
                    v-for="(pass, index) in itineraryResponse.TravelerInfo"
                    :key="index"
                  >
                    <h6>
                      {{ index + 1 }}) {{ pass.Prefix }}
                      {{ pass.FirstName }}
                      {{ pass.LastName }} ({{ pass.PaxType }}),
                      {{ pass.Gender }},
                      {{ pass.Age }}
                    </h6>
                  </div>
                </div><br/>
                <div class="row">
                  <div class="col-md-6">
                      <h5><u>Depart: {{itineraryResponse.OriginLocation}} - {{itineraryResponse.DestinationLocation}}</u></h5>
                      
                      <div v-for="(details, index) in itineraryResponse.ListOfFlightDetails[0].ListOfFlightSegments" :key="index">
                        <hr v-if="index > 0" style="width: 90%">
                      <p> {{details.DisplayDepartureDate}} &nbsp;&nbsp;&nbsp;
                          {{details.DisplayDepartureTime}} &nbsp;&nbsp;&nbsp;
                          {{details.DepartureAirField.IATACode}},
                          {{details.DepartureAirField.AirportName}} &nbsp;&nbsp;&nbsp;
                          {{details.Carrier.CarrierName}} &nbsp;&nbsp;&nbsp;
                          {{formatJourneyTime(details.DisplayJourneyTime)}} 
                      </p>
                      <p> {{details.DisplayArrivalDate}} &nbsp;&nbsp;&nbsp;
                          {{details.DisplayArrivalTime}} &nbsp;&nbsp;&nbsp;
                          {{details.ArrivalAirField.IATACode}},
                          {{details.ArrivalAirField.AirportName}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </p>
                      </div>
                      <br/>
                  </div>
                  <div class="col-md-6" v-if="itineraryResponse.ListOfFlightDetails.length > 0">
                    <h5><u>Return: {{itineraryResponse.DestinationLocation}} - {{itineraryResponse.OriginLocation}}</u></h5>

                    <div v-for="(details, index) in itineraryResponse.ListOfFlightDetails[1].ListOfFlightSegments" :key="index">
                        <hr v-if="index > 0" style="width: 90%">
                      <p> {{details.DisplayDepartureDate}} &nbsp;&nbsp;&nbsp;
                          {{details.DisplayDepartureTime}} &nbsp;&nbsp;&nbsp;
                          {{details.DepartureAirField.IATACode}},
                          {{details.DepartureAirField.AirportName}} &nbsp;&nbsp;&nbsp;
                          {{details.Carrier.CarrierName}} &nbsp;&nbsp;&nbsp;
                          {{ formatJourneyTime(details.DisplayJourneyTime) }} 
                      </p>
                      <p> {{details.DisplayArrivalDate}} &nbsp;&nbsp;&nbsp;
                          {{details.DisplayArrivalTime}} &nbsp;&nbsp;&nbsp;
                          {{details.ArrivalAirField.IATACode}},
                          {{details.ArrivalAirField.AirportName}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </p>
                      </div>
                      <br/>
                  </div>
                </div>
              </a-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flightBookingResponse: "",
      selectedFlight: "",
      itineraryResponse: "",
      isProcessing: true,
    };
  },
  activated() {
    this.flightBookingResponse =
      this.$store.getters["flight/flightBookingResponse"] || "";
    this.selectedFlight = this.$store.getters["flight/selectedFlight"] || "";
    this.itineraryResponse =
      this.$store.getters["flight/itineraryResponse"] || "";
    this.isProcessing = false;
    console.log(this.flightBookingResponse);
    // console.log(this.selectedFlight);
    // console.log(this.itineraryResponse);
  },
  methods: {
    formatJourneyTime(timeString){
      return timeString.replace(':', ' Hr ').concat(' Min')
    }
  }
};
</script>
<style scoped>
</style>