import _ from "lodash";
import {i18n} from "@/i18n";
export default {

    searchFormValidation(vm: any) {
        const errorAlert = [];
        if (!vm.form.SearchCountry || vm.form.SearchCountry == "") {
            errorAlert.push(i18n.t('addcomm.Please select Country'));
          }
          if (!vm.form.City || vm.form.City == "") {
            errorAlert.push(i18n.t('addcomm.Please select City'));
          }
          if (!vm.form.checkInDate || vm.form.checkInDate == "") {
            errorAlert.push(i18n.t('addcomm.Please select Check-In Date'));
          }
          if (!vm.form.checkOutDate || vm.form.checkOutDate == "") {
            errorAlert.push(i18n.t('addcomm.Please select Check-Out Date'));
          }
          return errorAlert;
    },

    customerFormValidation(vm: any) {
        const errorAlert = [];
        if (vm.form.firstname === "") {
            errorAlert.push("Please Enter First Name");
        }
        if (vm.form.lastname === "") {
            errorAlert.push("Please Enter Last Name");
        }
        if (vm.form.country === "") {
            errorAlert.push("Please Enter Country Name");
        }
        if (vm.form.city === "") {
            errorAlert.push("Please Enter City Name");
        }
        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regexEmail.test(vm.form.Email.toLowerCase()) || vm.form.Email === "") {
            errorAlert.push("Please Enter Valid Email");
        }
        if (vm.form.postcode === "") {
            errorAlert.push("Please Enter Postal Code");
        }
        if (vm.form.mobileNo === "") {
            errorAlert.push("Please Enter Mobile Number");
        }
        return errorAlert;
    },

    removeDuplicates(arr: any) {
        return arr.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
    },

    filterPayload(payload: any, filters: any) {
        // console.log(payload, filters);
        if (filters.hotelRatingFilters.length === 0 ||
            // filters.hotelPointsFilters.length === 0 ||
            filters.hotelAmenitiesFilters.length === 0) {
            return [];
        }
        // // Filter Hotel Rating
        if (filters.hotelRatingFilters !== undefined && filters.hotelRatingFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                data.basicinfo.starrating = Number(data.basicinfo.starrating)
                if (filters.hotelRatingFilters.includes(data.basicinfo.starrating)) {
                    return data;
                }
            });
        }

        // // Filter Hotel Points
        if (filters.hotelPointsFilters !== undefined && filters.hotelPointsFilters.length !== 0) {
            payload = payload.filter((data: any) => {
                data.roomrates.RoomRate[0].TotalPoints = Number(data.roomrates.RoomRate[0].TotalPoints)
                if ((data.roomrates.RoomRate[0].TotalPoints >= filters.hotelPointsFilters[0]) && (data.roomrates.RoomRate[0].TotalPoints <= filters.hotelPointsFilters[1])) {
                    return data;
                }
            });
        }

        // Filter Hotel Amenities
        if (filters.hotelAmenitiesFilters !== undefined && filters.hotelAmenitiesFilters.length !== 0) {
            let latestPayload: any[] = [];
            payload.filter((data: any) => {
                filters.hotelAmenitiesFilters.filter((item: any) => {
                    if (item === "Bar") {
                        if (data.basicinfo.Amenities.includes('Bar/lounge' || 'Bar' || 'Poolside bar' || 'Snack bar/deli')) {
                            latestPayload.push(data)
                        }
                    }
                    if (item === "Wi-Fi Access") {
                        if (data.basicinfo.Amenities.includes('Free WiFi')) {
                            latestPayload.push(data)
                        }
                    }
                    if (item === "Coffee shop") {
                        if (data.basicinfo.Amenities.includes('Coffee shop or café')) {
                            latestPayload.push(data)
                        }
                    }
                    if (item === "Gym") {
                        if (data.basicinfo.Amenities.includes('Fitness facilities' || '24-hour fitness facilities')) {
                            latestPayload.push(data)
                        }
                    }
                    if (item === "Pool") {
                        if (data.basicinfo.Amenities.includes('Outdoor pool' || 'Swimming pool')) {
                            latestPayload.push(data)
                        }
                    }
                    if (item === "Restaurant") {
                        if (data.basicinfo.Amenities.includes('Restaurant')) {
                            latestPayload.push(data)
                        }
                    }
                    if (item === "Business Centre") {
                        if (data.basicinfo.Amenities.includes('Business center' || '24-hour business center' || 'Meeting rooms' || 'One meeting room')) {
                            latestPayload.push(data)
                        }
                    }
                });
            });
            latestPayload = _.uniqBy(latestPayload, 'hotelid');
            payload = latestPayload;
        }
        return payload;
    },

    CleanPayload(payload: any) {
        const r: any = {
            allHotelRating: [],
            allHotelPoints: [],
            minPoint: 0,
            maxPoint: 0
        };
        if (payload && payload.length > 0) {
            payload.forEach((item: any) => {
                item.basicinfo.Amenities = [];
                item.basicinfo.starrating = Number(item.basicinfo.starrating);
                r.allHotelRating.push(item.basicinfo.starrating);
                r.allHotelPoints.push(item.roomrates.RoomRate[0].TotalPoints);
    
                item.isRestaurant = false;
                item.isWifi = false;
                item.isGym = false;
                item.isMeetingRoom = false;
                item.isPool = false;
                item.basicinfo.hotelamenities.Amenities[0].Amenities.hotelamenity.forEach((data: any) => {
                    item.basicinfo.Amenities.push(data.Value);
                    if (data.Value === 'Restaurant')
                        item.isRestaurant = true
                    if (data.Value === 'Free WiFi')
                        item.isWifi = true
                    if (data.Value.includes('fitness'))
                        item.isGym = true
                    if (data.Value.includes('meeting'))
                        item.isMeetingRoom = true
                    if (data.Value.includes('pool'))
                        item.isPool = true
                });
            });
            payload.allHotelRating = this.removeDuplicates(r.allHotelRating).sort();
            const notDupilcate = this.removeDuplicates(r.allHotelPoints);
            const maxPoint = Math.max(...notDupilcate);
            const minPoint = Math.min(...notDupilcate);
            payload.minPoint = minPoint;
            payload.maxPoint = maxPoint;
            // console.log(payload);
            return payload;
        } else return [];
    },
}