import axios from 'axios';
import store from '@/store/index';
import CREDS from "@/api/creds";

export default {

    getProgramDefinition() {
        return axios.get('api/callback/v1/lbms/program/current-program', {
            params: {
                'dmo_id': CREDS.VUE_APP_dmoId,
                'pstrProgramName': CREDS.VUE_APP_ProgramName
            }
        })
            .then(response => {
                // console.log("🚚 LBMSService says program definition is: " + JSON.stringify (response));
                if (response && response.status === 200) return response.data
                else return null
            })
            .then<any, any>((progdef: any) => {
                if (!progdef) return // console.log(response, "program definition");
                if (progdef.program_id) {
                    // this.programLogo = progdef.program.ProgramLogo;
                    store.commit("mutProgramDefinition", progdef);
                }
                return this.GetSystemParameter(progdef)
                    .then((response: any) => {
                        if (!response) return // console.log(response, "program definition");
                        // console.log(response, "system Paramater");
                        if (response.results.IsSuccessful)
                            store.commit("mutSystemParameter", response.results.ReturnObject)
                    })
            })
            .catch(err => console.log(err))
    },

    GetSystemParameter(progdef: any): any {
        try {
            // console.log("🚚 progdef  : " + JSON.stringify (progdef));

            return axios.get('api/callback/v1/lbms/program/GetSystemParameter', {
                params: {
                    pintProgramId: progdef.program_id,
                }
            }).then((response) => {
                if (!response) return
                // console.log(response);
                if (response.status === 200) {
                    return response.data;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetProgramCurrencyDefinitionList(progId: any): any {
        try {
            // console.log("🚚 progdef  : " + JSON.stringify (progdef));

            return axios.get('api/callback/v1/lbms/program/GetProgramCurrencyDefinitionList', {
                params: {
                    pintProgramId: progId
                }
            }).then((response) => {
                if (!response) return
                // console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    CheckMembershipCredentials(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/CheckMembershipCredentials', postData).then((response) => {
                if (!response) return
                // console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetEmailIdByUserName(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetEmailIdByUserName', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GenerateOTP(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GenerateOTP', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    VerifyOTP(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/VerifyOTP', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    UnblockMember(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/UnblockMember', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetTotalMemberAccrualTransaction(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetTotalMemberAccrualTransaction', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetMemberAccrualTransaction(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetMemberAccrualTransaction', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetTotalMemberAccrualTransactionByDate(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetTotalMemberAccrualTransactionByDate', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetMemberAccrualTransactionByDate(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetMemberAccrualTransactionByDate', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    ChangePassword(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/ChangePassword', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetExpirySchedule(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetExpirySchedule', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetNextExpiredPointsOnDate(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetNextExpiredPointsOnDate', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetNextYearExpirySchedule(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetNextYearExpirySchedule', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetTotalMemberRedemptionTransaction(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetTotalMemberRedemptionTransaction', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetMemberRedemptionTransaction(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetMemberRedemptionTransaction', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetTotalMemberRedemptionTransactionByDate(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetTotalMemberRedemptionTransactionByDate', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetMemberRedemptionTransactionByDate(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetMemberRedemptionTransactionByDate', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetTotalMemberTransaction(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetTotalMemberTransaction', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetMemberTransactionSummary(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetMemberTransactionSummary', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetTotalMemberTransactionByDate(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetTotalMemberTransactionByDate', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetMemberTransactionSummaryByDate(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetMemberTransactionSummaryByDate', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    GetMemberDetailsForLBMS(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/GetMemberDetailsForLBMS', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    UpdateProfile(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/UpdateProfile', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    CheckAvailability() {
        try {
            return axios.post('/api/callback/v1/lbms/redemption/CheckAvailability', {
                RelationReference: store.getters.relationReference,
                TransactionCurrency: store.getters.transactionCurrency || '',
                RelationType: store.getters.relationType,
                ProgramId: store.getters.programDefinition.program_id,
            }).then((response) => {
                if (!response) return
                // console.log(response);
                if (response.status === 200) {
                    return response.data;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    ForgotUserName(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/ForgotUserName', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    ForgotPassword(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/ForgotPassword', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    MemberActivation(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/MemberActivation', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    CheckUserNameAvailable(postData: any) {
        try {
            return axios.post('/api/callback/v1/lbms/user/CheckUserNameAvailable', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    RedeemPoints(postData: {}) {
        try {
            return axios.post('api/callback/v1/lbms/redemption/RedeemPoints', postData).then((response) => {
                if (!response) return
                console.log(response);
                if (response.status === 200) {
                    return response.data;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    },

    /**
     * 
     * @param postData {
        RelationReference: "123456789",
        MerchantName: "DXB-MUM-DXB - Reversal",
        ExternalReference :"201769250220" // ReturnObject value from /RedeemPoints api
        }
     * @returns 
     */
    ReversalPoints(MerchantName: string, externalReference: string) {
        const postData = {
            RelationReference: store.getters.memberDetails.MemberRelationsList[0].RelationReference,
            MerchantName,
            ExternalReference: externalReference
        };
        try {
            return axios.post('api/callback/v1/lbms/redemption/ReversalPoints', postData).then((response) => {
                if (!response) return
                if (response.status === 200) {
                    return response.data;
                } else {
                    console.log(response);
                }
            }, (err) => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    }
}
