<template>
  <div class="container">
    <a-spin v-if="loading" size=large style="margin-top: 200px" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" />
    <div v-else class="row">
      <div class="col-md-9">
        <div v-if="buyable" class="row main" style="margin: 30px 0 0">
          <div class="col-md-3 onlywidth">
            <div>
              <img
                :src="
                  buyable.imgSrc ||
                  (buyable.PrimaryImage ? buyable.PrimaryImage.Url : '')
                "
                class="card-img-top"
              />
            </div>
          </div>
          <div class="col-md-7">
            <div class="title">{{ buyable.name || buyable.Name }}</div>
            <div class="vhtml">
              <div> {{ $t('details.Brand') }}: {{ buyable.Properties.get("Brand") }}</div>
              <div> {{ $t('details.Category') }}: {{ buyable.Properties.get("Category") }}</div>
            </div>
            <div class="Ratings" v-if="buyable.Ratings > 0">
              <span>{{ $t('addcomm.Ratings') }}: </span
              ><a-rate v-model="buyable.Ratings" allow-half disabled />
            </div>
          </div>
        </div>
        <div class="tc">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1">
              <span slot="tab">
                <!-- was a-icon --><a type="align-center" />
               {{ $t('details.Brand') }} 
              </span>
              <div>
                <div v-html="buyable.Properties.get('LongDescription')"></div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" force-render>
              <span slot="tab">
                <!-- was a-icon --><a type="edit" />
                   {{ $t('details.Shipping') }}  
              </span>
              <div>
                    {{ $t('details.Mini delivery days') }} :
                {{ buyable.Properties.get("MinDeliveryDays") }}
              </div>
              <div>
                  {{ $t('details.Max delivery days') }} :
                {{ buyable.Properties.get("MaxDeliveryDays") }}
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" v-if="buyable.EnableReview">
              <span slot="tab">
                <!-- was a-icon --><a type="message" />
                {{ $t('details.Reviews') }} 
              </span>
             {{ $t('details.Content of Tab Pane 3') }}  
            </a-tab-pane>
          </a-tabs>
        </div>
        <iframe
          v-if="iFrameUrl"
          :src="iFrameUrl"
          width="100%"
          height="100%"
        ></iframe>
      </div>

      <div class="col-md-3" style="padding-top: 30px">
        <div style="background-color: #eeeeee; padding: 34px 28px 24px">
          <div >
            <!-- <span
              style="text-decoration: line-through; color: #999"
              v-if="
                buyable.Price.ListPriceWithTax.FormattedAmount !==
                buyable.Price.SalePriceWithTax.FormattedAmount
              "
              >{{ buyable.Price.ListPriceWithTax.FormattedAmount }}<br
            /></span> -->
            <span
              ><b>{{ buyable.Price || 0}}</b></span
            >
          </div>
          <div>
            <div class="input-group mb-3 addlose" style="margin-top: 20px">
              <div class="lose cursor" @click="decreaseQty">
                <img src="@/assets/img/paymentmethod/lose.png" alt="" />
              </div>
              <input
                type="text"
                class="form-control input-back"
                v-model="Qty"
                style="text-align: center; background: #ffffff; height:30px"
                readonly
                disabled="true"
              />
              <div class="lose cursor" @click="increaseQty">
                <img src="@/assets/img/paymentmethod/add.png" alt="" />
              </div>
            </div>

            <!-- <div class="input-group mb-3" style="width: 200px">
            <span class="input-group-text cursor" @click="decreaseQty">-</span>
            <input
              type="text"
              class="form-control input-back"
              v-model="Qty"
              style="text-align: center"
              disabled="true"
            />
            <span class="input-group-text cursor" @click="increaseQty">+</span>
          </div> -->
            <!-- <span v-if="buyable.MaxQuantity === Qty"
                >(Max Quantity: {{ buyable.MaxQuantity }})</span
              > -->
          </div>
          <div>
            <!-- was a-icon -->
            <a-button
              type="primary"
              style="width: 100%; margin-top: 10px"
              size="large"
              @click="clickAddtoCart"
              ><a type="shopping-cart" /> {{$t('details.Add to cart') }}</a-button
            >
            <a-button
              v-if="false"
              type="primary"
              size="large"
              style="width: 100%; margin-top: 10px"
              @click="redeemNow"
              > {{$t('details.Buy Now') }}</a-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Shop from "@/views/modules/shop/api/shop";
// import RecentlyViewed from "@/components/recentlyViewed";
import UserService from "@/api/user";
import { i18n } from '@/i18n';

export default {
  computed: {
    buyable: function () {
      const a = this.$store.getters.activeBuyable;
      return (
        a || {
          Properties: new Map(),
          units: [{ unit: "", price: 0 }],
          currentImg: "",
        }
      );
    },
    iFrameUrl: function () {
      const url = this.buyable.Properties.get("RedirectionUrl");
      return url;
    },
  },
  // components: { RecentlyViewed },
  data() {
    return {
      Qty: 1,
      loading: false,
      userCart: {},
      localCart: [],
    };
  },
  mounted() {},
  activated() {
    window.scroll(0, 0);
    console.log(this.buyable);
    console.log(this.$store.getters.detailId);
    if (!this.buyable) return;
    UserService.addRecentlyViewed(this.buyable);

    this.loading = true
    Shop.GetProductById(this.$store.getters.detailId || "").then((result) => {
      this.buyable.Price = result.Price.SalePriceWithTax.FormattedAmount;
      this.buyable.MaxQuantity = result.MaxQuantity;
      this.Qty = 1;
      this.loading = false;
    });

    let id = this.buyable.Id;

    this.userCart =  {}
    if (this.$store.getters.IsUserLoggedIn) {
      if (!this.$store.getters.shopUserCart)
        Shop.searchUserCart().then (response => {
          this.userCart = response || {}
          // this.cartItems = response.Items || [];
          })
      else this.userCart = this.$store.getters.shopUserCart

      //console.log(this.$store.getters.shopUserCart);
      if (this.userCart && this.userCart.Items) {
        const p = this.userCart.Items.filter((item) => item.ProductId === id);
        if (p) this.Qty = p.Quantity;
        }
      } else {
      this.localCart = this.$store.getters.localShopUserCart;
      if (this.localCart && this.localCart.Items) {
        const p = this.localCart.Items.filter((item) => item.ProductId === id);
        if (p) this.Qty = p.Quantity;
      }
    }
  },
  methods: {
    redirectIt() {
      // TMP: graph ql should replace or complement VC call in the first place.
      const url = this.buyable.Properties.get("RedirectionUrl");
      if (url) window.open(url);
      },
    decreaseQty() {
      if (this.Qty > 1) this.Qty--;
      },
    increaseQty() {
      if (this.Qty < this.buyable.MaxQuantity || this.buyable.MaxQuantity === 0)
        this.Qty++;
      },
    redeemNow() {
      if (!this.$store.getters.memberDetails) {
        this.$store.dispatch("navigate", { c2: "login" });
        return;
      }
      this.redirectIt();
      },
    clickAddtoCart() {
      // cart after login
      if (this.$store.getters.IsUserLoggedIn)
        this.addItemToCart(this.userCart.Id);
      else {
        let allProducts = this.$store.getters.localShopUserCart;
        let isProductAvailable = false;
        let id = this.buyable.Id;
        allProducts.forEach((item) => {
          if (item.Id === id) {
            item.Quantity = this.Qty;
            isProductAvailable = true;
          }
        });
        if (!isProductAvailable) {
          this.buyable.Quantity = this.Qty;
          allProducts.push(this.buyable);
        }
        this.$store.commit("mutLocalShopUserCart", allProducts);
        console.log(
          "cart before login----add item to cached cart in local Storage"
        );
        // cart without login
        }
      },
    addItemToCart(cartId) {
      const storeDetails = this.$store.getters.storeDetails;
      console.log(this.$store.getters.detailId);
      const postdata = {
        CatalogId: storeDetails.Catalog,
        CartId: cartId,
        Items: [
          {
            ProductId: this.$store.getters.detailId,
            Quantity: this.Qty,
          },
        ],
      };
      console.log(postdata);
      console.log(this.Qty);
      Shop.addUpdateCartItems(postdata).then((response) => {
        if (response)
          this.$notification["success"]({
            message: i18n.t("addcomm.Success"),
            description: i18n.t("addcomm.Cart Updated successfully"),
          });
      });
    },
  },
};
</script>

<style scoped>
.prod-img {
  padding: 15px;
}

.prod-desc {
  /* text-align: left; */
}

.input-back {
  background-color: white !important;
}
.main {
  /* text-align: left; */
  /* background-color: #f9f9f9; */
  margin-top: 30px;
  padding: 30px 12px;
  background-color: #ffffff;
}

.onlywidth {
  width: 285px;
}
.card-img-top {
  width: 260px;
  border: solid 1px #eeeeee;
}
.title {
  font-size: 20px;
  color: #1f1f1f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vhtml {
  font-size: 14px;
  color: #8e8d8d;
  margin: 2px 0 10px;
}
.Ratings {
  align-items: center;
  font-size: 14px;
  display: flex;
}
.Ratings span {
  margin-right: 10px;
}

.tc {
  margin-top: 10px;
  background-color: #fff;
  /* text-align: left; */
  padding: 20px 30px 30px;
  box-sizing: border-box;
}
.tc .title {
  font-size: 18px;
  color: #333;
  /* text-align: left; */
  position: relative;
  text-align: center;
  top: 5px;
}
</style>
