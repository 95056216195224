<template>
  <div class="main">
    <div class="row login">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <form class="login-form">
          <div class="mb-3">
            <label for="username" class="form-label">{{
              $t("addcomm.User Name")
            }}</label>
            <input
              type="text"
              class="form-control"
              id="username"
              v-model="username"
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">{{
              $t("common.Password")
            }}</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="password"
            />
          </div>
            <div v-if="getErrors">
            <a-alert
              v-for="(err, index) in errorAlert"
              :key="err"
              :message="err"
              type="error"
              closable
              class="mt-3"
              @close="handleErrorClose(index)"
              show-icon
            />
            </div><br/>
          <div>
            <div class="row" v-if="showUnblockUser">
              <label for="fpassword" class="form-label"><a @click="$store.dispatch('navigate', { layer: 'user', c2: 'unblockMember' })">
                  Unblock Member</a>
              </label>
            </div>
            <!-- <div class="row">
              <label for="fpassword" class="form-label">
                <a @click="$store.dispatch('navigate', {layer: 'user',  c2: 'activate' })">{{ $t("common.Activate an account") }}?</a>
              </label>
            </div> -->
            <div class="row">
              <label for="fpassword" class="form-label">
                <a @click="$store.dispatch('navigate', {layer: 'user', c2: 'forgotPassword' })">{{ $t("common.Forgot Password") }}?</a>
              </label>
            </div>
            <div class="row">
              <label for="fpassword" class="form-label">
                <a @click="$store.dispatch('navigate', {layer: 'user', c2: 'forgotUsername' })">{{ $t("common.Forgot User Name") }}?</a>
              </label>
            </div>
          </div>
          <br />
          <a-button
            type="primary"
            block
            @click.prevent="submitBid"
            v-if="!apiProcessing"
          >
            {{ $t("common.Login") }}
          </a-button>
          <a-button type="primary" block v-if="apiProcessing">
            {{ $t("addcomm.Please Wait") }} ...
          </a-button>
          <br/> <br/> <br/> <br/>
        </form>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>
import Shop from "@/views/modules/shop/api/shop";
import md5 from "js-md5";
import router from "@/router/index";
import LBMSService from "@/api/lbmsService";
import { i18n } from "@/i18n";
import Helper from "./Helper"
export default {
  components: {},
  data() {
    return {
      username: this.$store.getters.userName,
      password: "",
      rememberMe: false,
      showUnblockUser: false,
      apiProcessing: false,
      errorAlert: []
    };
  },
  computed: {
    programDefinition: function () {
      return this.$store.getters.programDefinition;
    },
    getErrors() {
      return this.errorAlert.length >= 1;
    },
  },

  methods: {
    submitBid() {
      this.errorAlert = [];
      this.errorAlert = Helper.loginFormValidation(
        this.username,
        this.password
      );
      if (this.errorAlert.length === 0) {
        this.apiProcessing = true;
        // this.programDefinition = this.$route.meta.Login;
        const encryptedPass = md5(this.username + this.password);
        LBMSService.CheckMembershipCredentials({
          ProgramId: this.programDefinition.program_id,
          RelationType: this.$store.getters.relationType,
          UserName: this.username,
          Password: encryptedPass.replace("\n", "").replace("\r", ""),
        }).then(
          (response) => {
            // console.log(response);
            if (response.data.results.IsSuccessful) {
              const mb = response.data.results.ReturnObject;
              this.$store.commit("mutMemberDetails", mb);
              this.$store.commit("mutIsUserLoggedIn", true);
              Shop.searchUserCart().then(cart =>
                this.$store.commit("mutShopUserCart", cart)
              );
              this.getMemberPointBalance();
            } else {
              this.apiProcessing = false;
              this.$notification["error"]({
                message: i18n.t("addcomm.Login Error"),
                description: response.data.results.ErrorDescription,
              });
              if (response.data.results.ErrorDescription === "Account lock.") {
                this.showUnblockUser = true;
              }
            }
          },
          (error) => {
            this.apiProcessing = false;
            console.log(error);
          }
        );
      }
    },
    /* updateCartWithLocalCart() {
      //Shop.searchUserCart().then((response) => {
        console.log(response);
        const localCart = this.$store.getters.localShopUserCart

        const userCart = this.$store.getters.shopUserCart;
        const storeDetails = this.$store.getters.storeDetails;

        let newItems = [];
        for (let i = 0; i < localCart.length; i++) {
          newItems.push({
            ProductId: localCart[i].Id,
            Quantity: localCart[i].Quantity
          });
        }
        const postdata = {
          CatalogId: storeDetails.Catalog,
          CartId: userCart.Id,
          Items: newItems,
        };
        Shop.addUpdateCartItems(postdata).then((response) => {
          console.log(response);
        });
      });
    },*/
    getMemberPointBalance() {
      LBMSService.GetProgramCurrencyDefinitionList(
        this.programDefinition.program_id
      ).then(
        (response) => {
          // console.log(response);
          if (response.data.results.IsSuccessful) {
            const defaultData = response.data.results.ReturnObject.filter(
              (d) => d.IsDefault === true
            )[0];
            this.$store.commit("setTransactionCurrency", defaultData.Currency);

            LBMSService.CheckAvailability().then((response) => {
              if (response.results.IsSuccessful) {
                this.apiProcessing = false;
                this.$store.commit("mutPoints", response.results.ReturnObject);
                router.back();
                // this.$store.dispatch("navigate", { c2: "home" });
              } else {
                this.apiProcessing = false;
                this.$notification["error"]({
                  message: i18n.t("addcomm.Login Error"),
                  description: response.results.ErrorDescription,
                });
              }
            });
          } else {
            this.apiProcessing = false;
            this.$notification["error"]({
              message: i18n.t("addcomm.Login Error"),
              description: response.data.results.ErrorDescription,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    onChange(e) {
      this.rememberMe = e.target.checked;
    },
    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
}

.login-form {
  margin-top: 20%;
  /* text-align: left; */
}

.btn {
  width: 100%;
}
</style>
