<template>
  <div class="container" style="margin-top: 15px">
    <hotel-search-panel :show-errors="true" v-on:callback="redirection" />
  </div>
</template>

<script>
import HotelSearchPanel from "@/views/modules/hotel/components/HotelSearchPanel";

export default {
  components: {
    "hotel-search-panel": HotelSearchPanel,
  },
  methods: {
    redirection(e) {
      //console.log(e);
      if (e.status) {
        // console.log(this.$store.getters["hotel/hotelSearchResults"]);
        this.$store.commit ('mutisUserProcessing', false)
        this.$store.dispatch("navigate", { c2: "hotel-results" });
      }
    },
  },
};
</script>