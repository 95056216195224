<template>
  <div>
    <div class="row" style="margin-top: 3%">
      <div class="col-md-2"></div>
      <div class="col-md-2">
        <a-card title="Personal Information" style="width: 100%">
          <div style="text-align: left" v-if="hotelBookingRequest !== ''">
            <h6>
              {{ hotelBookingRequest.Customer.title }}
              {{ hotelBookingRequest.Customer.firstname }}
              {{ hotelBookingRequest.Customer.lastname }}
            </h6>
            <h6>
              Address: {{ hotelBookingRequest.Customer.city }},
              {{ hotelBookingRequest.Customer.country }}
            </h6>
            <h6>Postal Code: {{ hotelBookingRequest.Customer.postalcode }}</h6>
            <h6>Email: {{ hotelBookingRequest.Customer.email }}</h6>
            <h6>Mobile: {{ hotelBookingRequest.Customer.mobile }}</h6>
          </div>
        </a-card>
      </div>
      <div class="col-md-6" v-if="hotelRepriceResponse !== ''">
        <a-card title="Hotel Details" style="width: 100%">
          <div style="text-align: left">
            <div class="row">
              <div class="col-md-2">
                <img
                  :src="hotelRepriceResponse.basicinfo.thumbnailimage"
                  height="130px"
                  width="150px"
                />
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-8">
                <h6>{{ hotelRepriceResponse.basicinfo.hotelname }}</h6>
                <p>
                  {{ hotelRepriceResponse.basicinfo.address }},
                  {{ hotelRepriceResponse.basicinfo.city }},
                  {{ hotelRepriceResponse.basicinfo.country }}
                </p>
                <p>
                  Fax:
                  {{ hotelRepriceResponse.basicinfo.communicationinfo.fax }}
                </p>
                <p>
                  Phone:
                  {{ hotelRepriceResponse.basicinfo.communicationinfo.phone }}
                </p>
                <p>
                  Website:
                  {{ hotelRepriceResponse.basicinfo.communicationinfo.website }}
                </p>
              </div>
            </div>
            <div class="row" style="text-align: left">
              <h5><u>Booking Details</u></h5>
              <h6>
                Check-In Date:
                {{ hotelSearchRequest.checkInDate.format("DD-MM-YYYY") }}
              </h6>
              <h6>
                Check-Out Date:
                {{ hotelSearchRequest.checkOutDate.format("DD-MM-YYYY") }}
              </h6>
              <h6>No. Of Rooms: {{ hotelSearchRequest.noOfRooms }}</h6>
              <h6>Adult Per Room: {{ hotelSearchRequest.adultPerRoom }}</h6>
              <h6 v-if="hotelSearchRequest.childrenPerRoom > 0">
                Children Per Room: {{ hotelSearchRequest.childrenPerRoom }}
              </h6>
            </div>
          </div>
        </a-card>
        <br />
        <div>
          <a-button block type="primary" @click="bookNow()">
            {{ apiProcessing === true ? "Please Wait...." : "Book Now" }}
          </a-button>
        </div>
        <br />
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>
<script>
import LBMSService from "@/api/lbmsService";
import IBEHotel from "@/api/ibe/hotel";
import { i18n } from "@/i18n";

export default {
  data() {
    return {
      hotelBookingRequest: "",
      hotelRepriceResponse: "",
      hotelSearchRequest: "",
      apiProcessing: false,
      externalReference: "",
      isTransactSuccess: false,
    };
  },
  activated() {
    this.hotelSearchRequest = this.$store.getters["hotel/hotelSearchRequest"];
    this.hotelBookingRequest = this.$store.getters["hotel/hotelBookingRequest"];
    this.hotelRepriceResponse =
      this.$store.getters["hotel/hotelRepriceResponse"];
    // console.log(this.hotelSearchRequest);
    // console.log(this.hotelBookingRequest);
    // console.log(this.hotelRepriceResponse);
  },
  methods: {
    bookNow() {
      this.apiProcessing = true;
      const redeempostdata = {
        RelationReference:
          this.$store.getters.memberDetails.MemberRelationsList[0]
            .RelationReference || "",
        ProgramId: this.$store.getters.programDefinition.program_id,
        RelationType: this.$store.getters.relationType,
        TransactionCurrency: this.$store.getters.transactionCurrency || "",
        Amounts:
          this.hotelRepriceResponse.roomrates.RoomRate[0].TotalBaseAmount,
        Points: this.hotelRepriceResponse.roomrates.RoomRate[0].ActualPoints,
        MerchantName:
          "Hotel - " + this.hotelRepriceResponse.basicinfo.hotelname +
          "-" +
          this.hotelRepriceResponse.basicinfo.city +
          "-" +
          this.hotelRepriceResponse.basicinfo.country,
        LoyaltyTxnType: 6,
      };
      // console.log(redeempostdata);
      LBMSService.RedeemPoints(redeempostdata).then((result) => {
        // console.log(result);
        if (result.results.IsSuccessful) {
          this.externalReference = result.results.ReturnObject;
          this.callHotelBookingAPI(this.hotelBookingRequest);
        } else {
          this.apiProcessing = false;
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description:
              `${i18n.t("addcomm.Failed")}: ` + result.results.ErrorDescription,
          });
        }
      });
    },
    callHotelBookingAPI(postdata) {
      this.isTransactSuccess = false;
      IBEHotel.GetHotelBookingResponse(postdata)
        .then((response) => {
          console.log(response);
          if (
            response.BookingResponse.bookingid &&
            response.BookingResponse.confirmationnumber
          ) {
            this.isTransactSuccess = true;
            this.$store.commit("hotel/setHotelBookingResponse", response);
            this.$store.dispatch("navigate", { c2: "hotel-booking-success" });
            this.checkUserPoints();
          } else {
            this.isTransactSuccess = false;
          }
        })
        .finally(() => {
          this.apiProcessing = false;
          if (this.isTransactSuccess === false) {
            this.$notification["error"]({
              message: i18n.t("addcomm.Error"),
              description: i18n.t("addcomm.Booking Failed"),
            });
            // roll back pointsÎ
            const MerchantName =
              "Hotel - " +
              this.hotelRepriceResponse.basicinfo.hotelname +
              "-" +
              this.hotelRepriceResponse.basicinfo.city +
              "-" +
              this.hotelRepriceResponse.basicinfo.country +
              "- Reversal";
            LBMSService.ReversalPoints(
              MerchantName,
              this.externalReference
            ).then((response) => {
              if (response && response.results.IsSuccessful) {
                console.log(response);
                this.checkUserPoints();
              }
            });
          }
        });
    },
    checkUserPoints() {
      LBMSService.CheckAvailability().then((response) => {
        if (response.results.IsSuccessful) {
          this.$store.commit("mutPoints", response.results.ReturnObject);
        }
      });
    },
  },
};
</script>
<style scoped>
</style>