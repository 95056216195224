
import VueFormGenerator from "vue-form-generator";
import {i18n} from "@/i18n";

export default {

    getDynamicForm(buyable: any) {
        const model: any = {};
        const fields = [];
        const objectArray: [string, any][any]= Object.entries(
            buyable.metas.form.schema[
                Object.keys(buyable.metas.form.schema)[0]
            ].properties
        );
        console.log(objectArray);
        for (let i = 0; i < objectArray.length; i++) {
            model[objectArray[i][0]] = "";
            fields.push({
                type: "input",
                inputType: "text",
                label: objectArray[i][1].title,
                model: objectArray[i][0],
                placeholder: "Enter " + objectArray[i][1].title,
                required: true,
                validator: () => {
                    if (objectArray[i][1].regex) {
                        if (
                            !objectArray[i][1].regex.match(model[objectArray[i][0]])
                        ) {
                            return ["Regex Mismatch: " + objectArray[i][1].regex];
                        } else {
                            return [];
                        }
                    } else {
                        return [];
                    }
                },
            });
            if (i === objectArray.length - 1) {
                return {
                    model,
                    fields
                }
            }
        }
    },

    getDefaultForm() {
        const model = {
            email: '',
            givenName: '',
            familyName: '',
            number: ''
        }
        const fields: [any] = [{}];
        fields.push(
            {
                type: "input",
                inputType: "text",
                label: i18n.t('addcomm.EmailAddress'),
                model: "email",
                placeholder: i18n.t('addcomm.EnterEmailAddress'),
                required: true,
                validator: VueFormGenerator.validators.email,
            },
            {
                type: "input",
                inputType: "text",
                label: i18n.t('common.First Name'),
                model: "givenName",
                placeholder: i18n.t('addcomm.EnterFirstName'),
                required: true,
                validator: () => {
                    const ValidationExpression = /^[a-z]*$/;
                    if (!ValidationExpression.test(model.givenName)) {
                        return [ "First Name requires only Characters (without space)" ]
                    } else {
                        return [];
                    }
                },
            },
            {
                type: "input",
                inputType: "text",
                label: i18n.t('common.Last Name'),
                model: "familyName",
                placeholder: i18n.t('addcomm.EnterLastName'),
                required: true,
                validator: () => {
                    const ValidationExpression = /^[a-z]*$/;
                    if (!ValidationExpression.test(model.familyName)) {
                        return [ "Last Name requires only Characters (without space)" ]
                    } else {
                        return [];
                    }
                },
            },
            {
                type: "input",
                inputType: "text",
                label: i18n.t('common.Account Number'),
                model: "number",
                placeholder: i18n.t('addcomm.EnterAccountNumber'),
                required: true,
                validator: () => {
                    const alphanumeric = /^[ A-Za-z0-9]*$/;
                    if (!alphanumeric.test(model.number)) {
                        return [ "Only Numbers and Characters" ]
                    } else {
                        return [];
                    }
                },
            }
        );
        return {
            model,
            fields
        }
    }
}
