<template>
  <div class="col-md-6" style="text-align: left">
    <div class="title">{{ $t('mine.Personal Details') }}</div>
    <vue-form-generator
        :schema="schema"
        :model="model"
        :options="formOptions"
    ></vue-form-generator>
    <div class="btmbtn">
      <a-button type="primary" block style="margin-bottom: 30px">
        {{ $t('common.Cancel') }}
      </a-button>
      <a-button type="primary" block @click.prevent="submit" style="margin-bottom: 30px">
        {{ $t('common.Submit') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import LBMSService from "@/api/lbmsService";
import { i18n } from "@/i18n";
export default {
  data() {
    return {
      userForm: {},
      isUserFormValid: false,
      model: {},
      schema: {
        fields: [],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  computed: {
    programDefinition: function () {
      return this.$store.getters.programDefinition;
    },
  },
  activated() {
    this.$store.commit ('mutisUserProcessing', false)
    },
  mounted() {
    LBMSService.GetMemberDetailsForLBMS({
      ProgramId: this.programDefinition.program_id,
      RelationReference: this.$store.getters.relationReference,
      RelationType: this.$store.getters.relationType,
    }).then(
      (response) => {
        // console.log(response);
        if (response.data.results.IsSuccessful) {
          this.userForm = response.data.results.ReturnObject;
          this.model["FirstName"] = this.userForm.FirstName;
          this.model["LastName"] = this.userForm.LastName;
          this.model["Address1"] = this.userForm.Address1;
          this.model["MobileNumber"] = this.userForm.MobileNumber;
          this.model["Nationality"] = this.userForm.Nationality;
          this.model["PassportNumber"] = this.userForm.PassportNumber;
          this.model["Gender"] = this.userForm.Gender;
          this.model["Email"] = this.userForm.Email;

          this.schema.fields.push(
            {
              type: "input",
              inputType: "text",
              label: i18n.t('common.First Name'),
              model: "FirstName",
              placeholder: i18n.t("mine.placeholder User First name"),
              required: true,
              validator: VueFormGenerator.validators.string,
            },
            {
              type: "input",
              inputType: "text",
              label: i18n.t('common.Last Name'),
              model: "LastName",
              placeholder: i18n.t("mine.placeholder User Last name"),
              required: true,
              validator: VueFormGenerator.validators.string,
            },
            {
              type: "input",
              inputType: "text",
              label: i18n.t("addcomm.Email"),
              model: "Email",
              placeholder: i18n.t("mine.placeholder User Email"),
              required: true,
              validator: VueFormGenerator.validators.string,
            },
            {
              type: "input",
              inputType: "text",
              label: i18n.t("address"),
              model: "Address1",
              placeholder: i18n.t("mine.placeholder User Address"),
              required: this.userForm.Address1.mandatory,
              validator: VueFormGenerator.validators.string,
            },
            {
              type: "input",
              inputType: "text",
              label: i18n.t("mine.Mobile Number"),
              model: "MobileNumber",
              placeholder: i18n.t("mine.placeholder User Mobile Number"),
              required: this.userForm.MobileNumber.mandatory,
              validator: VueFormGenerator.validators.string,
            },
            {
              type: "input",
              inputType: "text",
              label: i18n.t("mine.Nationality"),
              model: "Nationality",
              placeholder: i18n.t("mine.placeholder User Nationality"),
              required: this.userForm.Nationality.mandatory,
              validator: VueFormGenerator.validators.string,
            },
            {
              type: "input",
              inputType: "text",
              label: i18n.t("mine.Passport Number"),
              model: "PassportNumber",
              placeholder: i18n.t("mine.placeholder User Passport Number"),
              required: this.userForm.PassportNumber.mandatory,
              validator: VueFormGenerator.validators.string,
            },
            {
              type: "select",
              inputType: "dropdown",
              label: i18n.t("mine.Gender"),
              model: "Gender",
              placeholder: i18n.t("mine.placeholder User Gender"),
              values: ["M", "F"],
              // required: this.userForm.Gender.mandatory,
              validator: VueFormGenerator.validators.string,
            }
          );
        } else {
          // not a problem (PS aug '21)
          //this.$notification["error"]({
          //  message: i18n.t("addcomm.Error"),
          //  description: response.data.results.ErrorDescription,
          //});
        }
      },
      (error) => {
        console.log(error);
      }
    );
  },
  methods: {
    submit() {
      this.userForm.FirstName = this.model["FirstName"];
      this.userForm.LastName = this.model["LastName"];
      this.userForm.Address1 = this.model["Address1"];
      this.userForm.MobileNumber = this.model["MobileNumber"];
      this.userForm.Nationality = this.model["Nationality"];
      this.userForm.PassportNumber = this.model["PassportNumber"];
      this.userForm.Gender = this.model["Gender"];
      this.userForm.Email = this.model["Email"];

      LBMSService.UpdateProfile(this.userForm).then(
        (response) => {
          if (response) {
            this.$notification["success"]({
              message: i18n.t('addcomm.Success'),
              description:  i18n.t('addcomm.Profile Updated successfully')
            });
          } else {
            this.$notification["error"]({
              message: i18n.t("addcomm.Error"),
              description: response.data.results.ErrorDescription,
            });
          }
        },
        (error) => {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: error.message,
          });
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  font-size: 18px;
  color: #595959;
  margin-bottom: 40px;
}

.onlythis {
  width: 100%;
  background-color: #ffffff;
  margin: 20px auto;
  padding-top: 40px;
}

.onlythis .col-md-6 {
  /*border: 1px solid red;*/
  margin: auto;
}

.onlythis /deep/ .form-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.onlythis /deep/ label {
  width: 110px;
  margin-right: 50px;
  font-size: 14px;
  color: #595959;
  text-align: right;
}

/deep/ .field-wrap {
  width: 350px;
}

.btmbtn {
  display: flex;
  margin: 20px auto;
  width: 200px;
  justify-content: space-between;
}

.btmbtn button {
  width: 80px;
  height: 30px;
  background-color: #e5e5e5;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  color: #ffffff;
}

.btmbtn button:last-child {
  background-color: #0095eb;
}

/deep/ .required label {
  position: relative;
  left: 11px;
}

/deep/ .errors {
  position: relative;
  left: 160px;
}

/deep/ .form-group.error input:not([type=checkbox]),
.form-group.error select,
.form-group.error textarea {
  background: none;
}

</style>
