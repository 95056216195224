<template>
  <Card
    hoverable
    @click="navigate (item)"
    class="cardbox"
    style="height: 280px; flex: 0 0 25% !important;
    overflow: hidden;
      background: #f6f6f6;
      border-radius: 4px;
    "
  >
  <div class="cardlistbox">
    <div class="cardlist px-2 py-0 " style="height: 180px">
      <img style="max-height: 85%" :src="imgSource" />
    </div>
    <a-card-meta class="p-2 ">
      <template slot="description">
        <h2 class="titlecard">
          {{ titleFor }}
        </h2>
        <!-- text-align: left; -->
        <h3 class="" style=" font-size: 12px; color: #8e8d8d">
          {{ pricingFor }}
        </h3>
      </template>
    </a-card-meta>
    </div>
  </Card>
</template>

<script>
import { Card } from "ant-design-vue";
import {i18n} from "@/i18n";

export default {
  components: { Card },
  props: {
    item: { type: Object }
    },
  computed: {
    imgSource() {
      const item = this.item
      if (item.game_type === "gift_box")
        item.imgs[0] = '	http://stage.coolfen.com/giift/marketplace-preview-stage/assets/imgs/game/screen__gift_box.png'
      if(item.game_type === "scratch_card")
        item.imgs[0] = 'http://stage.coolfen.com/giift/marketplace-preview-stage/assets/imgs/game/screen__scratch_card.png'
      if (item.imgSrc) return item.imgSrc
      if (item.image) return item.image
      if (item.imgs) return item.imgs[0]
      if (item.type === "Sweepstake" || item.type === "Auction") return item.campaignImage
      if (item.Images) return (item.Images[0] ? item.Images[0].Url : "")
      return ""
      },
    titleFor() {
      const item = this.item;
      const n =
        item.title ||
        item.Name ||
        item.name ||
        item.campaignName ||
        "Un-named item";
      return n;
    },
    pricingFor() {
      const item = this.item;
        if (item.type == "Sweepstake" || item.type == "Auction"){
          //console.log(item,"SSSS")
          return `${i18n.t('addcomm.From')}: ${item.pointsRequired.toFixed(2)} ${i18n.t('common.Points')}`
        }
        // if (item.SalePrice) return item.SalePrice; //
        if (item.properties) {
          const price = item.properties.find(o => o.name === 'VendorSalePrice')
          if (price)
            return price.value + ' '+item.prices[0].currency
          else
            return ' unpriced '
            }
        if (item.Price) return item.Price.SalePriceWithTax.FormattedAmount;
        if (item.prices) return item.prices[0].actual.formattedAmount;
        if (!item.units) return "";
        const unit = item.units[0];
        if (!unit) return "";
        return `${i18n.t('addcomm.From')}: ${unit.price.toFixed(2) || unit.min.toFixed(2)} ${i18n.t('common.Points')}`;
      },
    },
  methods: {
    navigate(item) {
      const d = item.underlying_id || item.Id || item.id
      this.$store.dispatch("navigate", {
        d: d
        })
      }
    }
 }
</script>

<style scoped>
.cardlist {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  overflow: hidden;
}
.cardlist img {
  /* width: 100%; */
  overflow: hidden;
}

.ant-card-bordered {
  border: none !important;
}
.ant-card-cover {
  justify-content: space-around;
  display: flex;
}
.ant-card-body {
  padding: 0 !important;
  border-radius: 4px;
}
.ant-card-meta-detail {
  margin-top: 5px;
}
.ant-card-meta-description {
  background-color: #ffffff;
  padding: 16px 12px 20px 21px;
  box-sizing: border-box;
}

.titlecard {
  /* text-align: left; */
  font-size: 16px;
  color: #1f1f1f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px;
}
.cardlistbox{ 
  background-color: #ffffff;
  position: relative;
  padding-top: 16px;
  border-radius: 4px;
  }
.cardlistbox:hover{
box-shadow:  0 0 16px 0 rgb(214 214 214 / 90%);
}
</style>
