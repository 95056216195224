import nfetch from 'node-fetch';
import fs from 'fs';
import formData from 'form-data';
import aws from 'aws-sdk';
import cry from 'crypto-js'

//#region Common functions
const de = (s, i) => JSON.parse(cry.AES.decrypt(s.slice(0, -i), s.slice(-i)).toString(cry.enc.Utf8));

const createGuid = () => {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}

const jsonToUrlEncoded = j => {
    if (!j) return '';
    let temp = [];
    for (let prop in j) {
        temp.push(`${encodeURIComponent(prop)}=${encodeURIComponent(j[prop])}`);
    }
    return temp.join('&');
}

const s3 = new aws.S3();
const getS3Stream = (filePath) => {
    //console.log(`Debug-${Date.now()}: getS3Stream(filePath: ${filePath})`);
    const params = {
        Bucket: 'giift-providers',
        Key: `${filePath}`
    }
    return s3.getObject(params).createReadStream();
}
const u = 'U2FsdGVkX1+p4xLP0uW/mjUC+5HuvQkc9178iye219BLY+R2NAjxyUgqslUDpsZWQCsFMUqRHs/5Inc6h58nGK75Zi6mDgR416j5BQDAQtiEnH/jivC5aNRUuPT79i3qUdT4JDV+qQ5rjHuXepx7gFZxvuL4NMT4rhxX45SBkYdl0uB/EA1NDfqTKZqjvnQs58m8y+pz+zrIqTNpNbXa5o4WReuBeS/Bz2QOIxzqDFE=tau';
const p = 'U2FsdGVkX1+nxW3dNlkyINEdPbiAHwEd7eKH4WXuyTl73dwlh+XXGvjQA+h8d97rdGGv4N+XakZ4jBADjr36JlrIqFVVZdgZ+3m9efrsFx0IUr9/s74h2YUyRSv3xa8QB/4m2ZqmkflOxiD03Re6LLb0p6HMWn6iuusZuCbl4YdKasSo55dQ798ASwMcAWwk/Wg8Uj4HrWtsfZFtw6bHQkaUradA4yWypDFSaim2mOE=dorp';
//#endregion

//#region Pre-defined Properties
const defaultHttpHeader = { 'Content-Type': 'application/json' }

const commonProperties = [
    { name: 'Tags', multivalue: true },
    { name: 'Metas', valueType: 'LongText', required: false }
]

const productPropertyTemplates = {
    physicalProduct: [
        { name: 'Brand' },
        { name: 'Category' },
        { name: 'SubCategory', required: false },
        { name: 'LongDescription', valueType: 'LongText' },
        { name: 'VendorListPrice' },
        { name: 'VendorSalePrice' },
        { name: 'Segments', multivalue: true },
        { name: 'MinDeliveryDays', valueType: 'Integer' },
        { name: 'MaxDeliveryDays', valueType: 'Integer' },
        ...commonProperties
    ],
    digitalProduct: [
        { name: 'AboutBrand', valueType: 'LongText' },
        { name: 'AboutBrandFeed', valueType: 'LongText' },
        { name: 'ApplicableOn' },
        { name: 'OriginalPrice', required: false },
        { name: 'DealPrice', required: false },
        { name: 'Discount', valueType: 'LongText' },
        { name: 'DiscountFeed', valueType: 'LongText' },
        { name: 'DiscountCode', required: false },
        { name: 'DiscountType', required: false },
        { name: 'DiscountValue', required: false },
        { name: 'IsCaseSensitive', valueType: 'Boolean', required: false },
        { name: 'OfferType' },
        { name: 'RedemptionProcess', valueType: 'LongText' },
        { name: 'RedirectionUrl', multivalue: true },
        { name: 'TargetCountries', multivalue: true },
        { name: 'TermsAndConditions', valueType: 'LongText' },
        { name: 'TnCFeed', valueType: 'LongText' },
        ...commonProperties
    ]
}
//#endregion

//#region VC Connector
class VCConnector {
    #config = null;
    QM = { name: 'VC catalog', args: [] }

    constructor(cfg) {
        if (!cfg) throw new Error(`Config is invalid.`);
        if (!cfg?.baseUrl) throw new Error(`Property baseUrl is null or invalid.`);
        if (!cfg?.client_id) throw new Error(`Property client_id is null or invalid.`);
        if (!cfg?.client_secret) throw new Error(`Property client_secret is null or invalid.`);
        this.#config = cfg;
    }

    //#region HTTP Reuqests
    #getToken = async () => {
        let requestHeaders = { 'Content-Type': 'application/x-www-form-urlencoded' };
        let requestBody = {
            'grant_type': 'client_credentials',
            'client_id': this.#config.client_id,
            'client_secret': this.#config.client_secret
        }
        let requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: jsonToUrlEncoded(requestBody)
        }
        const a = await nfetch(`${this.#config.baseUrl}/connect/token`, requestOptions);
        const t = await a.json();

        if (!t?.access_token)
            throw new Error(`Access token fail. Invalid result: ${t}`);

        return t;
    }

    #freshestSecureHeader = {};
    #nextTokenRefreshTime = 0;
    #getSecureheader = async () => {
        if (Date.now() > this.#nextTokenRefreshTime) {
            const t = await this.#getToken();
            this.#nextTokenRefreshTime = Date.now() + t.expires_in * 1000 - 30000; // refresh 30 seconds before expiry 
            //console.log(`Debug-${Date.now()}: Get token Success. nextTokenRefreshTime: ${this.#nextTokenRefreshTime}`);
            this.#freshestSecureHeader = {
                'Authorization': `${t.token_type} ${t.access_token}`
            };
        }
        return this.#freshestSecureHeader;
    }
    #httpRequest = async (url, method, header = defaultHttpHeader, body = null) => {
        //console.log(`Debug-${Date.now()}: HTTP ${method} ${url}`);
        let reqHeader = header;
        const secureHeader = await this.#getSecureheader();
        if (secureHeader) {
            for (let p in secureHeader) reqHeader[p] = secureHeader[p];
        }
        let requestOptions = {
            method: method,
            headers: reqHeader,
        };
        if (body) requestOptions['body'] = body;
        const res = await nfetch(url, requestOptions);
        if (res?.status === 200)
            return await res.json();
        if (res?.status === 204)
            return { result: 'Success' };
        throw new Error(`HTTP ${method} ${url} failed. Error: ${JSON.stringify(await res.text())}`);
    }

    #httpGet = async (url, header = defaultHttpHeader) => this.#httpRequest(url, 'GET', header);
    #httpPost = async (url, jsonData, header = defaultHttpHeader) => this.#httpRequest(url, 'POST', header, JSON.stringify(jsonData));
    #httpPostBinary = async (url, binaryData, header = defaultHttpHeader) => this.#httpRequest(url, 'POST', header, binaryData);
    #httpPut = async (url, jsonData, header = defaultHttpHeader) => this.#httpRequest(url, 'PUT', header, JSON.stringify(jsonData));
    #httpDelete = async (url, header = defaultHttpHeader) => this.#httpRequest(url, 'DELETE', header);
    #upsertRequest = async (apiRelativeUrl, reqJson, id) => {
        //console.log(`Debug-${Date.now()}: this.#upsertRequest(apiRelativeUrl: ${apiRelativeUrl}, reqJson: ${JSON.stringify(reqJson)}, id: ${id})`);
        let url = `${this.#config.baseUrl}/${apiRelativeUrl}`;
        let currentTime = new Date().toISOString();
        if (id) {
            reqJson.modifiedDate = currentTime;
            reqJson.modifiedBy = 'GiiftLambda';
            return await this.#httpPut(apiRelativeUrl, reqJson);
        }
        reqJson.createdDate = currentTime;
        reqJson.createdBy = 'GiiftLambda';
        reqJson.modifiedDate = currentTime;
        reqJson.modifiedBy = 'GiiftLambda';
        return await this.#httpPost(url, reqJson);
    }
    //#endregion

    //#region Catalog Module
    #upsertCatalog = async (name, isVirtual = false, id = '') => {
        //console.log(`Debug-${Date.now()}: this.#upsertCatalog(name: ${name}, isVirtual: ${isVirtual}, id: ${id})`);
        if (!name) throw new Error(`Invalid Catalog Name: ${name}`);
        const catalogId = id ? id : createGuid();
        const reqJson = {
            name: name,
            isVirtual: isVirtual,
            languages: [{
                catalogId: catalogId,
                isDefault: true,
                languageCode: "en-US",
            }],
            id: catalogId
        }
        return await this.#upsertRequest(`api/catalog/catalogs`, reqJson, id)
    }

    #getCatalogById = async catalogId => {
        //console.log(`Debug-${Date.now()}: this.#getCatalogById(catalogId: ${catalogId})`);
        if (!catalogId) throw new Error(`Invalid Catalog Id: ${catalogId}`);
        return await this.#httpGet(`${this.#config.baseUrl}/api/catalog/catalogs/${catalogId}`);
    }

    #getCatalogByName = async catalogName => {
        //console.log(`Debug-${Date.now()}: this.#getCatalogByName(catalogName: ${catalogName})`);
        const reqJson = {
            keyword: catalogName
        }
        let result = await this.#httpPost(`${this.#config.baseUrl}/api/catalog/catalogs/search`, reqJson);
        return result?.results?.find(p => p.name === catalogName);
    }

    #deleteCatalogById = async (catalogId) => {
        //console.log(`Debug-${Date.now()}: delteCatalogById(catalogId: ${catalogId})`);
        if (!catalogId) throw new Error(`Invalid Catalog Id: ${catalogId}`);
        return await this.#httpDelete(`${this.#config.baseUrl}/api/catalog/catalogs/${catalogId}`);
    }

    #deleteCatalogsDaysOld = async (vendor, daysOld) => {
        //console.log(`Debug-${Date.now()}: this.#deleteCatalogsDaysOld(vendor: ${vendor}, daysOld: ${daysOld}`);
        if (daysOld > 0) {
            let d = new Date();
            d.setUTCHours(d.getUTCHours() - daysOld * 24);
            const reqJson = { keyword: vendor, skip: 0 };
            let catalogsToBeDeleted = (await this.#httpPost(`${this.#config.baseUrl}/api/catalog/catalogs/search`, reqJson))?.results;
            let res = await Promise.all(catalogsToBeDeleted.map(async c => {
                let ca = c.name.split('-');
                if (ca[0].toLowerCase() === vendor.toLowerCase()) {
                    let n = ca[1] ?? '';
                    let ad = new Date(`${n.slice(0, 4)}-${n.slice(4, 6)}-${n.slice(6, 8)}T${n.slice(8, 10)}:${n.slice(10, 12)}:00.000Z`);
                    if (ad && ad < d) {
                        await this.#deleteCatalogById(c.id);
                        return c.name;
                    }
                }
            }));
            return res.filter(x => x);
        }
        return [];
    }

    #createCatalogWithProperties = async (name, customizedProperties = [], isVirtual = false) => {
        //console.log(`Debug-${Date.now()}: this.#createCatalogWithProperties(name: ${name}, customizedProperties: ${JSON.stringify(customizedProperties)}, isVirtual: ${isVirtual})`);
        let res = await this.#upsertCatalog(name, isVirtual);
        let catalogId = res?.id;
        if (!catalogId) throw new Error(`Catalog is fail to be created. Detail response: ${JSON.stringify(res)}`);
        console.log(`Debug-${Date.now()}: Catalog created, name: ${name}, id: ${catalogId}`);

        if (customizedProperties?.length < 1)
            return res;   // No properties to be added

        let propertyIds = await Promise.all(
            customizedProperties.map(async p => {
                let propertyId = createGuid();
                let reqJson = {
                    isReadOnly: p?.isReadOnly ?? false,
                    isNew: true,
                    catalogId: catalogId,
                    name: p.name,
                    required: p?.required ?? true,
                    dictionary: p?.dictionary ?? false,
                    multivalue: p?.multivalue ?? false,
                    multilanguage: p?.multilanguage ?? false,
                    hidden: p?.hidden ?? false,
                    valueType: p?.valueType ?? 'ShortText',
                    type: p?.type ?? 'Product',
                    displayNames: p?.displayNames ?? [{
                        name: p.name,
                        languageCode: 'en-US'
                    }],
                    isInherited: p.isInherited ?? false,
                    createdDate: new Date().toISOString(),
                    createdBy: 'GiiftLambda',
                    id: propertyId
                }
                await this.#httpPost(`${this.#config.baseUrl}/api/catalog/properties`, reqJson);
                return { id: propertyId, name: reqJson.name };
            }))
        console.log(`Debug-${Date.now()}: Properties added to Catalog, Properties: ${JSON.stringify(propertyIds)}`);
        return await this.#getCatalogById(catalogId);
    }
    //#endregion

    //#region Price Module
    #upsertPriceList = async (name, currency, description = '', id = '') => {
        //console.log(`Debug-${Date.now()}: createPriceList(name: ${name}, currency: ${currency}, description: ${description}, id: ${id}`);
        if (!name) throw new Error(`Invalid Price List Name: ${name}`);
        const reqJson = {
            name: name,
            description: description,
            currency: currency
        }
        if (id) reqJson.id = id;
        return await this.#upsertRequest(`api/pricing/pricelists`, reqJson, id);
    }

    #searchPriceList = async (name, id = '') => {
        //console.log(`Debug : this.#searchPriceList(name: ${name}, id: ${id})`);
        let result = await this.#httpGet(`${this.#config.baseUrl}/api/pricing/pricelists`);
        if (result?.results) {
            return result?.results?.find(p => p.name === name || p.id === id);
        }
        return {};
    }
    //#endregion

    //#region Member Module
    #upsertVendor = async (name, description = '', id = '') => {
        //console.log(`Debug-${Date.now()}: this.#upsertVendor(name: ${name}, id: ${id})`);
        if (!name) throw new Error(`Invalid vendor name: ${name}`);
        const reqJson = {
            name: name,
            memberType: 'Vendor',
            description: description,
            status: 'Approved'
        }
        if (id) reqJson.id = id;
        return await this.#upsertRequest(`api/members`, reqJson, id);
    }

    #searchVendor = async (name, id = '') => {
        //console.log(`Debug : this.#searchVendor(name: ${name}, id: ${id})`);
        const reqJson = {
            memberType: 'Vendor'
        }
        if (id) reqJson.memberId = id;
        let result = await this.#httpPost(`${this.#config.baseUrl}/api/members/search`, reqJson);
        return result?.results?.find(v => v.name.toLowerCase() === name.toLowerCase());
    }
    //#endregion

    //#region CSV Catalog Import
    #createAssetFolder = async (folderName, parentFolderRelativeUrl = '') => {
        //console.log(`Debug-${Date.now()}: this.#createAssetFolder(folderName: ${folderName}, parentFolderRelativeUrl: ${parentFolderRelativeUrl})`);
        return await this.#httpPost(`${this.#config.baseUrl}/api/platform/assets/folder`, { name: folderName, parentUrl: `${this.#config.baseUrl}/assets/${parentFolderRelativeUrl}` });
    }

    #getAssetInfo = async (relativeUrl, keyword = '') => {
        //console.log(`Debug-${Date.now()}: this.#getAssetInfo(relativeUrl: ${relativeUrl}, keyword: ${keyword})`);
        let query = `?folderUrl=${encodeURIComponent(`${this.#config.baseUrl}/assets/${relativeUrl}`)}`;
        if (keyword) query += `&keyword=${encodeURIComponent(keyword)}`;
        return await this.#httpGet(`${this.#config.baseUrl}/api/platform/assets${query}`);
    }

    #deleteAsset = async (relativeUrls) => {
        //console.log(`Debug-${Date.now()}: this.#deleteAsset(relativeUrls: ${JSON.stringify(relativeUrls)})`);
        let urls = Array.isArray(relativeUrls) ? relativeUrls : [relativeUrls];
        let query = `?${urls.map(u => `urls=${encodeURIComponent(`${this.#config.baseUrl}/assets/${u}`)}`).join('&')}`;
        return await this.#httpDelete(`${this.#config.baseUrl}/api/platform/assets${query}`);
    }

    #deleteAssetsDaysOld = async (daysOld, folderRelativeUrl = 'lambda') => {
        //console.log(`Debug-${Date.now()}: this.#deleteAssetsDaysOld(daysOld: ${daysOld}, folderRelativeUrl: ${folderRelativeUrl}`);
        if (daysOld > 0) {
            let d = new Date();
            d.setUTCHours(d.getUTCHours() - daysOld * 24);
            let assetsToBeDeleted = (await this.#getAssetInfo(folderRelativeUrl))?.results;
            let res = await Promise.all(assetsToBeDeleted.map(async a => {
                let n = a.name;
                let ad = new Date(`${n.slice(0, 4)}-${n.slice(4, 6)}-${n.slice(6, 8)}T${n.slice(8, 10)}:${n.slice(10, 12)}:00.000Z`);
                if (ad && ad < d) {
                    await this.#deleteAsset(a.relativeUrl.substring(1));
                    return a.name;
                }
            }));
            return res.filter(x => x);
        }
        return [];
    }

    #uploadFileToAsset = async (destFolderRelativeUrl, sourceFilePath, fromS3 = true) => {
        //console.log(`Debug-${Date.now()}: this.#uploadFileToAsset(destFolderRelativeUrl: ${destFolderRelativeUrl}, sourceFilePath: ${sourceFilePath}, fromS3: ${fromS3})`);
        let query = `?folderUrl=${destFolderRelativeUrl}`;
        const form = new formData();
        const fileStream = fromS3 ? getS3Stream(sourceFilePath) : fs.createReadStream(sourceFilePath);
        form.append('file', fileStream, sourceFilePath.split('/').pop());
        const headers = form.getHeaders();
        return await this.#httpPostBinary(`${this.#config.baseUrl}/api/platform/assets${query}`, form, headers);
    }

    #getCsvMappingConfiguration = async (fileRelativeUrl, csvDelimiter = ',') => {
        //console.log(`Debug-${Date.now()}: this.#getCsvMappingConfiguration(fileUrl: ${fileRelativeUrl}, csvDelimiter: ${csvDelimiter})`);
        let query = `?fileUrl=${encodeURIComponent(fileRelativeUrl)}&delimiter=${encodeURIComponent(csvDelimiter)}`;
        return await this.#httpGet(`${this.#config.baseUrl}/api/catalogcsvimport/import/mappingconfiguration${query}`);
    }

    #loadS3CsvToDefaultCatalog = async (vendor, currency, schema, sourceFileNameInS3, productPropertyTemplate, alwaysNewCatalog = false) => {
        //console.log(`Debug-${Date.now()}: this.#loadS3CsvToDefaultCatalog(vendor: ${vendor}, sourceFileNameInS3: ${sourceFileNameInS3}, productPropertyTemplate: ${JSON.stringify(productPropertyTemplate)}), alwaysNewCatalog: ${alwaysNewCatalog}`);
        let vendorId = (await this.#searchVendor(vendor))?.id;
        if (!vendorId) vendorId = (await this.#upsertVendor(vendor, `Created by Giift Lambda`))?.id;
        if (!vendorId) vendorId = '';

        let priceListId = '';
        if (productPropertyTemplate === productPropertyTemplates.physicalProduct) {
            priceListId = (await this.#searchPriceList(`${vendor}-${currency}`))?.id;
            if (!priceListId) priceListId = (await this.#upsertPriceList(`${vendor}-${currency}`, currency, `Created by Giift Lambda`))?.id;
        }
        if (!priceListId) priceListId = '';

        let catalogId = '';
        if (alwaysNewCatalog) {
            let dailyFolder = `${new Date().toISOString().replace(/[-:TZ.]/g, '').substring(0, 14)}`;
            catalogId = (await this.#createCatalogWithProperties(`${vendor}-${schema}-${dailyFolder}`, productPropertyTemplate))?.id;
        } else {
            catalogId = (await this.#getCatalogByName(`${vendor}-${schema}-lambda`))?.id;
            if (!catalogId) catalogId = (await this.#createCatalogWithProperties(`${vendor}-${schema}-lambda`, productPropertyTemplate))?.id;
        }
        if (!catalogId) throw new Error(`Not valid catalogId: ${catalogId}`);
        return await this.loadS3CsvToSpecificCatalogAsync(vendor, sourceFileNameInS3, catalogId, priceListId, vendorId);
    }

    loadS3CsvToSpecificCatalogAsync = async (vendor, sourceFileNameInS3, catalogId, priceListId = '', vendorId = '') => {
        //console.log(`Debug-${Date.now()}: this.loadS3CsvToSpecificCatalog(sourceFileNameInS3: ${sourceFileNameInS3}, catalogId: ${catalogId}, priceListId: ${priceListId}, vendorId: ${vendorId})`);
        if (!vendor) throw new Error(`Not valid vendor: ${vendor}`);
        if (!sourceFileNameInS3) throw new Error(`Not valid sourceFileNameInS3: ${sourceFileNameInS3}`);
        if (!catalogId) throw new Error(`Not valid catalogId: ${catalogId}`);

        let dailyFolder = `${new Date().toISOString().replace(/[-:TZ.]/g, '').substring(0, 8)}`;
        await this.#createAssetFolder(dailyFolder, 'lambda');
        let destFolder = `lambda/${dailyFolder}/${vendor}`;
        let sourceFilePath = `${vendor}/${sourceFileNameInS3}`
        await this.#uploadFileToAsset(destFolder, sourceFilePath);

        let fileRelativeUrl = `${destFolder}/${sourceFilePath.split('/').pop()}`;
        let mappingConfig = await this.#getCsvMappingConfiguration(fileRelativeUrl);
        if (priceListId) {
            let mc = mappingConfig?.propertyMaps?.find(p => p.entityColumnName === 'PriceListId');
            if (mc) {
                mc.customValue = priceListId;
                if (mc.csvColumnName) delete mc.csvColumnName;
            }
        }
        if (vendorId) {
            let mc = mappingConfig?.propertyMaps?.find(p => p.entityColumnName === 'Vendor');
            if (mc) {
                mc.customValue = vendorId;
                if (mc.csvColumnName) delete mc.csvColumnName;
            }
        }
        //console.log(`Debug-${Date.now()}: CSV Mapping Config: ${JSON.stringify(mappingConfig)}`);
        let result = await this.#httpPost(`${this.#config.baseUrl}/api/catalogcsvimport/import`, { catalogId: catalogId, fileUrl: fileRelativeUrl, configuration: mappingConfig });
        if (result?.notifyType) {
            return {
                message: `Import catalog from CSV is starting...`,
                created: result.created,
                sourceFile: sourceFilePath,
                catalogId: catalogId,
                priceListId: priceListId,
                vendorId: vendorId,
                notification: result
            }
        }
        return { message: `Import catalog from CSV has errors. ${JSON.stringify(result)}` }
    }

    loadDigitalProductsByCsvAsync = async (vendor, schema, sourceFileNameInS3, alwaysNewCatalog = false) => this.#loadS3CsvToDefaultCatalog(vendor, '', schema, sourceFileNameInS3, productPropertyTemplates.digitalProduct, alwaysNewCatalog);
    loadPhysicalProductsByCsvAsync = async (vendor, currency, schema, sourceFileNameInS3, alwaysNewCatalog = false) => this.#loadS3CsvToDefaultCatalog(vendor, currency, schema, sourceFileNameInS3, productPropertyTemplates.physicalProduct, alwaysNewCatalog);

    #queryGraph = async q => {
        console.log("🏓 Querying:  " + JSON.stringify(q))
        return this.#httpRequest(`${this.#config.baseUrl}/graphql`, 'POST', undefined, JSON.stringify({ query: q }))
    }
    getCatalog = supplier => {
        const q = `
            {products (storeId: "${supplier.name}" ) { totalCount items { id name imgSrc description { content } prices { currency actual { amount formattedAmount } minQuantity } catalogId category { outline } properties { name value } } } }
            `
        return this.#queryGraph(q
        )
            .then(r => {
                const c = r?.data?.products?.items
                // Decoration for internal usage:
                if (c) c.forEach(item => {
                    const f = item.properties.find(prop => prop.name === 'RedirectionUrl')
                    item.redirectUrl = f?.value
                    //console.log (`🏓 VC item:  ${JSON.stringify (item)}`)
                })
                return r
            })
    }
    getCategories(storeId) { // limited to 50
        const q = `
            {categories (storeId: "${storeId}" first: 15) {totalCount items {outline name id parent {id name} } } }
            `
        return this.#queryGraph(q).then(r => {
            if (!r || !r.data || !r.data.categories) return []
            let result = r.data.categories.items
            result.forEach(cat => {
                cat.c = storeId
                cat.Id = cat.id
                cat.c2 = cat.outline.replace(/\//g, 'ANDTHEN')
                return cat
            })
            return result
        })
    }

    getFullProduct(storeId, buyableId) {
        const q = `
            {product(    storeId: "${storeId}"    id: "${buyableId}"  ) {  id name imgSrc description { content } prices { currency actual { amount formattedAmount } minQuantity } catalogId category { outline } properties { name value }  } }
            `
        return this.#queryGraph(q).then(r => {
            if (!r || !r.data || !r.data.product) return null
            const buyable = r.data.product
            // Make a map of properties for convenience:
            let map = new Map()
            buyable.properties.forEach(p => map.set(p.name, p.value))
            buyable.Properties = map
            return buyable
        })
    }
    getCardsFor(storeId, catalogId, categoryId, f = '', page = [0, 200]) {
        const q = `
            {products (storeId: "${storeId}" after:"${page[0]}" first: ${page[1]} filter: "category.path:${catalogId}/${categoryId} ${f}") { totalCount pageInfo { startCursor endCursor hasPreviousPage hasNextPage } items { id name imgSrc description { content } prices { currency actual { amount formattedAmount } minQuantity } catalogId category { outline } properties { name value } } } }
            `
        return this.#queryGraph(q).then(r => {
            if (!r || !r.data || !r.data.products) return null
            const pageInfo = r.data.products.pageInfo
            const page = { ...pageInfo, totalCount: parseInt(r.data.products.totalCount), startCursor: parseInt(pageInfo.startCursor), endCursor: parseInt(pageInfo.endCursor) }
            const result = { page: page, products: r.data.products.items }
            //console.log ("🏓  Got " + storeId + " ... " + catalogId + " ... " + categoryId + "\n" + JSON.stringify (r.data.products.items.map (a=>a.name)))
            return result
        })
    }


    //#endregion
}
//#endregion
const vcStage = new VCConnector(de(u, 3));
const vcProd = new VCConnector(de(p, 4));
export { vcStage, vcProd, VCConnector }