<template>
  <div>
    <a-card
      :title="$t('common.By Countries')"
      :bordered="false"
      style="width: 100%; height: 100%"
    >
      <a-row>
        <a-col :span="24">
          <a-select
            showSearch
            allowClear
            style="width: 100%"
            @change="$emit('searchCountries', $event)"
          >
            <a-select-option
              v-for="country in $store.getters.allCountries"
              :key="country.countryName"
            >
              {{ country.countryName }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row><br/>
    </a-card>
  </div>
</template>

<script>
import GiiftCommonCountry from "@/api/giift/common/country";

export default {
  data() {
    return {
      isProcessing: true,
    };
  },
  mounted() {
    const allCountries = GiiftCommonCountry.GetAll();
    if (allCountries && allCountries.length !== 0) this.isProcessing = false;
    else this.callCountryApi();
  },
  methods: {
    callCountryApi() {
      this.$axios.get("/api/common/v1/country/all").then(
        (response) => {
          // console.log(response);
          if (!response) return;
          this.$store.commit("mutAllCountries", response.data);
          this.isProcessing = false;
        },
        (err) => console.log(err.message)
      );
    },
  },
};
</script>

<style scoped>
.ant-card >>> .ant-card-body {
  padding-bottom: 14px !important;
}

.ant-card >>> .ant-card-head-title {
  text-align: center;
}
</style>
