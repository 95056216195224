<template>
  <a-spin :spinning="spinning" size="large">
    <a-card style="text-align: left" :bordered="border">
      <div slot="title" v-if="title">{{ $t("addcomm.Hotel Booking") }}</div>
      <div class="row">
        <div class="col-md-3">
          <label>{{ $t("details.Country") }}</label
          ><br />
          <v-select
            :value="form.SearchCountry"
            @input="updateSearchCountry"
            :placeholder="$t('addcomm.Select Country')"
            width="100%"
            :loading="form.SearchCountries.length === 0"
            :options="form.SearchCountries"
          >
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              >
                {{ $t("addcomm.vs__spinner class hide") }}
              </div>
            </template>
          </v-select>
        </div>
        <div class="col-md-3">
          <label>{{ $t("addcomm.City") }}</label
          ><br />
          <v-select
            v-model="form.City"
            :options="form.Cities"
            @search="onSearch"
            :disabled="form.SearchCountry === '' && form.Cities.length === 0"
            :placeholder="$t('addcomm.Select City')"
            width="100%"
          >
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              >
                {{ $t("addcomm.vs__spinner class hide") }}
                <!-- The .vs__spinner class will hide the text for me. -->
              </div>
            </template>
          </v-select>
        </div>

        <!--div class="col-md-3">
          <label>City</label><br />
          <v-select
            v-model="city"
            placeholder="Select City"
            width="100%"
            :options="Cities"
            @search="onSearch"
          >
          </v-select>
        </div-->

        <div class="col-md-3">
          <label>{{ $t("addcomm.Check-In") }}</label
          ><br />
          <a-date-picker
            v-model="form.checkInDate"
            size="large"
            style="width: 100%"
            :disabled-date="disabledDate"
            :format="dateFormat"
            :placeholder="$t('addcomm.Select Check-In Date')"
            @change="getCheckInDateValue()"
          />
          <!-- @openChange="handleStartOpenChange" -->
        </div>

        <div class="col-md-3">
          <label>{{ $t("addcomm.Check-Out") }}</label
          ><br />
          <a-date-picker
            v-model="form.checkOutDate"
            size="large"
            style="width: 100%"
            :disabled-date="disabledEndDate"
            :format="dateFormat"
            :placeholder="$t('addcomm.Select Check-Out Date')"
            :open="endOpen"
            @openChange="handleEndOpenChange"
          />
        </div>

        <div class="col-md-3">
          <label>{{ $t("addcomm.Room") }} </label><br />
          <a-select
            v-model="form.noOfRooms"
            size="large"
            :default-value="form.noOfRooms"
            style="width: 100%"
            @change="onAddRooms"
          >
            <a-select-option v-for="n in parseInt(4)" :key="n" :value="n">
              {{ n }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-md-4"
          v-for="(room, index) in form.addRooms"
          :key="index"
        >
          <div class="row">
            <div class="col-md-12">
              <strong>{{ $t("addcomm.Room") }} {{ index + 1 }}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>{{ $t("addcomm.Adults 12+ Yrs") }} </label><br />
              <a-select
                v-model="room.adult"
                size="large"
                :default-value="form.adultPerRoom"
                style="width: 100%"
              >
                <a-select-option v-for="n in parseInt(4)" :key="n" :value="n">
                  {{ n }}
                </a-select-option>
              </a-select>
            </div>
            <div class="col-md-6">
              <label>{{ $t("addcomm.Children 2 to 11 Yrs") }}</label
              ><br />
              <a-select
                v-model="room.child"
                size="large"
                :default-value="form.childrenPerRoom"
                style="width: 100%"
              >
                <a-select-option :key="0" :value="0">
                  {{ 0 }}
                </a-select-option>
                <a-select-option v-for="n in parseInt(2)" :key="n" :value="n">
                  {{ n }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>

      <div v-if="getErrors">
        <a-alert
          v-for="(err, index) in errorAlert"
          :key="err"
          :message="err"
          type="error"
          closable
          class="mt-3"
          @close="handleErrorClose(index)"
          show-icon
        />
      </div>

      <div class="row mt-3">
        <div class="col-md-12" style="text-align: right">
          <a-button
            @click.prevent="submitSearch"
            type="primary"
            size="large"
            class="btn-block"
          >
            {{ $t("addcomm.Search") }}
          </a-button>
        </div>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { i18n } from "@/i18n";
import moment from "moment";
import IBEHotel from "@/api/ibe/hotel";
import debounce from "lodash/debounce";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Helper from "../Helper";
export default {
  components: {
    vSelect,
  },

  props: {
    showErrors: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: i18n.t("addcomm.Hotel Booking"),
    },
  },

  model: {
    props: ["showErrors", "border", "title"],
    event: "callback",
  },

  data() {
    return {
      form: {
        checkInDate: moment().add(3, "days"),
        checkOutDate: "",
        noOfRooms: 1,
        addRooms: [
          {
            adult: 1,
            child: 0,
          },
        ],
        adultPerRoom: 1,
        childrenPerRoom: 0,
        SearchCountry: "",
        Cities: [],
        City: "",
        SearchCountries: [],
        Locations: [],
      },
      membershipReference: "000000",
      dateFormat: "DD/MM/YYYY",
      endOpen: false,
      value: undefined,
      spinning: false,
      errorAlert: [],
      // SearchCountries: [],
      // Locations: [],
    };
  },
  
  created() {
    this.spinning = false;
    this.reset()
  },

  methods: {
      async reset() {
    if (this.$store.getters["hotel/hotelSearchRequest"].SearchCountry) {
      this.form = this.$store.getters["hotel/hotelSearchRequest"];
    } else {
      this.form.Locations = await IBEHotel.GetAllHotelCities();
      this.form.SearchCountries = [
        ...new Set(this.form.Locations.map((item) => item.Country))
      ];
    }
  },

    getCheckInDateValue() {
      this.form.checkOutDate = moment(this.form.checkInDate).add(1, "days");
    },
    disabledDate(current) {
      // Can not select days before today
      return current && current < moment().endOf("day");
    },

    disabledEndDate(checkOutDate) {
      const checkInDate = this.form.checkInDate;
      if (!checkOutDate || !checkInDate) {
        return false;
      }
      return checkInDate.valueOf() >= checkOutDate.valueOf();
    },

    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },

    handleEndOpenChange(open) {
      this.endOpen = open;
    },

    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        return this.search(loading, search, this);
      }
    },

    onAddRooms(n) {
      if (n > this.form.addRooms.length) {
        for (let i = this.form.addRooms.length; i < n; i++) {
          this.form.addRooms.push({
            adult: 1,
            child: 0,
          });
        }
      } else if (n < this.form.addRooms.length) {
        for (let i = n; i < this.form.addRooms.length; i - 1) {
          this.form.addRooms.splice(-1, 1);
        }
      }
    },

    search: debounce((loading, search, vm) => {
      loading(false)
      return vm.form.Cities.filter((c) => c.indexOf(search) >= 0);
    }, 350),

    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
      },

    async submitSearch() {
      this.errorAlert = [];
      this.errorAlert = Helper.searchFormValidation(this);
      //
      if (this.errorAlert.length === 0) {
        this.spinning = true
        this.$store.commit ('mutisUserProcessing', true)
        try {
          // Room Occupancy Calculation
          const roomsAdults = [],
            roomsChildren = [];
          this.form.addRooms.filter((r) => {
            roomsChildren.push(r.child);
            roomsAdults.push(r.adult);
          });
          //
          const postdata = {
            AdultPerRoom: roomsAdults.join(","),
            CheckInDate: moment(this.form.checkInDate).format(
              moment.HTML5_FMT.DATE
            ),
            CheckOutDate: moment(this.form.checkOutDate).format(
              moment.HTML5_FMT.DATE
            ),
            ChildrenPerRoom: roomsChildren.join(","),
            CityName: this.form.City, //this.city.split(",")[2],
            Country: this.form.SearchCountry, //this.city.split(",")[1],
            CountryISOCode: this.form.Locations.filter(
              (l) =>
                l.CityName == this.form.City &&
                l.Country == this.form.SearchCountry
            )[0].CountryISOCode,
            IpAddress: "49.248.108.58",
            MembershipReference: "000000",
            NoOfRooms: this.form.noOfRooms,
            RedemptionRate: 1,
            TypeOfReturnResult: "",
          };
          if (this.errorAlert.length == 0)
            this.$store.commit("hotel/setHotelSearchRequest", this.form);
        
          // OK, time to make the request:
          // the result of dispatch is not a promise this.$store.dispatch("hotel/callHotelSearchRequest", postdata)
          this.callHotelSearchRequest (postdata)
            .then (() => {
              this.spinning = false
              this.$emit("callback", {
                errors: this.errorAlert,
                status: this.errorAlert.length === 0
                })
              },
              e => {
                console.log(e)
                this.errorAlert.push(e)
                // e.filter((d) => this.errorAlert.push(d));
                this.spinning = false
                }
            )
        } catch (error) {
          console.error(error);
        } finally {
          //this.spinning = false
        }
      }
    },

  callHotelSearchRequest( payload) {
    return IBEHotel.GetHotelSearchResponse(payload)
        .then (response => {
            let err = '' // do nothing with err for now
            if (response.Errors) {
                const e = [];
                response.Errors.filter((d) => e.push(d.ErrorMessage));
                err = 'Error'
                }
            else {
                if (!response.SearchResponse || response.SearchResponse.hotels.hotel === null || 
                    response.SearchResponse.hotels.hotel.length === 0) {
                    err = '' + i18n.t('addcomm.No Result Found')
                    this.$store.commit("hotel/setHotelSearchResults", [])
                    }
                else {
                    this.$store.commit("hotel/setHotelSearchId", response.SearchId);
                    this.$store.commit("hotel/setHotelSearchResults", response.SearchResponse.hotels.hotel || [])
                    }
                }
              if (err.length > 0) console.log ("Hotel search error: " + err)   
            })
      },

    updateSearchCountry(v) {
      this.form.SearchCountry = v;
      this.form.Cities = [];
      this.form.City = "";
      this.form.Cities = [...new Set (this.form.Locations
                                      .filter(d => d.Country == this.form.SearchCountry)
                                      .map((c) => c.CityName))]
      }
    },
  computed: {
    getErrors() {
      return this.errorAlert.length >= 1;
    },
  },
};
</script>