<template>
  <div>
    <div class="container" v-if="hotelInfoApiProcessing === false">
          <!-- text-align: left; -->
      <div
        class="row"
        style="
          margin-top: 3%;
          margin-left: 5%;
          margin-right: 5%;
        "
        v-if="hotelDetails !== ''"
      >
        <div class="col-md-8">
          <a-card title="Personal Details" v-if="form">
            <!-- /text-align: left;  -->
            <div class="row" style="padding-left: 12px">
              <div class="col-md-2">
                <label>Title</label><br />
                <a-select style="width: 100px" v-model="form.title">
                  <a-select-option value="Mr."> Mr. </a-select-option>
                  <a-select-option value="Mrs."> Mrs. </a-select-option>
                  <a-select-option value="Ms."> Ms. </a-select-option>
                </a-select>
              </div>
              <div class="col-md-5">
                <label>First Name</label><br />
                <a-input
                  v-model="form.firstname"
                  placeholder="Enter your First name"
                />
              </div>
              <div class="col-md-5">
                <label>Last Name</label><br />
                <a-input
                  v-model="form.lastname"
                  placeholder="Enter your Last name"
                />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6" style="text-align: left">
                <label>Email</label><br />
                <a-input v-model="form.Email" placeholder="Enter your Email" />
              </div>
              <div class="col-md-6">
                <label>Mobile No</label><br />
                <a-input
                  v-model="form.mobileNo"
                  placeholder="Enter your Mobile No"
                />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-4" style="text-align: left">
                <label>City</label><br />
                <a-input v-model="form.city" placeholder="Enter your City" />
              </div>
              <div class="col-md-4">
                <label>Postcode</label><br />
                <a-input
                  v-model="form.postcode"
                  placeholder="Enter your Postcode"
                />
              </div>
              <div class="col-md-4">
                <label>Country</label><br />
                <a-input
                  v-model="form.country"
                  placeholder="Enter your Country"
                />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <label>Special Request (Optional)</label><br />
                <a-textarea
                  v-model="form.srequest"
                  placeholder="Enter your Special Request"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
                />
              </div>
            </div>
            <br />
            <div class="row">
              <a-button type="primary" block @click="proceed()">
                {{
                  apiProcessing === true
                    ? "Please Wait...."
                    : "Proceed to Booking"
                }}
              </a-button>
            </div>
            <br />
            <div v-if="getErrors">
              <a-alert
                v-for="(err, index) in errorAlert"
                :key="err"
                :message="err"
                type="error"
                closable
                class="mt-3"
                @close="handleErrorClose(index)"
                show-icon
              />
            </div>
          </a-card>
        </div>
        <div class="col-md-4">
          <a-card title="Hotel Details">
            <!-- <a slot="extra" href="#">Edit</a> -->
            <div class="row">
              <h5>{{ hotelDetails.basicinfo.hotelname }}</h5>
              <p>
                {{ hotelDetails.basicinfo.address }},
                {{ hotelDetails.basicinfo.city }},
                {{ hotelDetails.basicinfo.country }}
              </p>
            </div>
            <div class="row">
              <img
                :src="hotelDetails.basicinfo.thumbnailimage"
                height="170px"
              />
              <br /><br />
            </div>
            <br />
            <div class="row">
              <p>
                <b
                  >Check-In:
                  {{ hotelSearchRequest.checkInDate.format("DD-MM-YYYY") }}</b
                >
              </p>
              <p>
                <b
                  >Check-out:
                  {{ hotelSearchRequest.checkOutDate.format("DD-MM-YYYY") }}</b
                >
              </p>
              <p>
                <b
                  >Room-Type:
                  {{ hotelDetails.roomrates.RoomRate[0].roomtype.roomdescription || "" }}</b
                >
              </p>
            </div>
            <div class="row" v-if="HotelRepriceResponse !== ''">
              <p>
                <b
                  >{{
                    HotelRepriceResponse.roomrates.RoomRate[0].ratebreakdown
                      .rate.length
                  }}
                  Night(s)</b
                >
              </p>
              <p>
                <b
                  >Points:
                  {{
                    HotelRepriceResponse.roomrates.RoomRate[0].ActualPoints
                  }}</b
                >
              </p>
            </div>
          </a-card>
        </div>
      </div>
    </div>
    <div
      v-if="hotelInfoApiProcessing === true"
      class="col-md-9"
      style="height: 100%; z-index: 10; margin-left: 12%; margin-top: 5%"
    >
      <a-spin
        size="large"
        class="ht-100"
        role="progressbar"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  </div>
</template>
<script>
import Helper from "@/views/modules/hotel/Helper";
import IBEHotel from "@/api/ibe/hotel";
import { i18n } from '@/i18n';
export default {
  data() {
    return {
      form: {
        title: "Mr.",
        firstname: "",
        lastname: "",
        country: "",
        city: "",
        postcode: "",
        Email: "",
        mobileNo: "",
        srequest: "",
      },
      errorAlert: [],
      hotelDetails: "",
      hotelSearchRequest: "",
      hotelRoomTypeCode: "",
      hotelSearchId: "",
      HotelRepriceResponse: "",
      apiProcessing: false,
      hotelInfoApiProcessing: true,
    };
  },
  computed: {
    getErrors() {
      return this.errorAlert.length >= 1 ? true : false;
    },
  },
  activated() {
    this.resetForm();
    this.hotelDetails = this.$store.getters["hotel/selectedHotel"] || "";
    if (this.hotelDetails !== "") {
      this.hotelSearchRequest =
        this.$store.getters["hotel/hotelSearchRequest"] || "";
      this.hotelRoomTypeCode =
        this.$store.getters["hotel/hotelRoomTypeCode"] || "";
      this.hotelSearchId = this.$store.getters["hotel/hotelSearchId"] || "";
      // map data for Reprice
      this.hotelDetails.roomrates.RoomRate.forEach((item) => {
        if (item.roomtype.roomtypecode === this.hotelRoomTypeCode) {
          this.hotelDetails.roomrates.RoomRate = [item];
        }
      });
      this.hotelDetails.SearchId = this.hotelSearchId;
      // console.log(this.hotelDetails);

      IBEHotel.HotelReprice({
        Hotel: this.hotelDetails,
        RedemptionRate: 1,
      }).then((response) => {
        // console.log(response);
        if (response && response.Hotel) {
          this.hotelInfoApiProcessing = false;
          this.HotelRepriceResponse = response.Hotel;
        } else {
          this.deleteHotel(
            this.hotelDetails.hotelid,
            this.$store.getters["hotel/hotelSearchResults"]
          );
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: `${i18n.t('addcomm.Failed')}: ${i18n.t('addcomm.Please try different Hotel')}`,
          });
          this.$store.dispatch("navigate", { c2: "hotel-results" });
        }
      });
    } else {
      this.$store.dispatch("navigate", { c2: "hotel" });
    }
  },
  methods: {
    resetForm() {
      this.form = {
        title: "Mr.",
        firstname: "",
        lastname: "",
        country: "",
        city: "",
        postcode: "",
        Email: "",
        mobileNo: "",
        srequest: "",
      };
      this.hotelDetails = "";
      this.errorAlert = [];
      this.hotelInfoApiProcessing = true;
    },
    deleteHotel(hotelId, payload) {
      if (payload.length > 0) {
        payload = payload.filter((data) => {
          if (data.hotelid !== hotelId) return data;
        });
        this.$store.commit("hotel/setHotelSearchResults", payload);
      }
    },
    proceed() {
      this.errorAlert = [];
      this.errorAlert = Helper.customerFormValidation(this);
      if (this.errorAlert.length == 0) {
        this.apiProcessing = true;
        // console.log(this.form);
        const memberDetails = this.$store.getters.memberDetails || "";
        const postdata = {
          AdultPerRoom: this.hotelSearchRequest.adultPerRoom,
          CheckInDate: this.hotelSearchRequest.checkInDate.format("YYYY-MM-DD"),
          CheckOutDate:
            this.hotelSearchRequest.checkOutDate.format("YYYY-MM-DD"),
          ChildrenPerRoom: this.hotelSearchRequest.childrenPerRoom,
          Customer: {
            city: this.form.city,
            country: this.form.country,
            email: this.form.Email,
            firstname: this.form.firstname,
            landline: "",
            lastname: this.form.lastname,
            middlename: "",
            mobile: this.form.mobileNo,
            postalcode: this.form.postcode,
            state: "",
            streetaddress1: "",
            streetaddress2: "",
            title: this.form.title,
          },
          Hotel: this.HotelRepriceResponse,
          SearchId: this.hotelSearchId,
          NoOfRooms: this.hotelSearchRequest.noOfRooms,
          IpAddress: "127.0.0.1",
          MembershipReference: memberDetails.MemberRelationsList[0].RelationReference || "",
          RedemptionRate: 1,
          //   ReferenceId : "Test12345"
        };
        // console.log(postdata);
        this.$store.commit(
          "hotel/setHotelRepriceResponse",
          this.HotelRepriceResponse
        );
        this.$store.commit("hotel/setHotelBookingRequest", postdata);
        this.$store.dispatch("navigate", { c2: "hotel-review" });
      }
    },
    handleErrorClose(i) {
      const e = this.errorAlert;
      delete e[i];
      this.errorAlert = e.flat();
    },
  },
};
</script>
<style scoped>
</style>
