import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/index'
import flight from '@/store/modules/flight'
import car from '@/store/modules/car'
import hotel from '@/store/modules/hotel'
import buyablesService from "@/api/buyablesService"

Vue.use(Vuex)


interface relationsList { RelationReference: string }
interface mrl { FirstName: string, LastName: string, MemberRelationsList: Array<relationsList> }

function cached(key: string, defaultValue: any) {
  const result = localStorage.getItem(key)
  if (!result) return defaultValue
  const expextedType = typeof defaultValue
  switch (expextedType) {
    case "string": return String.bind(null, result)();
    case "number": return Number.bind(null, result)();
    case "boolean": return result == "true" ? true : false;
    case "object":
      try { return JSON.parse(result) }
      catch (e) {
        console.error(`Could not parse value for ${key} from local storage: ${result}`)
        return defaultValue
      }
    default: return ''
  }
}
function cacheMutate(state: { [k: string]: any }, s: any, key: string) {
  state[key] = s
  if (typeof s === 'object')
    s = JSON.stringify(s)
  localStorage.setItem(key, s)
}

const proxyBuyable = { Name: 'proxy', name: 'proxy', underlying_id: 'xxx', Id: 'xxx', imgs: [''], Properties: new Map(), units: [{ unit: '', price: 0 }], currentImg: '' }


import CREDS from "@/api/creds"

export default new Vuex.Store({
  state: {
    filter: "",
    lang: cached ('lang', 'en'),
    branDing: false,
    pageCursor: 1,
    countPerBlock: 240, // multiple of 4, 5, 6, 8, 10, 12 for tiled pages like cardLister: How many buyables to fetch at a time
    block: {startCursor: 0, count: 0, totalCount: 0},
    invalidStoreDetails: false,
    iFrameUrl: cached ('iFrameUrl', ''),
    isProcessing: false,
    isUserProcessing: false,
    storeId: "",
    storeDetails: null,
    navContext: "mkt",
    underNavContext: "home",
    detailId: "",
    activeBuyables: [],
    filteredBuyables: [],
    lastBuyable: proxyBuyable,
    buyablesCache: new Map(),
    ASGToken: cached ('ASGToken', ''),
    i18nlang: cached ('lang', 'en'),
    points: cached ('points', 0),
    shop_token: cached ('shop_token', ''),
    lbms_token: cached ('lbms_token', ''),
    lbms_token_expiry_time: cached ('lbms_token_expiry_time', ''),
    shop_token_expiry_time: cached ('shop_token_expiry_time', ''),
    lbmsRefresh_token: cached ('lbmsRefresh_token', ''),
    currentCard: cached ('currentCard', ''),
    currentUser: cached ('currentUser', ''),
    IsUserLoggedIn: Boolean(cached ('IsUserLoggedIn', false)),
    allCountries: [],
    programDefinition: cached ("programDefinition", {}),
    systemParameter: cached ("systemParameter", {}),
    recentlyViewed: cached ("recentlyViewed", []),
    offerproducts: null,
    shopCategories: [],
    currentShopCategory: {c: '', c2: 'home', Id: ''},
    shopUserCart: {},
    memberDetails: cached ("memberDetails",
      { FirstName: 'anonymous', LastName: '', MemberRelationsList: [{ RelationReference: '' }] }),
    shopFilters: [],
    localShopUserCart: cached ("localShopUserCart", []),
    shopOrders: [],
    transactionCurrency: cached ("transactionCurrency", ''),
    CREDS: CREDS,
    i18n: {locale: "en", setLocaleMessage: (a:any,b:any)=>{}},
    buyableRedeemSuccess: '',
    passwordPageType: ''
  },
  getters: {
    filter: state => state.filter,
    lang: state => state.lang,
    branDing: state => state.branDing,
    pageCursor: state => state.pageCursor,
    block: state => state.block,
    startBlock: state => state.block.startCursor,
    countPerBlock: state => state.countPerBlock,
    invalidStoreDetails: state => state.invalidStoreDetails,
    relationType: state => state.CREDS.VUE_APP_RelationType,
    iFrameUrl: state => state.iFrameUrl,
    storeId: state => state.storeId,
    storeDetails: state => state.storeDetails,
    isProcessing: state => state.isProcessing,
    isUserProcessing: state => state.isUserProcessing,
    navContext: state => state.navContext,
    underNavContext: state => state.underNavContext,
    detailId: state => state.detailId,
    activeBuyable: state => state.lastBuyable,
    buyablesCache: state => state.buyablesCache,
    points: state => state.points,
    ASGToken: state => state.ASGToken,
    i18nlang: state => state.i18nlang,
    shop_token: state => state.shop_token,
    lbms_token: state => state.lbms_token,
    lbms_token_expiry_time: state => state.lbms_token_expiry_time,
    shop_token_expiry_time: state => state.shop_token_expiry_time,
    lbmsRefresh_token: state => state.lbmsRefresh_token,
    memberDetails: state => state.memberDetails,
    relationReference: state => {
      const mds: mrl = (state.memberDetails as mrl)
      const mbs = mds.MemberRelationsList
      if (mbs) {
        return mbs[0].RelationReference
      }
      return ''
    },
    programDefinition: state => state.programDefinition,
    systemParameter: state => state.systemParameter,
    IsUserLoggedIn: state => state.IsUserLoggedIn,
    activeBuyables: state => { return state.activeBuyables },
    filteredBuyables: state => state.filteredBuyables,
    allCountries: state => state.allCountries,
    recentlyViewed: state => state.recentlyViewed,
    offerproducts: state => state.offerproducts,
    shopCategories: state => state.shopCategories,
    currentShopCategory: state => state.currentShopCategory,
    shopUserCart: state => state.shopUserCart,
    shopFilters: state => state.shopFilters,
    localShopUserCart: state => state.localShopUserCart,
    shopOrders: state => state.shopOrders,
    transactionCurrency: state => state.transactionCurrency,
    buyableRedeemSuccess: state => state.buyableRedeemSuccess,
    passwordPageType: state => state.passwordPageType,
    userName: state => {
      if (state.currentUser) return state.currentUser.userName
      return ''
    }
  },
  mutations: {
    mutFilter (state, f) {state.filter = f},
    mutLang (state, s) { cacheMutate (state, s, "lang") },
    toggleBranDing: function (state) {
      state.branDing = !state.branDing
      },
    mutPageCursor: function (state, pageCursor) {
      state.pageCursor = pageCursor
      },
    mutBlock: function (state, b) {
      //console.log ("🍫 Mutating block: " + JSON.stringify (b))
      if (!b)
        state.block = {startCursor: 0, count: 0, totalCount: 0}
      else {
        state.block.startCursor = b.startCursor
        state.block.count = 1 + b.endCursor - b.startCursor
        state.block.totalCount = b.totalCount
        }
      },
    mutInvalidStoreDetails: function (state, s) {
      state.invalidStoreDetails = s
    },
    mutiFrameUrl: function (state, s) { cacheMutate(state, s, "iFrameUrl") },
    mutStoreDetails: function (state, s) {
      state.storeDetails = s
    },
    mutAllCountries: function (state, cs) { state.allCountries = cs },
    mutFilteredBuyables: function (state, bs) { state.filteredBuyables = bs },
    mutisProcessing: function (state, bool) { state.isProcessing = bool },
    mutisUserProcessing: function (state, bool) { state.isUserProcessing = bool },
    mutPoints: function (state, s) { cacheMutate(state, s, "points") },
    mutASGToken: function (state, s) { cacheMutate(state, s, "ASGToken") },
    muti18nlang: function (state, s) { cacheMutate(state, s, "i18nlang") },
    mutLbms_token: function (state, s) { cacheMutate(state, s, "lbms_token") },
    mutShop_token: function (state, s) { cacheMutate(state, s, "shop_token") },
    mutShop_token_expiry_time: function (state, s) { cacheMutate(state, s, "shop_token_expiry_time") },
    mutLbms_token_expiry_time: function (state, s) { cacheMutate(state, s, "lbms_token_expiry_time") },
    mutLbmsRefresh_token: function (state, s) { cacheMutate(state, s, "lbmsRefresh_token") },
    mutProgramDefinition: function (state, o) { cacheMutate(state, o, "programDefinition") },
    mutSystemParameter: function (state, o) { cacheMutate(state, o, "systemParameter") },
    mutCurrentCard: function (state, s) { cacheMutate(state, s, "currentCard") },
    mutCurrentUser: function (state, s) {
      cacheMutate(state, s, "currentUser")
      if (s)
        state.IsUserLoggedIn = true
      else {
        localStorage.removeItem("points");
        localStorage.removeItem("ASGToken");
        //that's ok to leave: localStorage.removeItem("i18nlang");
        localStorage.removeItem("shop_token");
        localStorage.removeItem("lbms_token");
        localStorage.removeItem("lbms_token_expiry_time");
        localStorage.removeItem("shop_token_expiry_time");
        localStorage.removeItem("lbmsRefresh_token");
        localStorage.removeItem("memberDetails");
        localStorage.removeItem("currentCard");
        localStorage.setItem("IsUserLoggedIn", "false");
        localStorage.removeItem("transactionCurrency");
        state.IsUserLoggedIn = false
      }
    },
    mutMemberDetails: function (state, mbs) { cacheMutate(state, mbs, "memberDetails") },
    mutIsUserLoggedIn: function (state, bool) { cacheMutate(state, bool, "IsUserLoggedIn") },
    mutLocalShopUserCart: function (state, arr) { cacheMutate(state, arr, "localShopUserCart") },
    mutBCache: function (state, { key, value }) {
      state.buyablesCache.set(key, value)
    },
    mutActiveBuyables: function (state, b) {
      b = b ? b : []
      // full reset:
      //console.log("🛍️ Changing active buyables " + (b ? b.length : 0))
      state.activeBuyables = b
      state.filteredBuyables = b
    },
    mutStoreId: function (state, id) { state.storeId = id },
    mutNavContext: async function (state, { id, c, c2 }) {
      if (c) state.navContext = c
      if (c2) state.underNavContext = c2
      state.currentShopCategory = { ...state.currentShopCategory, c: c, c2: c2, Id: state.detailId }
      // If we have a product Id, we zoom in:
      if (id) {
        type Buyable = { Name: string; name: string, id: string; underlying_id: string; Id: string; imgs: Array<string>; Properties: Map<any, any>; units: { unit: string; price: number; }[]; currentImg: string; }
        const buyables = state.activeBuyables as Array<Buyable>
        if (!buyables) return

        if (state.navContext != 'mkt')
            buyablesService.getFullProduct(state.storeId, id).then((vcbuyable: Buyable) => {
            state.lastBuyable = vcbuyable || proxyBuyable
            state.detailId = id
            })
        else { // Obsolete soon!
          const loadedBuyable = buyables.find(b => (b.id === id) || (b.underlying_id === id) || (b.Id === id)) as Buyable
          if (loadedBuyable) {
            //console.log("🧩 Loaded buyable props " + JSON.stringify (loadedBuyable.Properties.keys()))
            state.lastBuyable = { ...proxyBuyable, ...loadedBuyable }
            state.detailId = id
            }
          }
        }
      else
        state.detailId = ''
      //console.log (`🧭 Changing to navigation context, id=${state.detailId} and navContext=${state.navContext} and undernavContext=${state.underNavContext}` )
    },
    mutRecentlyViewed: function (state, c) { cacheMutate(state, c, "recentlyViewed") },
    mutOfferProducts: function (state, c) {
      //console.log(" 🐝 offers: " + JSON.stringify (c));
      state.offerproducts = c
    },
    mutShopCategories: function (state, c) {
      state.shopCategories = c
    },
    mutShopUserCart: function (state, c) { state.shopUserCart = c },
    mutShopFilters: function (state, c) { state.shopFilters = c },
    mutShopOrders: function (state, c) { state.shopOrders = c },
    mutBuyableRedeemSuccess: function (state, c) { state.buyableRedeemSuccess = c },
    setPasswordPageType: function (state, c) { state.passwordPageType = c },
    setTransactionCurrency: function (state, arr) { cacheMutate(state, arr, "transactionCurrency") },
  },
  actions: {
    paginate ({ state, getters }, {pageCursor, countPerPage} ) {
      // pageCursor starts at 1
      const countPerBlock = state.countPerBlock
      const newCursor = (pageCursor - 1) * countPerPage
      const minCursor = state.block.startCursor
      const maxCursor = minCursor + countPerBlock - 1
      if ((newCursor < minCursor) || (newCursor > maxCursor)  ) {
        const c = state.navContext
        const c2 = state.underNavContext
        buyablesService.getBuyables (c, c2, getters.filter, [newCursor, countPerBlock])
          .then (() => this.commit ('mutPageCursor', pageCursor) )
        }
      else
        this.commit ('mutPageCursor', pageCursor)
      },
    navigate(s, item) {
      let path = ''
      if (item.layer) path += "/layer/" + item.layer
      if (item.c) path += "/context/" + item.c
      if (item.c2) path += "/undercontext/" + item.c2
      if (item.d) path += "/detail/" + item.d
      //console.log(`🧭 Navigating to ${JSON.stringify (item)}`)
      // ALL NAVIGATION CHANGES HAPPEN IN THE ROUTER, GUARANTEEING DEEP LINK - ability
      this.commit("mutFilter", "")
      router.push(path).then (() => this.commit ('mutPageCursor', 1)).catch(() => { })
      }
    },
  modules: {
    flight,
    car,
    hotel
  }
})
