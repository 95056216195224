<template>
  <a-card
    :title="$t('addcomm.By Brands')"
    :bordered="false"
    style="width: 100%; height: 100%"
  >
    <a-row>
      <a-col :span="24">
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
        >
          {{ $t("addcomm.Select All") }}
        </a-checkbox>
      </a-col>
    </a-row>
    <a-checkbox-group
      v-model="shopBrandFilters"
      style="width: 100%"
      @change="onChange"
    >
      <a-row v-for="checkbox in options" :key="checkbox">
        <a-col :span="24">
          <a-checkbox :value="checkbox">{{ checkbox }}</a-checkbox>
        </a-col>
      </a-row>
    </a-checkbox-group>
  </a-card>
</template>

<script>
// import ShopHelper from "../Helper.ts";
export default {
  data() {
    return {
      indeterminate: false,
      checkAll: true,
      shopBrandFilters: [],
      options: [],
    };
  },

  watch: {
    shopBrandFilters: function () {
      this.$emit("input", this.shopBrandFilters);
    },

    brandOpts(d) {
      this.shopBrandFilters = d;
      this.options = d;
    },
  },

  updated() {
    this.$emit("shopBrands", this.shopBrandFilters);
  },

  created() {
    this.shopBrandFilters = this.$store.getters.shopFilters.allBrand;
    this.options = this.$store.getters.shopFilters.allBrand;
    this.$emit("shopBrands", this.shopBrandFilters);
  },
  methods: {
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.options.length;
      this.checkAll = checkedList.length === this.options.length;
      this.$emit("shopBrands", this.shopBrandFilters);
    },

    onCheckAllChange(e) {
      Object.assign(this, {
        shopBrandFilters: e.target.checked ? this.options : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      this.$emit("shopBrands", this.shopBrandFilters);
    },
  },
  computed: {
    brandOpts() {
      return this.$store.getters.shopFilters.allBrand;
    },
  },
};
</script>

<style scoped>
.ant-card >>> .ant-card-body {
  padding-bottom: 14px !important;
}

.ant-card >>> .ant-card-head-title {
  text-align: center;
}

div >>> .ant-checkbox-wrapper {
  width: 100%;
  /* text-align: left; */
  margin: 0 0 5px;
}
</style>>
