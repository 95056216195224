import store from '@/store/index';
export default {
    billingFormValidation(billingForm: any) {
        // console.log(billingForm);
        const errorAlert = [];
        if (billingForm.fName === "") {
            errorAlert.push("Please enter First Name");
          }
          if (billingForm.lName === "") {
            errorAlert.push("Please enter Last Name");
          }
          const regexEmail =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            !regexEmail.test(billingForm.email.toLowerCase()) ||
            billingForm.email === ""
          ) {
            errorAlert.push("Please enter valid Email Address");
          }
          if (billingForm.phone === "") {
            errorAlert.push("Please enter Phone Number");
          }
          if (billingForm.address1 === "") {
            errorAlert.push("Please enter Address");
          }
          if (billingForm.countryName === "") {
            errorAlert.push("Please select Country");
          }
          if (billingForm.city === "") {
            errorAlert.push("Please enter City");
          }
          if (billingForm.zip === "") {
            errorAlert.push("Please enter Zip Code");
          }
          return errorAlert;
    },

    findShopFiltersData() {
        const activeBuyables = store.getters.activeBuyables;
        const r: any = {
            allNames: [],
            allBrand: [],
            allCategory: [],
            allColor: [],
            allPrice: [],
        };
        const allShopFilters = r;
        // console.log(activeBuyables)
        if (activeBuyables && activeBuyables.length > 0) {
            activeBuyables.forEach((item: any) => {
                if (item.properties) {
                    r.allNames.push(item.name);
                    item.properties.forEach((property: any) => {
                        if (property.name === "Brand" && property.value) {
                            r.allBrand.push(property.value);
                            item.BrandName = property.value;
                        }
                        if (property.name === "Category" && property.value) {
                            r.allCategory.push(property.value);
                            item.CategoryName = property.value;
                        }
                        if (property.name === "Color" && property.value) {
                            r.allColor.push(property.value);
                            item.ColorName = property.value;
                        }
                        if (property.name === "VendorSalePrice" && property.value) {
                            r.allPrice.push(Number(property.value));
                            item.SalePrice = Number(property.value);
                        }
                    });
                }

                // 
                allShopFilters.allColor = this.removeDuplicates(r.allColor);
                allShopFilters.allBrand = this.removeDuplicates(r.allBrand);
                allShopFilters.allCategory = this.removeDuplicates(r.allCategory);
                allShopFilters.allNames = this.removeDuplicates(r.allNames);

                const notDupilcate = this.removeDuplicates(r.allPrice);
                const maxPoint = Math.max(...notDupilcate);
                const minPoint = Math.min(...notDupilcate);

                allShopFilters.minPoint = Math.ceil(minPoint);
                allShopFilters.maxPoint = Math.ceil(maxPoint + 1);
                // console.log(allShopFilters);
                store.commit('mutShopFilters', allShopFilters);
            })
        }
        return activeBuyables;
    },

    removeDuplicates(arr: any) {
        return arr.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
    },

    filterBuyables(payload: any, colors: any, brands: any, categories: any, name: any, prices: any) {
        // console.log(colors, brands, categories, name, prices);
        // console.log(payload);
        if (colors.length === 0 || brands.length === 0 || categories.length === 0) {
            // console.log("inside 1111")
            return [];
        }
        if (colors.length === store.getters.shopFilters.allColor.length &&
            brands.length === store.getters.shopFilters.allBrand.length &&
            categories.length === store.getters.shopFilters.allCategory.length &&
            name === "" && prices[0] === store.getters.shopFilters.minPoint && 
            prices [1] === store.getters.shopFilters.maxPoint) {
            // console.log("inside 2222")
            return payload;
        }

        // // Filter By Shop Product Name
        if (name) {
            // console.log("inside 3333")
            payload = payload.filter((data: any) => {
                if (data.name.toLowerCase().includes(name.toLowerCase())) {
                    return data;
                }
            });
        }

        // // Filter By Shop Products Color
        if (colors !== undefined && colors.length !== 0) {
            // console.log("inside 4444")
            payload = payload.filter((data: any) => {
                if (data.ColorName && colors.includes(data.ColorName)) {
                    return data;
                }
            });
            // console.log(payload);
        }

        // // Filter Product Price
        if (prices !== undefined && prices.length !== 0) {
            // console.log("inside 5555")
            payload = payload.filter((data: any) => {
                if ((data.SalePrice >= prices[0]) && (data.SalePrice <= prices[1])) {
                    return data;
                }
            });
            // console.log(payload);
        }

        // // Filter By Shop Products Category
        if (categories !== undefined && categories.length !== 0) {
            // console.log("inside 6666")
            payload = payload.filter((data: any) => {
                if (data.CategoryName && categories.includes(data.CategoryName)) {
                    return data;
                }
            });
            // console.log(payload);
        }

        // // Filter By Shop Products Brand
        if (brands !== undefined && brands.length !== 0) {
            // console.log("inside 7777")
            payload = payload.filter((data: any) => {
                if (data.BrandName && brands.includes(data.BrandName)) {
                    return data;
                }
            });
            // console.log(payload);
        }
        // console.log(payload);
        return payload;
    }
}