
//const VC_API_Url = (process.env.NODE_ENV === 'development') ? "http://18.141.1.188:8081" : "https://shopgatewayuat.giift.com:9081";
const VC_API_Url = "https://shopgatewayuat.giift.com:9081" // "https://shopgatewayapi.giift.com:8081" // 

const CREDS = {
    VUE_APP_client_id: "lbms_cloud_marketplace",
    VUE_APP_client_secret: "29b231b80163beb9ae6132d17fb799f93392721b",
    VUE_APP_grant_type: "client_credentials",
    VUE_APP_scope: "cart wallet user cloud_lbms_client.user cloud_lbms_client.program cloud_lbms_client.redemption retailer.exchange.read",
    VUE_APP_api_url: "https://sandbox.giift.com",
    VUE_APP_ASG_url: "https://stage.coolfen.com",
    VUE_APP_dmoId: "1571107170YFPJ8",
    VUE_APP_sessionId: "9fbce3d9-8717-485b-8efc-ef4a092d961b",
    VUE_APP_RelationType: "4",
    VUE_APP_ProgramName: "BNI",
    VUE_APP_RetailerId: "1570853016Zb888",
    VUE_APP_Shop_api_url: VC_API_Url,
    VUE_APP_ShopClientSecret: "f0a2e8a5-ceaa-40e8-ab14-c00251dd4501",
    VUE_APP_ShopGrantType: "client_credentials",
    VUE_APP_ShopClientId: "faed4a93-71da-40f4-ad24-1c39f947dd05",
    VUE_APP_IBEBaseUrl: "https://ibeapi.giift.com",
    VUE_APP_IBEHotelUsername: "UNIFIEDMARKETPLACE",
    VUE_APP_IBEHotelToken: "P@ssword12",
    VUE_APP_IBEFlightUsername: "UNIFIEDMARKETPLACE",
    VUE_APP_IBEFlightToken: "P@ssword12",
    VUE_APP_IBECarUsername: "UNIFIEDMARKETPLACE",
    VUE_APP_IBECarToken: "P@ssword12"
}

export default CREDS