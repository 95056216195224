<template>
  <div>
    <div class="row" style="margin-top: 3%">
      <div class="col-md-2"></div>
      <div class="col-md-2" v-if="carDriverInfo !== ''">
        <a-card title="Driver Information" style="width: 100%">
          <div style="text-align: left">
            <h6>
              {{ carDriverInfo.title }} {{ carDriverInfo.firstname }}
              {{ carDriverInfo.lastname }}
            </h6>
            <h6>
              {{ $t("addcomm.Age") }}:
              {{ carBookingRequest.Booking.DriverInfo[0].DriverAge }}
            </h6>
          </div>
        </a-card>
      </div>
      <div class="col-md-6" v-if="carBookingRequest !== ''">
        <a-card
          :title="'Car Details (' + carBookingRequest.FinalPoints + ' Points)'"
          style="width: 100%"
        >
          <!-- <a slot="extra" href="#">more</a> -->
          <div>
            <div class="row">
              <div class="col-md-2">
                <img
                  :src="carBookingRequest.Booking.Vehicle[0].ImageURL"
                  height="120px"
                  width="150px"
                />
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-9" style="text-align: left">
                <h6>{{ carBookingRequest.Booking.Vehicle[0].Name }}</h6>
                <h6>
                  {{ $t("addcomm.Transmission type") }} :
                  {{ carBookingRequest.Booking.Vehicle[0].automatic }}
                </h6>
                <h6>
                  {{ $t("addcomm.Seats") }}:
                  {{ carBookingRequest.Booking.Vehicle[0].seats }}
                </h6>
                <h6>
                  {{ $t("addcomm.Doors") }}:
                  {{ carBookingRequest.Booking.Vehicle[0].doors }}
                </h6>
                <h6>
                  AC/Non-AC:
                  {{
                    carBookingRequest.Booking.Vehicle[0].aircon === "Yes"
                      ? "AC"
                      : "Non-AC"
                  }}
                </h6>
                <h6>
                  {{ $t("addcomm.Fuel") }}:
                  {{ carBookingRequest.Booking.Vehicle[0].fuelPolicy }}
                </h6>
                <h6>
                  {{ $t("addcomm.Group") }}:
                  {{ carBookingRequest.Booking.Vehicle[0].group }}
                </h6>
              </div>
            </div>
          </div> </a-card
        ><br />
        <div>
          <a-button block type="primary" @click="bookNow()">
            {{ isProcessing === true ? "Please Wait...." : "Book Now" }}
          </a-button>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>
<script>
import Helper from "./Helper.ts";
import LBMSService from "@/api/lbmsService";
import car from "../../../api/ibe/car";
import { i18n } from "@/i18n";
export default {
  data() {
    return {
      carBookingRequest: "",
      carDriverInfo: "",
      isProcessing: false,
      externalReference: '',
      isTransactSuccess: false,
    };
  },
  activated() {
    this.carBookingRequest = this.$store.getters["car/carBookingRequest"];
    this.carDriverInfo = this.$store.getters["car/carDriverInfo"];
    if (this.carBookingRequest.Booking === undefined) {
      this.$store.dispatch("navigate", { c2: "car" });
    } else {
      this.carBookingRequest.Booking.Vehicle[0].group = Helper.SetupCarClass(
        this.carBookingRequest.Booking.Vehicle[0].group
      );
      this.carBookingRequest.Booking.Vehicle[0].fuelPolicy =
        Helper.SetupfuelType(
          this.carBookingRequest.Booking.Vehicle[0].fuelPolicy
        );
      // console.log(this.carBookingRequest);
      // console.log(this.carDriverInfo);
    }
  },
  methods: {
    bookNow() {
      this.isProcessing = true;
      let selectedCar = this.$store.getters["car/selectedCar"];
      let SearchRequest = this.$store.getters["car/carSearchRequest"];
      const memberDetails = this.$store.getters.memberDetails || "";
      const redeempostdata = {
        RelationReference:
          memberDetails.MemberRelationsList[0].RelationReference || "",
        ProgramId: this.$store.getters.programDefinition.program_id,
        RelationType: this.$store.getters.relationType,
        TransactionCurrency: this.$store.getters.transactionCurrency || "",
        Amounts: selectedCar.Price[0].TotalBaseFare,
        Points: this.carBookingRequest.FinalPoints,
        MerchantName:
          "Car - " +
          SearchRequest.pickUp.Country +
          "-" +
          SearchRequest.dropOff.Country,
        LoyaltyTxnType: 7,
      };
      // console.log(redeempostdata);
      LBMSService.RedeemPoints(redeempostdata).then((result) => {
        // console.log(result);
        if (result.results.IsSuccessful) {
          this.externalReference = result.results.ReturnObject;
          this.callCarBookingAPI(this.carBookingRequest);
        } else {
          this.isProcessing = false;
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: i18n.t(
              "addcomm.Failed"
            )`: ${result.results.ErrorDescription}`,
          });
        }
      });
    },
    callCarBookingAPI(postdata) {
      this.isTransactSuccess = false;
      car.CarBookRequest(postdata).then((response) => {
        // console.log(response);
        if (response && response.data.results.MakeBookingRS.Booking.id) {
          this.isTransactSuccess = true;
          this.$store.commit("car/setCarBookingResponse", response.data.results);
          this.$store.dispatch("navigate", { c2: "car-booking-success" });
          this.checkUserPoints();
        } else {
            this.isTransactSuccess = false;
        }
      }).finally(() => {
        this.isProcessing = false;
        if (this.isTransactSuccess === false) {
          this.$notification["error"]({
              message: i18n.t("addcomm.Error"),
              description: "Booking Failed",
            });
            // roll back points
            let SearchRequest = this.$store.getters["car/carSearchRequest"];
            const MerchantName =
              "Car - " +
              SearchRequest.pickUp.Country +
              "-" +
              SearchRequest.dropOff.Country +
              "- Reversal";
            LBMSService.ReversalPoints(MerchantName, this.externalReference).then((response) => {
              if (response && response.results.IsSuccessful) {
                console.log(response);
                this.checkUserPoints();
              }
            });
        }
      });
    },
    checkUserPoints() {
      LBMSService.CheckAvailability().then((response) => {
        if (response.results.IsSuccessful) {
          this.$store.commit("mutPoints", response.results.ReturnObject);
        }
      });
    },
  },
};
</script>
<style scoped>
</style>