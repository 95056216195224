<template>
  <div style="height: 100%; overflow: scroll">
    <div v-if="isLoggedIn">
      <div class="row my-order">
        <br /><br />
        <h6>
          <a-button
            @click="$store.dispatch('navigate', { c2: 'orders' })"
            type="primary"
            icon="database"
          >
            {{ $t('addcomm.My Orders') }}
          </a-button>
        </h6>
      </div>
      <div class="cart_section">
        <div class="container-fluid">
          <div class="row" v-if="shopItems.length > 0" style="margin-top: -5%">
            <div class="col-lg-10 offset-lg-1">
              <div class="cart_container">
                <div class="cart_title">
                  {{ $t('addcomm.Shopping Cart') }}
                  <small> ({{ shopItems.length }} {{ $t('addcomm.Items') }}) </small>
                </div>
                <div
                  class="cart_items"
                  v-for="item in shopItems"
                  :key="item.Id"
                >
                  <ul class="cart_list">
                    <li class="cart_item clearfix">
                      <div class="cart_item_info row justify-content-between">
                        <div class="col-md-3 cart_item_image">
                          <img :src="item.ImageUrl" alt="" height="130px" width="80px" />
                        </div>
                        <div class="col-md-3">
                          <div class="cart_item_title">{{ $t('common.Name') }}</div>
                          <div class="cart_item_text">{{ item.Name }}</div>
                        </div>
                        <div class="col-md-2">
                          <div class="cart_item_title">{{ $t('details.Quantity') }}</div>
                          <div class="input-group">
                            <input
                              type="button"
                              value="-"
                              class="button-minus"
                              data-field="quantity"
                              @click="decreaseQty(item)"
                            />
                            <input
                              type="number"
                              step="1"
                              max=""
                              :value="item.Quantity"
                              name="quantity"
                              class="quantity-field"
                              disabled
                            />
                            <input
                              type="button"
                              value="+"
                              class="button-plus"
                              data-field="quantity"
                              @click="increaseQty(item)"
                            />
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="cart_item_title">{{ $t('addcomm.Price') }}</div>
                          <div class="cart_item_text">
                            {{ item.Price.SalePriceWithTax.FormattedAmount }}
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="cart_item_title">{{ $t('addcomm.Total') }}</div>
                          <div class="cart_item_text">
                            {{
                              item.Quantity * item.Price.SalePriceWithTax.Amount
                            }}
                            {{ item.Price.SalePriceWithTax.Currency.Symbol }}
                          </div>
                        </div>
                        <div class="col-md-1">
                          <div class="cart_item_title">{{ $t('addcomm.Remove') }}</div>
                          <div class="cart_item_text">
                            <span
                              ><a-icon @click="removeItem(item)" type="delete"
                            /></span>
                          </div>
                        </div>
                        <!-- <div class="cart_item_color cart_info_col">
                        <div class="cart_item_title">Color</div>
                        <div class="cart_item_text">
                          <span style="background-color: #999999"></span>Silver
                        </div>
                      </div> -->
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="order_total">
                  <div class="order_total_content text-md-right">
                    <div class="order_total_title">{{ $t('addcomm.Order Total') }}:</div>
                    <div class="order_total_amount">
                      {{ shopUserCart.Price.SubTotalWithTax.FormattedAmount }}
                    </div>
                  </div>
                </div>
                <div class="cart_buttons">
                  <button
                    type="button"
                    class="button cart_button_clear"
                    @click="
                      $store.dispatch('navigate', { c2: 'home' })
                    "
                  >
                    <a-icon type="arrow-left" />&nbsp;&nbsp;{{ $t('addcomm.Continue Shopping') }}
                  </button>
                  <button
                    type="button"
                    class="button cart_button_checkout"
                    @click="updateCart"
                  >
                    <a-icon type="sync" />&nbsp;&nbsp; {{ $t('addcomm.Update Cart') }}</button
                  >&nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    class="button cart_button_checkout"
                    @click="gotoNextPage()"
                  >
                    {{ $t('addcomm.Checkout') }} &nbsp;&nbsp; <a-icon type="arrow-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div style="text-align: center" v-if="isProcessing === false && shopItems.length === 0">
            <h4>{{ $t('addcomm.Cart is Empty') }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isLoggedIn">
      <div class="cart_section">
        <div class="container-fluid">
          <div class="row" v-if="localCart.length > 0" style="margin-top: -5%">
            <div class="col-lg-10 offset-lg-1">
              <div class="cart_container">
                <div class="cart_title">
                  {{ $t('addcomm.Shopping Cart') }}<small> ( {{ localCart.length }} {{ $t('addcomm.Items') }}) </small>
                </div>
                <div
                  class="cart_items"
                  v-for="item in localCart"
                  :key="item.Id"
                >
                  <ul class="cart_list">
                    <li class="cart_item clearfix">
                      <div class="cart_item_info row justify-content-between">
                        <div class="col-md-3 cart_item_image">
                          <img :src="item.PrimaryImage.Url" alt="" />
                        </div>
                        <div class="col-md-3">
                          <div class="cart_item_title">{{ $t('common.Name') }}</div>
                          <div class="cart_item_text">{{ item.Name }}</div>
                        </div>
                        <div class="col-md-2">
                          <div class="cart_item_title">{{ $t('details.Quantity') }}</div>
                          <div class="input-group">
                            <input
                              type="button"
                              value="-"
                              class="button-minus"
                              data-field="quantity"
                              @click="decreaseQty(item)"
                            />
                            <input
                              type="number"
                              step="1"
                              max=""
                              :value="item.Quantity"
                              name="quantity"
                              class="quantity-field"
                              disabled
                            />
                            <input
                              type="button"
                              value="+"
                              class="button-plus"
                              data-field="quantity"
                              @click="increaseQty(item)"
                            />
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="cart_item_title">Price</div>
                          <div class="cart_item_text">
                            {{ item.Price.SalePriceWithTax.FormattedAmount }}
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="cart_item_title">Total</div>
                          <div class="cart_item_text">
                            {{
                              item.Quantity * item.Price.SalePriceWithTax.Amount
                            }}
                            {{ item.Price.SalePriceWithTax.Currency.Symbol }}
                          </div>
                        </div>
                        <div class="col-md-1">
                          <div class="cart_item_title">{{ $t('addcomm.Remove') }}</div>
                          <div class="cart_item_text">
                            <span @click="removeItem(item)"
                              ><a-icon type="delete"
                            /></span>
                          </div>
                        </div>
                        <!-- <div class="cart_item_color cart_info_col">
                        <div class="cart_item_title">Color</div>
                        <div class="cart_item_text">
                          <span style="background-color: #999999"></span>Silver
                        </div>
                      </div> -->
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="order_total">
                  <div class="order_total_content text-md-right">
                    <div class="order_total_title">{{ $t('addcomm.Order Total') }}:</div>
                    <div class="order_total_amount">
                      {{ localCartOrderTotal }}
                    </div>
                  </div>
                </div>
                <div class="cart_buttons">
                  <button
                    type="button"
                    class="button cart_button_clear"
                    @click="
                      $store.dispatch('navigate', { c2: 'home' })
                    "
                  >
                    <a-icon type="arrow-left" />{{ $t('addcomm.Continue Shopping') }}
                  </button>
                  <button
                    type="button"
                    class="button cart_button_checkout"
                    @click="
                      $store.dispatch('navigate', {
                        layer: 'user',
                        c2: 'login'
                      })
                    "
                  >
                    {{ $t('common.Login') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="localCart.length === 0">{{ $t('addcomm.Cart is Empty') }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="isProcessing === true"
      class="col-md-9"
      style="height: 100%; z-index: 10; margin-left: 12%"
    >
        <a-spin
          size=large
          class="ht-100"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        />
    </div>
  </div>
</template>
<script>
import shopAuth from "@/views/modules/shop/api/shop";
import { i18n } from '@/i18n';
export default {
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.IsUserLoggedIn;
    },
  },
  data() {
    return {
      shopUserCart: {},
      localCart: [],
      shopItems: [],
      localCartOrderTotal: 0,
      updateCartItems: [],
      isProcessing: true,
    };
  },
  activated() {
    if (this.isLoggedIn) {
      this.callShopUserCart();
    } else {
      console.log("inside not logged in");
      this.localCart = this.$store.getters.localShopUserCart;
      this.isProcessing = false;
      // this.localCart = Array.from(this.localCart);
      this.localCartOrderTotal = 0;
      this.localCart.filter((item) => {
        this.localCartOrderTotal =
          this.localCartOrderTotal +
          item.Price.SalePriceWithTax.Amount * item.Quantity;
      });
    }
  },
  methods: {
    callShopUserCart() {
      shopAuth.searchUserCart().then(response => {
        this.shopUserCart = response;
        this.shopItems = this.shopUserCart.Items || [];
        this.isProcessing = false;
      });
    },
    removeItem(product) {
      if (this.$store.getters.IsUserLoggedIn) {
        product.Quantity = 0;
        this.shopItems = this.shopItems.filter(
          (item) => item.Id !== product.Id
        );
        this.checkForUpdatedCartItem(product, 0);
        console.log("when logged in");
        if (this.shopItems.length === 0) this.updateCart();
      } else if (!this.$store.getters.IsUserLoggedIn) {
        this.localCart = this.localCart.filter((item) => {
          return item.Id !== product.Id;
        });
        this.localCartOrderTotal = 0;
        this.localCart.filter((item) => {
          this.localCartOrderTotal =
            this.localCartOrderTotal +
            item.Price.SalePriceWithTax.Amount * item.Quantity;
        });

        this.$store.commit("mutLocalShopUserCart", this.localCart);
      }
    },
    increaseQty(product) {
      if (this.$store.getters.IsUserLoggedIn) {
        console.log("when logged in");
        this.shopItems.filter((item) => {
          if (item.Id === product.Id && item.Quantity < item.MaxQuantity) {
            item.Quantity = item.Quantity + 1;
            this.checkForUpdatedCartItem(product, item.Quantity);
          }
        });
      } else if (!this.$store.getters.IsUserLoggedIn) {
        this.localCart.filter((item) => {
          if (item.Id === product.Id && item.Quantity < item.MaxQuantity)
            item.Quantity = item.Quantity + 1;
        });
        this.$store.commit("mutLocalShopUserCart", this.localCart);
      }
    },
    decreaseQty(product) {
      if (this.$store.getters.IsUserLoggedIn) {
        console.log("when logged in");
        this.shopItems.filter((item) => {
          if (item.Id === product.Id && item.Quantity > 1) {
            item.Quantity = item.Quantity - 1;
            this.checkForUpdatedCartItem(product, item.Quantity);
          }
        });
      } else if (!this.$store.getters.IsUserLoggedIn) {
        this.localCart.filter((item) => {
          if (item.Id === product.Id && item.Quantity > 1)
            item.Quantity = item.Quantity - 1;
        });
        this.$store.commit("mutLocalShopUserCart", this.localCart);
      }
    },
    checkForUpdatedCartItem(product, qty) {
      const searchItem = this.updateCartItems.filter((item) => {
        if (item.Id === product.Id) {
          item.Quantity = qty;
          return item;
        }
      });
      if (searchItem.length === 0) {
        product.Quantity = qty;
        this.updateCartItems.push(product);
      }
    },
    updateCart() {
      if (this.updateCartItems.length > 0) {
        const userCart = this.$store.getters.shopUserCart;
        const storeDetails = this.$store.getters.storeDetails;
        let newItems = [];
        for (let i = 0; i < this.updateCartItems.length; i++) {
          newItems.push({
            ProductId: this.updateCartItems[i].ProductId,
            Quantity: this.updateCartItems[i].Quantity,
          });
        }
        const postdata = {
          CatalogId: storeDetails.Catalog,
          CartId: userCart.Id,
          Items: newItems,
        };
        shopAuth.addUpdateCartItems(postdata).then((response) => {
          if (response) this.callShopUserCart();
          this.$notification["success"]({
            message: i18n.t('addcomm.Success'),
            description: i18n.t('addcomm.Cart Updated successfully'),
          });
        });
      }
    },
    gotoNextPage() {
      const orderTotal = this.shopUserCart.Price.SubTotalWithTax.Amount
      const userCurrentPoints = this.$store.getters.points || 0;
      console.log(orderTotal, userCurrentPoints)
      if (orderTotal > userCurrentPoints)
      this.$notification["error"]({ message: i18n.t("addcomm.Error"), description: i18n.t("addcomm.Insufficient Points")});
      else
      this.$store.dispatch('navigate', { c2: 'checkout' })
    }
  },
};
</script>
<style scoped>
.my-order {
  text-align: right;
  margin-right: 10%;
  padding-top: 2%;
}

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

body {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: #e0e0e0;
  color: #000000;
}

ul {
  list-style: none;
  margin-bottom: 0px;
}

.button {
  display: inline-block;
  background: #0e8ce4;
  border-radius: 5px;
  height: 48px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.button a {
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 48px;
  color: #ffffff;
  padding-left: 35px;
  padding-right: 35px;
}

.button:hover {
  opacity: 0.8;
}

.cart_section {
  width: 100%;
  padding-top: 93px;
  padding-bottom: 111px;
}

.cart_title {
  font-size: 30px;
  font-weight: 500;
}

.cart_items {
  margin-top: 8px;
}

.cart_list {
  border: solid 1px #e8e8e8;
  /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
}

.cart_item {
  width: 100%;
  padding: 15px;
  padding-right: 46px;
}

.cart_item_image {
  width: 133px;
  height: 133px;
  float: left;
}

.cart_item_image img {
  max-width: 100%;
}

.cart_item_info {
  width: calc(100% - 133px);
  float: left;
  padding-top: 18px;
}

.cart_item_name {
  margin-left: 7.53%;
}

.cart_item_title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.cart_item_text {
  font-size: 18px;
  margin-top: 35px;
}

.cart_item_text span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 11px;
  -webkit-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -ms-transform: translateY(4px);
  -o-transform: translateY(4px);
  transform: translateY(4px);
}

.cart_item_price {
  text-align: right;
}

.cart_item_total {
  text-align: right;
}

.order_total {
  width: 100%;
  height: 60px;
  margin-top: 30px;
  border: solid 1px #e8e8e8;
  /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1); */
  padding-right: 46px;
  padding-left: 15px;
  background-color: #fff;
}

.order_total_title {
  display: inline-block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 60px;
}

.order_total_amount {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  margin-left: 26px;
  line-height: 60px;
}

.cart_buttons {
  margin-top: 60px;
  text-align: right;
}

.cart_button_clear {
  display: inline-block;
  border: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.5);
  background: #ffffff;
  border: solid 1px #b2b2b2;
  padding-left: 35px;
  padding-right: 35px;
  outline: none;
  cursor: pointer;
  margin-right: 26px;
}

.cart_button_clear:hover {
  border-color: #0e8ce4;
  color: #0e8ce4;
}

.cart_button_checkout {
  display: inline-block;
  border: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 48px;
  color: #ffffff;
  padding-left: 35px;
  padding-right: 35px;
  outline: none;
  cursor: pointer;
  vertical-align: top;
}

input,
textarea {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 10px;
}

input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group {
  clear: both;
  margin: 15px 0;
  position: relative;
}

.input-group input[type="button"] {
  background-color: #eeeeee;
  min-width: 38px;
  width: auto;
  transition: all 300ms ease;
}

.input-group .button-minus,
.input-group .button-plus {
  font-weight: bold;
  height: 38px;
  padding: 0;
  width: 38px;
  position: relative;
}

.input-group .quantity-field {
  position: relative;
  height: 38px;
  left: -6px;
  text-align: center;
  width: 62px;
  display: inline-block;
  font-size: 13px;
  margin: 0 0 5px;
  resize: vertical;
}

.button-plus {
  left: -13px;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}
</style>
