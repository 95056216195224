<template>
  <div style="position: relative">
    <div class="navbar container">
      <div class="display-5">
        <img class="logo" height="80px" src="@/assets/img/logosimple.jpeg" alt=""/>
        <div class="search-input">
          <img src="../assets/img/paymentmethod/shopicon.png" style="margin-right: 20px;" alt=""/>
          <a-auto-complete
              :data-source="stdShopCandidates"
              style="margin-left: 20px; width: 250px"
              :class="{ invalidstore: $store.getters.invalidStoreDetails }"
              :placeholder="$store.getters.storeId"
              placeholder-class="color:orange"
              @change="switchStore"
              :filter-option="filterOption"
          />
          <a
              @mousedown="$store.commit('toggleBranDing')"
              class="outsidelink"
          >
            <a-icon v-if="$store.getters.branDing" type="eye-invisible" class="logomode" alt=""/>
            <a-icon v-else type="eye" alt=""/>

          </a>
        </div>
      </div>
      <div class="nav-right">
        <div class="language">
          <a-dropdown>
            <a
                class="language_dropdown-link nav-link dropdown-toggle"
                @click="(e) => e.preventDefault()"
            >
            <img v-if="$t('locale_name') === 'English'" src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/en.png" alt="">
            <img v-if="$t('locale_name') === '简体中文'" src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/cn.png" alt="">
            <img v-if="$t('locale_name') === 'Francais'" src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/fr.png" alt="">
            <img v-if="$t('locale_name') === 'عربى'" src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/ar.png" alt="">
            <img v-if="$t('locale_name') === 'Bahasa Indonesia'" src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/bi.png" alt="">
            <img v-if="$t('locale_name') === 'Bahasa Melayu'" src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/bm.png" alt="">
              {{ $t("locale_name") }}
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a class="language_dropdown-item" @click="changeLang('en')">
                  <img src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/en.png" alt="">
                  &nbsp;English
                </a>
              </a-menu-item>
              <a-menu-item>
                <a class="language_dropdown-item" @click="changeLang('zh-CN')">
                  <img src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/cn.png" alt="">
                  &nbsp;简体中文
                </a>
              </a-menu-item>
              <a-menu-item>
                <a class="language_dropdown-item" @click="changeLang('fr')">
                  <img src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/fr.png" alt="">
                  &nbsp;Francais
                </a>
              </a-menu-item>
              <a-menu-item>
                <a class="language_dropdown-item" @click="changeLang('ar')">
                  <img src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/ar.png" alt="">
                  &nbsp;عربى
                </a>
              </a-menu-item>
              <a-menu-item>
                <a class="language_dropdown-item" @click="changeLang('bi')">
                  <img src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/bi.png" alt="">
                  &nbsp;Bahasa Indonesia
                </a>
              </a-menu-item>
              <a-menu-item>
                <a class="language_dropdown-item" @click="changeLang('bm')">
                  <img src="https://d2vyvfypn1lj3h.cloudfront.net/assets/img/flag/bm.png" alt="">
                  &nbsp;Bahasa Melayu
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="userinfo" @click="$store.dispatch('navigate', {layer: 'user', c2: 'userprofile' })">
          <img
              src="../assets/img/paymentmethod/me.png"
              alt=""
              v-if="$store.getters.IsUserLoggedIn"
              
          />
          <span v-if="!$store.getters.IsUserLoggedIn" @click.stop="login">{{
              $t("common.Login")
            }}</span>
          <span v-if="$store.getters.IsUserLoggedIn"
          >{{ $t("common.Points") }}: {{ $store.getters.points }}</span
          >
        </div>

        <div
            class="userinfo onlythis"
            @click="$store.dispatch('navigate', { c2: 'cart' })"
        >
          <img src="../assets/img/paymentmethod/cart.png" alt=""/>
          <span>{{ $t("addcomm.cart") }}</span>
        </div>
        <div
            class="userinfo logout"
            v-if="$store.getters.IsUserLoggedIn"
            @click="logout"
        >
          <img
              class="width:19px; height:20px"
              src="../assets/img/paymentmethod/logout.png"
              alt=""
          />
          <span>{{ $t("common.Log Out") }}</span>
        </div>
      </div>
    </div>

    <categoryMenu style="width: 100%" :cats="shopCategories" :homit="true"></categoryMenu>
    <!-- categoryMenu style="width: 100%" :cats="mktCategories"></categoryMenu -->
  </div>
</template>

<script>
import categoryMenu from "@/components/categoryMenu.vue";
//import buyablesService from "@/api/buyablesService";
import debounce from "lodash/debounce";
import {li18n} from "@/i18n";

export default {
  components: {categoryMenu},
  data() {
    return {
      points: this.$store.getters.points,
      stdShopCandidates: [
        "GBXStore",
        "RealMe",
        "AHBUAT",
        "GiiftOfferStore",
        "GiiftWarehouse",
        "JOUDREWARDS",
        "visakj",
      ],
      candidateStoreName: this.$store.getters.storeId,
    };
  },
  computed: {
    shopCategories: function () {
      return this.$store.getters.shopCategories
      /* if (cats.length === 0) return [];
      //console.log ("🍄 Building shopCategories, " + cats.length)
      const homeNavItem = { name: 'home.home', c2: "home" }
      return [homeNavItem, ...cats]; */
    },
    mktCategories: function () {
      return [
        {
          title: "GiftCards",
          name: "addcomm.EgiftCard",
          id: "egiftCard",
          c: "mkt",
          c2: "egiftCard",
        },
        {
          title: "Exchanges",
          name: "mine.exchange",
          id: "Exchanges",
          c: "mkt",
        },
        {
          title: "miles",
          name: "mine.miles",
          id: "miles",
          parent: {id: "Exchanges"},
          c: "mkt",
          c2: "miles",
        },
        {
          title: "retailers",
          name: "addcomm.Retailers",
          id: "retailers",
          parent: {id: "Exchanges"},
          c: "mkt",
          c2: "retailers",
        },
        {title: "Travel", name: "home.Travel", id: "Travel", c: "mkt"},
        {
          title: "Flights",
          name: "addcomm.Flight",
          id: "flight",
          parent: {id: "Travel"},
          c: "mkt",
          c2: "flight",
        },
        {
          title: "Hotels",
          name: "addcomm.Hotel",
          id: "hotel",
          parent: {id: "Travel"},
          c: "mkt",
          c2: "hotel",
        },
        {
          title: "Cars",
          name: "addcomm.Car",
          id: "car",
          parent: {id: "Travel"},
          c: "mkt",
          c2: "car",
        },
        {name: "mine.Charity", id: "green", c: "mkt", c2: "green"},
        {
          title: "Lounges",
          name: "mine.Lounges",
          id: "lounges",
          c: "mkt",
          c2: "lounges",
        },
        //{title: 'PhysicalVoucher', name: 'physicalvoucher', id: "physicalvoucher", c: 'mkt', c2: 'physicalvoucher'},
        {
          title: "Utility",
          name: "mine.Utility",
          id: "utility",
          c: "mkt",
          c2: "utility",
        },
        {
          title: "Games",
          name: "addcomm.Games",
          id: "games",
          c: "mkt",
          c2: "games",
        },
        {
          title: "Auction",
          name: "addcomm.Auction",
          id: "auction",
          c: "mkt",
          c2: "auction",
        },
        {
          title: "Sweepstakes",
          name: "addcomm.Sweepstakes",
          id: "sweepstake",
          c: "mkt",
          c2: "sweepstake",
        },
      ];
    },
  },
  methods: {
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text
              .toUpperCase()
              .indexOf(input.toUpperCase()) >= 0
      );
    },
    handleSearch(/*value*/) {
      //this.possibleStoreNames = value ? [...stdShopCandidates, value] : [];
    },
    switchStore(id) {
      let f = () => {
        this.$store.dispatch("navigate", {c: id, c2: "home"})
      };
      debounce(f, 400)();
    },
    logout() {
      this.$store.commit("mutIsUserLoggedIn", false);
      this.$store.commit("mutCurrentUser", null);
      this.$store.dispatch("navigate", {c2: "home"});
    },
    login() {
      this.$store.dispatch("navigate", {layer: 'user', c2: "login"});
    },
    navContextIn(catNames) {
      const result = catNames.includes(this.$store.getters.underNavContext);
      return result;
    },
    changeLang(lang) {
      li18n.loadLanguageAsync(lang)
      this.$store.commit ('muti18nlang', lang)
    },
  },
};
</script>

<style scoped>
.dropdown-toggle::after {
  position: relative;
  top: 6px;
}

.invalidstore input {
  border: 2px solid var(--warning) !important;
}

.invalidstore input:focus {
  box-shadow: 0 0 0 2px #ff5050 !important;
}

.outsidelink {
  padding-left: 32px;
  margin-right: 20px;
  font-size: 20px;
  color: lightgrey;
}

.logomode {
  color: green;
}

.logout:hover {
  cursor: pointer;
}

.manage-account {
  padding-left: 25%;
  color: black;
}

.search-input {
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 8px
}

.search-input img {
  position: relative;
  left: 30px;
  z-index: 999;
}

.display-5 {
  display: flex;
}

.display-5 .logo {
  height: 56px;
}

.ant-select-search__field {
  padding-left: 40px !important;
  border-radius: 20px !important;
  box-sizing: border-box !important;
  border: 1px solid #dcdcdc !important;
}

.nav-right {
  display: flex;
  align-items: center;
}

.nav-right .language {
  display: flex;
  font-size: 12px;
  color: #1f1f1f;
  align-items: center;
  height: 66px;
}

.language_dropdown-link,
.language_dropdown-item {
  display: flex;
  align-content: center;
  font-size: 12px;
  color: #333;
}

.language_dropdown-link img{
  width: 20px;
  height: 13px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  margin-left: 5px;
}

.language_dropdown-item img {
  height: 13px;
  width: 20px;
  position: relative;
  top: 5px;
}

/* Flag from 'https://www.freakflagsprite.com/' */
.icon__flag {
  background-image: url(../assets/img/icon__flag__sprite__42.png);
  background-repeat: no-repeat;
  background-size: 100% 49494%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  box-sizing: content-box;
  width: 18px;
  height: 12px;
}

.icon__flag.en {
  background-position: center 0.2287%;
}

.icon__flag.zh-CN {
  background-position: left 0.4524%;
}

.userinfo {
  /* height: 66px; */
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #1f1f1f;
  cursor: pointer;
}

.userinfo img {
  width: 21px;
  height: 21px;
  margin-bottom: 5px;
}

.onlythis {
  border: none;
}

.onlythis img {
  width: 25px;
  height: 22px;
}

.logout img {
  width: 19px;
  height: 20px;
}
</style>
