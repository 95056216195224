<template>
  <div class="container" style="padding-top: 15px; position: relative; top: 0">
    <div class="row">
      <div class="col-md-12" v-if="serachCriteria !== ''">
        <a-collapse accordion style="margin-bottom: 15px" @change="getCollapse()">
          <a-collapse-panel
            key="1"
            :header="$t('addcomm.Modify Search')"
            style="text-align: left"
          >
            <div slot="extra" v-if="showSerachCriteria">
           ({{ filteredPayload.length }} {{$t('addcomm.Results') }} )    {{$t('addcomm.Car Search')}} :
            {{$t('addcomm.Pick Up')}}: {{ serachCriteria.pickUp.Location.Value }},
               {{ serachCriteria.pickUp.pickUpDate.format("DD-MM-YYYY") }} 
              {{$t('addcomm.Drop Off')}}: {{ serachCriteria.dropOff.Location.Value }}, 
              {{ serachCriteria.dropOff.dropOffDate.format("DD-MM-YYYY") }}
            </div>
            <car-search-panel show-errors :border="false" title="" />
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <car-automatic-filter v-model="carAutomaticFilters" />
        <car-aircon-filter v-model="carAirconFilters" />
        <car-class-filter v-model="carClassFilters" />
      </div>
      <div class="col-md-9">
        <div
          class="row"
          v-for="(result, index) in filteredPayload.slice(startIndex, endIndex)"
          :key="index"
        >
          <div class="col-md-12">
            <a-card hoverable style="margin-bottom: 15px">
              <div class="row">
                <div class="col-md-4">
                  <img
                    class="avatar"
                    :src="result.Vehicle[0].ImageURL"
                    width="250px"
                  />
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <br />
                      <h5>{{ result.Vehicle[0].Name }}</h5>
                    </div>
                    <div class="col-md-6">
                      <br />
                      <h5>{{ result.Price[0].TotalPoints }} {{$t('common.Points')}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-11">
                      <a-card-grid
                        style="width: 33.33%; text-align: center; height: 10%"
                        :hoverable="false"
                        ><span
                          > {{$t('addcomm.Car Class')}}: {{ result.Vehicle[0].CarClass }}
                        </span></a-card-grid
                      >
                      <a-card-grid
                        style="width: 33.33%; text-align: center; height: 10%"
                        :hoverable="false"
                        ><span
                          ><img
                            class="avatar"
                            src="./icons/icon-plane-sml.png"
                            height="20px"
                            width="20px"
                          />
                         {{$t('addcomm.At airport')}}--
                        </span>
                      </a-card-grid>
                      <a-card-grid
                        style="width: 33.33%; text-align: center; height: 10%"
                        :hoverable="false"
                        ><span
                          ><img
                            class="avatar"
                            src="./icons/icon_fuelpolicy.png"
                            height="20px"
                            width="20px"
                          />
                          {{ result.Vehicle[0].CarFuel }}
                        </span></a-card-grid
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-11">
                      <a-card-grid
                        style="width: 25%; text-align: center; height: 10%"
                        :hoverable="false"
                        ><span
                          ><img
                            class="avatar"
                            src="./icons/Window_Icon.png"
                            height="20px"
                            width="20px"
                          />
                          {{ result.Vehicle[0].doors }}  {{$t('addcomm.Doors')}}</span
                        ></a-card-grid
                      >
                      <a-card-grid
                        style="width: 25%; text-align: center; height: 10%"
                        :hoverable="false"
                        ><span
                          ><img
                            class="avatar"
                            src="./icons/AC_Car_Icon.png"
                            height="20px"
                            width="20px"
                          />
                          {{
                            result.Vehicle[0].aircon === "Yes" ? "AC" : "Non-AC"
                          }}
                        </span></a-card-grid
                      >
                      <a-card-grid
                        style="width: 25%; text-align: center; height: 10%"
                        :hoverable="false"
                        ><span
                          ><img
                            class="avatar"
                            src="./icons/Manual.png"
                            height="20px"
                            width="20px"
                          />
                          {{ result.Vehicle[0].automatic }}
                        </span></a-card-grid
                      >
                      <a-card-grid
                        style="width: 25%; text-align: center; height: 10%"
                        :hoverable="false"
                        ><span
                          ><img
                            class="avatar"
                            src="./icons/Person_Icon.png"
                            height="20px"
                            width="10px"
                          />
                          {{ result.Vehicle[0].seats }}{{$t('addcomm.Seats')}}
                        </span></a-card-grid
                      >
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <hr style="margin-top: -2%" />
              <div class="row">
                <!-- text-align: left; -->
                <div
                  class="col-md-6"
                  style=" padding-left: 5%"
                  @click="showModal(result)"
                >
                  <h6>{{$t('addcomm.TERMS')}} </h6>
                </div>
                <div
                  class="col-md-6"
                  style="text-align: right; padding-right: 5%"
                >
                  <a-button type="primary" @click="gotoNextPage(result)"
                    > {{$t('addcomm.Book Now')}}</a-button
                  >
                </div>
              </div>
              <br />
            </a-card>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 35px">
      <a-pagination
        :total="filteredPayload.length"
        @change="pagination"
        :pageSize="pageSize"
        v-if="filteredPayload.length !== 0"
      />
    </div>
    <div>
      <a-modal v-model="visible" :title="$t('addcomm.Terms & Conditions')" @ok="handleOk">
        <div v-if="termsCond.length > 0">
          <div v-for="(tnc, index) in termsCond" :key="index">
            <div v-if="tnc.Term !== null">
              <div v-for="(trm, indx) in tnc.Term" :key="indx">
                <div v-if="trm.Body !== '  <br/>'">
                  <h5>{{ trm.Caption }}</h5>
                  <div v-html="trm.Body"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="margin-top: 5%; margin-bottom: 5%; text-align: center">
            <a-spin size="large" />
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import CarSearchPanel from "@/views/modules/car/components/CarSearchPanel.vue";
import CarAutomaticFLTR from "@/views/modules/car/components/CarAutomaticFLTR.vue";
import CarAirconFLTR from "@/views/modules/car/components/CarAirconFLTR.vue";
import CarClassFLTR from "@/views/modules/car/components/CarClassFLTR.vue";
import car from "../../../api/ibe/car";
import { i18n } from '@/i18n';
export default {
  name: "CarResults",
  components: {
    "car-search-panel": CarSearchPanel,
    "car-automatic-filter": CarAutomaticFLTR,
    "car-aircon-filter": CarAirconFLTR,
    "car-class-filter": CarClassFLTR,
  },
  data() {
    return {
      carAutomaticFilters: [],
      carAirconFilters: [],
      carClassFilters: [],
      currentPage: 1,
      startIndex: 0,
      endIndex: 10,
      pageSize: 10,
      visible: false,
      termsCond: [],
      userCurrentPoints: 0,
      serachCriteria: '',
      showSerachCriteria: true
    };
  },
  methods: {
    pagination(event) {
      this.currentPage = event;
      this.startIndex = (this.currentPage - 1) * this.pageSize;
      this.endIndex = this.startIndex + this.pageSize;
      window.scroll(0, 0);
    },
    gotoNextPage(result) {
      if (this.$store.getters.IsUserLoggedIn) {
        this.userCurrentPoints = this.$store.getters.points || 0;
        if (result.Price[0].TotalPoints > this.userCurrentPoints) {
          this.$notification["error"]({
            message: i18n.t("addcomm.Error"),
            description: i18n.t("addcomm.Insufficient Points"),
          });
        } else {
          this.$store.commit("car/setSelectedCarId", result.Vehicle[0].id);
          this.$store.dispatch("navigate", { c2: "car-booking" });
        }
      } else {
        this.$store.dispatch("navigate", { c2: "login" });
      }
    },
    showModal(result) {
      this.visible = true;
      const postdata = {
        Items: null,
        Location: [
          {
            id: result.Route[0].PickUp[0].id,
            locName: "",
            country: "",
            city: "",
            onAirport: "",
            Value: "",
          },
        ],
      };
      car.CarTermsRequest(postdata).then((response) => {
        this.termsCond = response.data.results[0].termGroupField || [];
      });
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    getCollapse() {
      this.showSerachCriteria = !this.showSerachCriteria;
    }
  },
  computed: {
    filteredPayload() {
      return this.$store.getters["car/filteredPayload"]({
        carAutomaticFilters: this.carAutomaticFilters,
        carAirconFilters: this.carAirconFilters,
        carClassFilters: this.carClassFilters,
      });
    },
  },
  created() {
    this.serachCriteria = this.$store.getters["car/carSearchRequest"];
    if (this.filteredPayload === [] || this.serachCriteria === '') {
      this.$store.dispatch("navigate", { c2: "car" });
    }
  }
};
</script>
<style scoped>
.ant-card >>> .ant-card-head-title {
  text-align: left !important;
}

.ant-card >>> .ant-card-body {
  padding: 0 !important;
}

.ant-card >>> .ant-card-actions > li {
  padding: 0 15px;
}
</style>